<template>
  <section class="products">
    <div class="row">
      <div class="col-12 col-lg-10 order-2 order-lg-1">
        <div class="row no-gutters">
          <div class="col-12 col-md-2"><h2>Products</h2></div>
          <div class="col-12 col-md-10 filters">
            <div class="row no-gutters align-items-end">
              <div class="col-12 col-md-3">
                <label class="text-white">Select View:</label>
                <div class="btn-group">
                  <button
                  class="btn btn-sm mt-0"
                  :class="isWarehouseView ? 'btn-secondary' : 'btn-outline-secondary'"
                  @click="viewType = 'warehouse'"
                  >
                  <i class="fa fa-warehouse"></i> Warehouses
                </button>
                <button
                class="btn btn-sm mt-0"
                :class="isPriceListView ? 'btn-secondary' : 'btn-outline-secondary'"
                @click="viewType = 'pricelist'"
                >
                <i class="fa fa-tags"></i> Price Lists
              </button>
            </div>
          </div>
          <div class="col-12 col-md-4">
                <div class="input-group">
                  <input
                    v-model="keyword"
                    @keyup.enter="searchProducts()"
                    type="text"
                    placeholder="Type keyword"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>

              <div class="col-12 col-md-3">
                <button
                  class="btn btn-primary btn-sm"
                  @click="searchProducts()"
                >
                  <i class="fa fa-search"></i> Search
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  @click="getProducts(), keyword = ''"
                >
                  <i class="fa fa-times"></i> Reset
                </button>
              </div>
              <div class="col-12 col-md-2" v-if="$router.user.roleScore >= 2">
                <button
                  class="btn btn-secondary btn-sm"
                  @click="salesforceSyncSapProducts()"
                >Sync Salesforce</button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive-not">
          <table class="table table-hover sticky">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Name</th>
                <th>Commercial</th>
                <th>Group</th>
                <template v-if="isWarehouseView">
                  <th>Attributes</th>
                  <th>Stock</th>
                  <th>On Hand</th>
                  <th>On Order</th>
                  <th>Is Commited</th>
                  <th>Production Capacity</th>
                  <th>BOM</th>
                </template>
                <!-- <th>Color</th>
                <th>Plug</th>
                <th>Attribute 1</th> -->
                <!-- <th>Attribute 2</th>
                <th>Category</th> -->
                <template v-if="isPriceListView">
                  <th v-for="list in listini" :key="list.listNum">{{ list.listName }}</th>
                </template>
                <!-- <th>Family</th>
                <th>Function</th> -->
              </tr>
            </thead>
            <tbody class="clean">
              <tr v-if="isLoading"><td colspan=100%>Loading products from SAP...</td></tr>
              <template v-else v-for="(value, key, index) in products_list">
                <tr :key="key" :id="key" class="products__group" :class="{'active': index === activeIndex}" @click="activeIndex !== index ? activeIndex = index : activeIndex = -1">
                  <td colspan="100%" class="group">{{ key }}</td>
                </tr>
                <tr
                v-show="activeIndex === index || activeIndex === -1"
                  v-for="product in value"
                  :key="product.itemCode"
                  :id="product.itemCode"
                  :class="[{ 'table-danger': product.stock <= 0}, product.stock > 50 ? 'table-success' : 'table-warning']"
                >
                <td>
                  <strong>{{ product.itemCode }}</strong>
                </td>
                <td>{{ product.itemName }}</td>
                <td>{{ product.commercialCode }} - {{ product.commercialName }}</td>
                <td>{{ product.itemGroup }}</td>
                <template v-if="isWarehouseView">
                  <td>{{ product.attribute1 }}<br>{{ product.attribute2 }}</td>
                  <td>
                    <div class="d-inline-flex text-center">
                      <div>
                        <small class="d-block font-weight-bold">Tot</small>
                        <strong>{{ product.stock | qty }}</strong>
                      </div>
                      <div class="ml-4">
                        <small class="d-block font-weight-bold">Hive</small>
                        {{ product.hiveStock | qty }}
                      </div>
                    </div>
                  </td>
                  <td>{{ product.onHand | qty }}</td>
                  <td>{{ product.onOrder | qty }}</td>
                  <td>{{ product.isCommited| qty  }}</td>
                  <td>
                    <div class="d-inline-flex text-center">
                      <div class="mx-1">
                        <small class="d-block font-weight-bold">MC</small>
                        {{ product.capacitaProduttiva_MC | qty }}
                      </div>
                      <div class="mx-1">
                        <small class="d-block font-weight-bold">Net</small>
                        {{ product.capacitaProduttiva_Netta | qty }}
                      </div>
                      <div class="mx-1">
                        <small class="d-block font-weight-bold">Gro</small>
                        {{ product.capacitaProduttiva_Lorda | qty }}
                      </div>
                    </div>
                  </td>
                  <td><BOM :sku="product.itemCode"></BOM></td>
                </template>
                <!-- <td>{{ product.color }}</td>
                  <td>{{ product.plug }}</td>
                  <td>{{ product.attribute1 }}</td> -->
                  <!-- <td>{{ product.attribute2 }}</td>
                    <td>{{ product.productRange }}</td> -->
                    <!-- <td>{{ product.productFamily }}</td>
                      <td>{{ product.productFunction }}</td> -->
                      <template v-if="isPriceListView">
                        <td v-for="(priceList, index) in product.pricelists" :key="product.itemCode+index">{{ priceList.price, priceList.currency | price }}</td>
                      </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 col-lg-2 order-1 order-lg-2">
        <div class="card mb-4">
          <div class="card-header">
            <h5 class="mb-0">Product Categories</h5>
          </div>
          <div class="card-body">
            <a
              v-for="(value, key) in products_list"
              :key="key"
              :href="'#' + key"
              v-text="key"
              class="products__group__link"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BOM from "./BOM";
import SapService from "@/services/SapService";
export default {
  name: "Products",
  data() {
    return {
      isLoading: false,
      products_list: false,
      listini: [],
      products: false,
      keyword: "",
      activeIndex: -1,
      viewType: 'warehouse',
      toggles: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true]
    };
  },
  computed: {
    isPriceListView() {
      return this.viewType === 'pricelist'
    },
    isWarehouseView() {
      return this.viewType === 'warehouse'
    },
  },
  components: { BOM },
  async beforeMount() {
    await this.getProducts();
  },
  methods: {
    mergeObjectsInUnique(array,property) {
      const newArray = new Map();
      array.forEach((item) => {
        const propertyValue = item[property];
        newArray.has(propertyValue) ? newArray.set(propertyValue, { ...item, ...newArray.get(propertyValue) }) : newArray.set(propertyValue, item);
      });
      return Array.from(newArray.values());
    },
    async getProducts() {
      this.isLoading = true
      if (this.products) {
        this.products_list = this.products
      } else {
        const response = await SapService.getProducts({itemGroup: 'Prodotti finiti'})
        // const products = this.mapWarehouses(response.data.item)
        const products = response.data.item
        const response2 = await SapService.getProducts({ property1: 'Y' })
        const replacements_products = response2.data.item?.map(p => { return {...p, commercialName: p.commercialName + ' | Ricambi'} })
        // const response2 = await SapService.getProducts({itemGroup: 'Ricambi'})
        // response2.data.item?.map(p => { return {...p, commercialName: p.commercialName + ' | Ricambi'} })
        // const replacements_products = this.mapWarehouses(response2.data.item)
        const prices = await this.getPriceLists()
        const productsPrices = prices.map((p) => {
          return {...p, pricelists: p.listiniPrezzi.filter(l => l.listNum != 3) }
        })
        this.listini = productsPrices[0].pricelists

        const productionCapacitiesResponse = await SapService.getQuery(364)
        let hiveStock = await SapService.getQuery(382)
        hiveStock = hiveStock.data.SqlResponse.Row

        const productionCapacities = productionCapacitiesResponse.data.SqlResponse.Row
        const productsWithPrices = this.mergeObjectsInUnique([...products, ...replacements_products, ...productsPrices], 'itemCode');
        productsWithPrices.map(p => {
          const pc = productionCapacities.find(pc => p.itemCode === pc.itemCode)
          if (pc) {
            p.hiveStock = hiveStock.find(i => i.itemcode === p.itemCode)?.stock
            p.stock = pc.stock
            p.onHand = pc.onHand
            p.isCommited = pc.isCommited
            p.onOrder = pc.onOrder
            p.capacitaProduttiva_MC = pc.capacitaProduttiva_MC
            p.capacitaProduttiva_Netta = pc.capacitaProduttiva_Netta
            p.capacitaProduttiva_Lorda = pc.capacitaProduttiva_Lorda
          }
        })
        const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
        const groupedProducts = groupByKey(productsWithPrices, 'commercialName')
        this.products = groupedProducts
        this.products_list = groupedProducts
      }
      this.isLoading = false
    },
    async getPriceLists() {
      const response = await SapService.getPriceLists({itemGroup: 'Prodotti finiti'})
      const response2 = await SapService.getPriceLists({itemGroup: 'Ricambi'})
      return [...response.data, ...response2.data] 
    },
    // mapWarehouses(products){
    //   return products.map((product) => {
    //     product.onHand = 0
    //     product.isCommited = 0
    //     product.onOrder = 0
    //     product.wareHouses.wareHouse.filter(wh => ['HIVE','Mc','P&L','Puppin'].includes(wh.whsCode)).forEach(wh => {
    //       product.onHand+=parseInt(wh.onHand)
    //       product.isCommited+=parseInt(wh.isCommited)
    //       product.onOrder+=parseInt(wh.onOrder)
    //     })
    //     return {...product, stock: product.onHand + product.onOrder - product.isCommited }
    //   })
    // },
    async salesforceSyncSapProducts() {
      await SapService.salesforceSyncSapProducts();
    },
    async searchProducts() {
      var regex = new RegExp(this.keyword, "i")
      const filteredProducts = {}
      Object.keys(this.products).forEach((key) => {
        const fp = this.products[key].filter(p => p.itemName.match(regex) !== null)
        if (fp.length) {
          filteredProducts[key] = fp
        }
      })
      this.products_list = filteredProducts
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
table.sticky thead, table.sticky thead, table.sticky thead th {
  position: sticky;
  top: 60px;
}
table tr {
  background: #fff;
  th {
    font-weight: 300;
    background-color: $gray02;
    border: 1px solid $gray03 !important;
    color: #fff;
  }
  td {
    vertical-align: middle;
  }
}
form label,
form button {
  margin-top: 1.5rem;
}
.clean input,
input.clean {
  width: auto;
  background: transparent;
  border: none;
}
input[type="number"] {
  width: 4rem;
}
.filters {
  background-color: $gray02;
  padding: 1rem;
  input,
  select {
    margin: 1rem;
  }
  button {
    margin: 1rem 0;
  }
}
.products__group {
  font-size: 1rem;
  text-align: center;
  background-color: rgba($gray04, 1)!important;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
  //box-shadow: inset 0 0 1rem 0 rgba(#000, .25);
  &:hover, &.active {
    background-color: #000!important;
  }
  &__link {
    display: block;
    font-size: 1rem;
    padding: 0.5rem;
    color: $gray04;
    border-bottom: 1px solid rgba($gray04, 0.25);
    text-decoration: none;
    &:hover {
      background-color: rgba($gray01, 0.6);
    }
  }
}
</style>
