import Api from "@/services/Api";
export default {
  // Orders
  getOrders(page, params) {
    return Api().post("/api/orders/p=" + page, params);
  },
  getFilteredOrders(params) {
    return Api().post("/api/orders/filtered/", params);
  },
  exportOrders(params) {
    return Api().post("/api/orders/export/", params);
  },
  searchCustomers(params) {
    return Api().post("/api/orders/customers/", params);
  },
  addOrder(params) {
    return Api().post("/api/imports/", params);
  },
  updateOrder(id, params) {
    return Api().put("/api/orders/" + id, params);
  },
  getOrder(id) {
    return Api().get("/api/orders/" + id);
  },
  deleteOrder(id) {
    return Api().delete("/api/orders/" + id);
  },
  getPaymentsDetails() {
    return Api().post("/api/orders/payment-details/");
  },
  getLastOrders() {
    return Api().get("/api/orders/last-orders");
  },
  getQuote(id) {
    return Api().get(`/api/crm/quote/${id}`);
  },
  getAccount(id) {
    return Api().get(`/api/crm/account/${id}`);
  },
  getContact(id) {
    return Api().get(`/api/crm/contact/${id}`);
  },
  salesforceSyncOrders() {
    return Api().post("/api/crm/orders-sync")
  },
  // Shipping
  getShippingOrders(params) {
    return Api().post("/api/orders/shipping/", params);
  },
  // Imports
  getImports() {
    return Api().get("/api/imports");
  },
  // irenImport() {
  //   return Api().get("/api/imports/iren");
  // },
  fileImport(params) {
    return Api().post("/api/imports/file", params);
  },
  sqsSalesforceImport() {
    return Api().post("/api/imports/sqs-salesforce");
  },
};