<template>
  <div class="card">
    <form class="form" @submit.prevent>
      <h5 class="mb-4">New Production Order</h5>
      <div class="form-row">
        <div class="col-12 col-md-6">
          <label for="id_number">Order Number</label>
          <input
            class="form-control form-control-sm"
            type="text"
            name="id_number"
            placeholder="Order Number"
            readonly
            v-model="formData.id_number"
          />
          <input type="hidden" v-model="formData.typology" name="typology" value="production"/>
        </div>
        <div class="col-12 col-md-6">
          <label for="date">Date</label>
          <input
            class="form-control form-control-sm"
            type="datetime-local"
            name="date"
            v-model="formData.date"
          />
        </div>
        <div class="col-12">
          <div class="row align-items-center">
            <div class="col-4">
              <label for="typology">Typology</label>
              <select class="form-control form-control-sm" name="typology" v-model="formData.typology" @change="typologyUpdated">
                <option v-for="t in typologies" :key="t.code" :value="t.code">{{ t.name }}</option>
              </select>
            </div>
            <div class="col-8">
              <small>{{selectedTypology.description}}</small>
            </div>
            <div class="col-6">
              <label for="assembler">Assembler</label>
              <select name="assembler"  class="form-control form-control-sm" v-model="formData.assembler" required @change="setWarehouse">
                <option value="tgt@vitesy.com">TGT</option>
                <option value="pannielazzarini@vitesy.com">Panni e Lazzarini</option>
                <option value="andrea@vitesy.com">Internal Test</option>
              </select>
            </div>
            <div class="col-6">
              <kbd>WH: {{ this.formData.warehouse }}</kbd>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="form-row">
        <div class="col-12">
         <h6>Product</h6>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <select
                class="form-control form-control-sm"
                id="products"
                v-model="selectedProduct"
                @change="addProduct(selectedProduct)"
                @click="getProducts()"
              >
                <option selected="selected" value="" disabled
                  >Select a product</option
                >
                <optgroup
                  v-for="(value, key) in products_list"
                  :key="key"
                  :label="key"
                >
                  <option
                    v-for="product in value"
                    :key="product.itemCode"
                    :value="product"
                    v-text="product.itemCode + ' | ' + product.itemName"
                  ></option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="products-table table-sm" v-if="Object.keys(formData.product).length">
            <strong>SKU</strong>: {{ formData.product.sku }}
            <br>
            <strong>Commercial Code</strong>: {{ formData.product.commercial_code }}
            <!-- <input class="form-control form-control-sm" name="name" type="text" placeholder="Commercial Code" v-model="formData.product.commercial_code" step="any" /> TEMP -->
            <br>
            <strong>Name</strong>: {{ formData.product.name }}
            <br><br>
            <strong>Quantity:</strong>
            <input class="form-control form-control-sm number w-100" name="quantity" type="number" min="1" placeholder="Quantity" v-model.number="formData.product.quantity"/>
          </div>
        </div>
      </div>
      <div>
        <hr />
        <label for="notes">Notes</label>
        <textarea
          class="form-control form-control-sm"
          type="text"
          name="notes"
          placeholder="Notes"
          v-model="formData.notes"
          rows="4"
        ></textarea>
        <hr />
        <button type="submit" class="btn btn-md btn-outline-success btn-block" @click.prevent="addProductionOrder">+ Add order
        </button>
      </div>
      <br />
      <div class="alert alert-success" v-show="alertSuccess" role="alert">
        <strong>Well done!</strong> New order placed.
      </div>
      <div class="alert alert-danger" v-show="alertError" role="alert">
        <strong>Ops!</strong> Something went wrong.
      </div>
    </form>
  </div>
</template>
<script>
import ProductionService from "@/services/ProductionService";
import SapService from "@/services/SapService";
import { production_typologies } from "@/assets/data.json";
export default {
  name: "NewProductionOrder",
  props: ["lastOrder"],
  data() {
    return {
      alertSuccess: false,
      alertError: false,
      selectedProduct: "",
      products_list: false,
      typologies: production_typologies,
      formData: {
        id_number: "",
        date: "",
        product: {},
        typology: "production",
        assembler: "",
        warehouse: "Mc"
      },
    };
  },
  computed: {
    defaultDate: function () {
      let now = new Date();
      let today =
        now.getFullYear() +
        "-" +
        ("0" + (now.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + now.getDate()).slice(-2) +
        "T" +
        ("0" + now.getHours()).slice(-2) +
        ":" +
        ("0" + now.getMinutes()).slice(-2);
      return today;
    },
    selectedTypology(){
      return this.typologies.find(t => t.code === this.formData.typology)
    }
  },
  mounted() {
    this.initializeOrder();
  },
  methods: {
    setWarehouse(){
      this.formData.assembler === 'pannielazzarini@vitesy.com' ? this.formData.warehouse = 'P&L' : this.formData.warehouse = 'Mc'
    },
    async getProducts() {
      if (!this.products_list) {
        const response = await SapService.getProducts({itemGroup: 'Prodotti finiti'})
        const products = response.data.item
        const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
        this.products_list = groupByKey(products, 'commercialName')
      }
    },
    addProduct(selectedProduct) {
      this.formData.product = { 
        sku: selectedProduct.itemCode,
        name: selectedProduct.itemName,
        commercial_code: selectedProduct.commercialCode,
        commercial_name: selectedProduct.commercialName,
        ean: selectedProduct.ean,
        color: selectedProduct.color,
        plug: selectedProduct.plug,
        attribute_1: selectedProduct.attribute1,
        attribute_2: selectedProduct.attribute2,
        family: selectedProduct.productFamily,
        quantity: 1,
        production_instructions: selectedProduct.productionsInstructionsExtended || product.productionsInstructions || ''
      };
      this.selectedProduct = "";
    },
    removeProduct() {
      this.formData.product = {};
    },
    async initializeOrder() {
      this.formData.id_number =
        "P" +
        String(
          Number(this.lastOrder?.substring(1, this.lastOrder.length)) + 1
        ).padStart(7, "0");
      this.formData.date = this.defaultDate;
    },
    async addProductionOrder() {
      if (!Object.keys(this.formData.product).length) {
        alert("Add the product!");
        return;
      }

      await this.secondaryProductCheck(this.formData.product)
      await ProductionService.addProductionOrder(this.formData)
        .then((response) => {
          this.alertError = false;
          this.alertSuccess = true;
          this.$parent.getProductionOrders();
          this.initializeOrder();
        })
        .catch(() => {
          this.alertSuccess = false;
          this.alertError = true;
        });
    },
    async secondaryProductCheck(product) {
      if (product.commercial_code === 'ETE K1') {
        const product_2 = {
          sku: product.color === 'White' ? 'ET00AC00' : 'ET00AC01',
          name: `Eteria Air Purifier - ${product.color}`,
          commercial_code: 'ETE AP',
          commercial_name: 'Eteria Air Purifier',
          color: product.color,
          family: 'Eteria',
          quantity: product.quantity
        }
        this.formData = {...this.formData, product_2 }
      }
    },
    typologyUpdated() {
      this.formData.typology === 'rework' || this.formData.typology === 'maintenance' ? this.formData.warehouse = 'Rework' : this.formData.warehouse = 'Mc'
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.form {
  position: relative;
  width: 100%;
  padding: 2.5rem;
  @include tablet {
    padding: 2rem;
  }
  @include mobile {
    padding: 1rem;
  }
  .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 1rem;
    display: block;
    width: auto;
    &:focus {
      z-index: 2;
    }
    &.number {
      width: 4.5rem;
    }
  }
  textarea {
    width: 100% !important;
  }
  select#products {
    width: 100%;
  }
  .products-table {
    tr {
      background-color: #fff !important;
      td,
      th {
        border: 0;
      }
      .form-control {
        margin-bottom: 0;
      }
    }
  }
}
</style>
