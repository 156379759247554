<template>
  <div>
    <div
      class="bg-danger text-light p-2 d-inline-block mb-4"
      v-if="new_shipping.error"
      v-text="new_shipping.error"
    ></div>
    <div v-if="isLoading" class="loading"></div>
    <div v-else class="row">
      <div class="col-12 col-md-6">
        <table>
          <tr>
            <td><strong>Hive ID:</strong></td>
            <td><a :href="`https://portal.hive.app/orders/${new_shipping.logistic_partner_id}`" target="_blank">{{ new_shipping.logistic_partner_id }}</a></td>
          </tr>
          <tr>
            <td><strong>Rate:</strong></td>
            <td>{{ new_shipping.shipping_details.rate }}€</td>
          </tr>
          <tr>
            <td><strong>Tracking Numbers:</strong></td>
            <td>
              <div
                v-for="tracking_number in new_shipping.shipping_details.tracking_numbers"
                :key="tracking_number"
                v-text="tracking_number"
              ></div>
            </td>
          </tr>
        </table>
        <template v-if="new_shipping.shipping_details.labels">
          <printFiles
            :s3Keys="new_shipping.shipping_details.labels"
            buttonText="Print Labels"
            buttonClass="ups my-4"
          />
          <div class="shipping__labels col-12 row">
            <div
              v-for="label in new_shipping.shipping_details.labels"
              :key="label"
              :class="[
                {
                  'col-12': new_shipping.shipping_details.courier === 'dhl',
                  'shipping__label col-6':
                    new_shipping.shipping_details.courier !== 'dhl',
                },
              ]"
            >
              <S3File :s3Key="label" />
            </div>
          </div>
        </template>
      </div>
      <div class="col-12 col-md-6" v-if="new_shipping.shipping_details.paperless">
        <S3File :s3Key="new_shipping.shipping_details.paperless" />
      </div>
    </div>
  </div>
</template>
<script>
import OrdersService from "@/services/OrdersService";
import ShippingService from "@/services/ShippingService";
import S3File from "../utils/S3File";
import printFiles from "../utils/printFiles";
export default {
  name: "ShipOrder",
  props: ["id_number"],
  data() {
    return {
      order: {},
      products: [],
      new_shipping: "",
      new_package: {
        dimensions: {
          length: "",
          width: "",
          height: "",
          weight: "",
        },
        products: [],
      },
      signedUrls: {},
    };
  },
  components: {
    S3File,
    printFiles,
  },
  mounted() {
    this.newShipping(this.id_number);
  },
  methods: {
    async newShipping(idNumber) {
      this.isLoading = true;
      const response = await OrdersService.getOrder(idNumber);
      this.order = response.data;
      if (
        this.order.shipping_details.tracking_numbers.length > 0 ||
        ["delivered", "in-transit", "exception"].includes(
          this.order.shipping_status
        )
      ) {
        this.new_shipping = {
          error: "This order has already been shipped!",
          shipping_details: this.order.shipping_details,
        };
      } else {
        this.products = [];
        this.order.products.forEach((product) => {
          for (var n = 0; n < Number(product.quantity); n++) {
            this.products.push(product.sku);
          }
        });

        const response = await ShippingService.newShipping(idNumber);
        if (response.data) {
          this.new_shipping = {
            error: response.data.error || response.data.errors,
            shipping_details: this.order.shipping_details,
          };
        } else {
          this.new_shipping = response.data;
        }
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
.shipping__labels {
  display: inline-flex;
  flex-flow: row wrap;
}
</style>
