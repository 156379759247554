<template>
  <tr
    v-if="order"
    :id="[order.id_number]"
    :class="[order.status, { 'table-info': !isDisabled }]"
    @dblclick.prevent="editProductionOrder"
  >
    <td>
      <div class="btn-group-vertical actions">
        <router-link
          :to="{
            name: 'ProductionOrderDetails',
            params: { id_number: order.id_number },
          }"
          class="btn btn-outline-primary btn-sm"
          ><i class="fa fa-info-circle"></i> Details</router-link
        >
        <button
          v-if="$router.user.roleScore > 1"
          class="btn btn-primary btn-sm"
          @click.prevent="updateProductionOrder(order.id_number)"
          :class="{ loading: isLoading }"
        >
          <i class="fa fa-save"></i> Update
        </button>
        <button
          v-if="$router.user.roleScore > 1"
          class="btn btn-danger btn-sm"
          @click.prevent="deleteProductionOrder(order.id_number)"
          :class="{ loading: isDeleting }"
        >
          <i class="fa fa-trash-alt"></i> Delete
        </button>
      </div>
    </td>
    <td>
      <strong>{{ order.id_number }}</strong>
      <br>
      <kbd class="kbd" v-if="order.sap_document_id">{{ order.sap_document_id }}</kbd>
      <template v-if="order.typology !== 'production'">
        <br>
        <kbd>{{ order.typology}}</kbd>
      </template>
    </td>
    <td>
      <input v-if="!isDisabled" class="form-control form-control-sm" type="datetime-local" name="date" v-model="order.date" />
      <span v-else>{{ order.date | formatDate }}</span>
      <small>{{ productionTime }}</small>
    </td>
    <td>
      <div>
        <span class="inline clean">
          <input v-if="!isDisabled" class="form-control form-control-sm" type="number" name="quantity" v-model="order.product.quantity" />
          <strong v-else class="mr-3">{{ order.product.quantity }}</strong>
          {{ order.product.sku }}
          <!--<input type="number" v-model="order.product.quantity" :disabled="isDisabled" size="3" readonly>-->
          <!--<input type="text" v-model="order.product.sku" :disabled="isDisabled" size="12" readonly>-->
        </span>
        <span class="small">{{ order.product.commercial_code }} |
          <strong>{{ order.product.name }}</strong></span>
      </div>
    </td>
    <td>
      <select
        v-model="order.status"
        :disabled="isDisabled"
        :class="order.status"
      >
        <option
          v-for="status in statuses"
          :key="status"
          selected="selected"
          :value="status"
          >{{ status }}</option
        >
      </select>
      <div v-if="order.status === 'completed'" class="text-center">{{ order.product.quantity_actual || order.product.quantity }}/{{ order.product.quantity }}</div>
    </td>
    <td class="clean">
      <textarea v-model="order.notes"></textarea>
      <small v-if="$router.user.roleScore > 1">{{ order.assembler }}</small>
    </td>
    <td class="label__col">
      <button
        class="btn ups"
        @click.prevent="printLabel()"
      >
        <i class="fa fa-print"></i>
      </button>
    </td>
    <td v-if="$router.user.roleScore > 1" class="sap__col">
      <!-- <button
        v-if="!order.sap_id && this.$router.user.roleScore > 1"
        :disabled="order.status !== 'completed'"
        class="btn sap"
        :class="{ loading: isLoading }"
        @click.prevent="updateSapProduct()"
      >
        <i class="fa fa-cloud-upload-alt"></i>
      </button> -->
      <button
        v-if="!order.sap_id && this.$router.user.roleScore >= 2"
        :disabled="order.status !== 'completed'"
        class="btn sap"
        :class="{ loading: isLoading }"
        @click.prevent="addProductionOrder()"
      >
        <i class="fa fa-cloud-upload-alt"></i>
        <span>Send to SAP</span>
      </button>
      <button
        v-if="order.sap_status !== 'closed' && order.status === 'completed' && this.$router.user.roleScore >= 2 && order.sap_id"
        class="btn btn-sm sap d-inline-flex"
        :class="{ loading: isLoading }"
        @click.prevent="closeProductionOrder()"
      >
        <i class="fa fa-cloud-upload-alt mr-1"></i>
        <span>Close in SAP</span>
      </button>
      <kbd v-if="order.sap_id">{{ order.sap_id }}</kbd>
    </td>
  </tr>
</template>
<script>
import ProductionService from "@/services/ProductionService";
import SapService from "@/services/SapService";
export default {
  name: "SingleProductionOrder",
  props: ["order"],
  data() {
    return {
      isDisabled: true,
      isLoading: false,
      isDeleting: false,
      statuses: ["new", "in-progress", "completed", "warning"],
      productionTime: ""
    };
  },
  methods: {
    editProductionOrder() {
      if (this.$router.user.roleScore > 1 && this.order.status === "new") {
        this.isDisabled = !this.isDisabled;
      }
    },
    async updateProductionOrder(id) {
      this.isLoading = !this.isLoading;
      this.isDisabled = true;
      await ProductionService.updateProductionOrder(id, this.order).then(() => {
        this.isLoading = !this.isLoading;
      });
    },
    async deleteProductionOrder(id) {
      if (confirm("Are you sure?")) {
        this.isDeleting = !this.isDeleting;
        await ProductionService.deleteProductionOrder(id).then(() => {
          this.isDeleting = !this.isDeleting;
        });
        this.$parent.getProductionOrders();
      } else {
        this.isDisabled = !this.isDisabled;
      }
    },
    async updateSapProduct() {
      if (this.order.status === "completed") {
        this.isLoading = !this.isLoading;
        await SapService.updateSapProduct(this.order)
          .then((response) => {
            console.log(response.data);
            alert(response.data);
            this.$parent.getProductionOrders();
          })
          .catch((err) => console.log(err));
      }
    },
    async addProductionOrder() {
      if (this.order.status === "completed") {
        this.isLoading = !this.isLoading;
        await SapService.addProductionOrder(this.order._id, this.order.product)
          .then((response) => {
            console.log(response.data);
            alert(response.data);
            this.$parent.getProductionOrders();
          })
          .catch((err) => console.log(err));
      }
    },
    async closeProductionOrder() {
      if (this.order.status === "completed" && this.order.sap_status !== 'closed') {
        this.isLoading = !this.isLoading;
        await SapService.closeProductionOrder(this.order._id, this.order)
          .then((response) => {
            console.log(response.data);
            alert(response.data);
            this.$parent.getProductionOrders();
          })
          .catch((err) => console.log(err));
      }
    },
    getProductionTime(end) {
      let start = new Date();
      if (this.order.end_production_date) {
        end = new Date(this.order.end_production_date);
      }
      if (this.order.start_production_date) {
        start = new Date(this.order.start_production_date);
      }
      var delta = Math.abs(end - start) / 1000;
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
      var seconds = (delta % 60).toFixed(0);
      this.productionTime = `${days} days ${hours}h ${minutes}' ${seconds}''`;
    },
    printLabel(){
      let imgUrl = 'https://prismic-io.s3.amazonaws.com/vitesy/e61769ac-8ef5-496b-a4f8-28ebcf5d375d_NAT.svg'
      this.order.product.sku.substr(0,2) === 'ET' ? imgUrl = 'https://prismic-io.s3.amazonaws.com/vitesy/cd0443e9-c475-422f-8dd6-3b85e3ea86ef_ETE.svg' : ''
      this.order.product.sku.substr(0,2) === 'IA' || this.order.product.sku.substr(0,2) === 'CY' ? imgUrl = 'https://prismic-io.s3.amazonaws.com/vitesy/634216cc-483c-4dd8-9aff-47f8167ecea5_NAP.svg' : ''
      
      const html = `<html>
                    <head><style>
                    @page { size: 4in 6in; }
                    @page :left, @page :right, @page :top, @page :bottom { margin-left: 0; }
                    body { margin: 0; font-size: 'Roboto', sans-serif; }
                    .wrapper {width:384px; height:576px;}
                    .content { font-size: 15pt; padding: 1.5rem; }
                    img {width: 80%; height: auto; margin: 1rem auto 0 auto; display: block;}
                    </style></head>
                    <body>
                    <div class="wrapper">
                    <img src="${imgUrl}"/>
                    <br>
                    <div class="content">
                    SKU: <strong>${this.order.product.sku}</strong> 
                    <br><br>
                    Descrizione: <br><strong>${this.order.product.name}</strong>
                    <br><br>
                    N° lotto di produzione: <strong>${this.order.id_number}</strong>
                    <br><br>
                    Quantità: <strong>${this.order.product.quantity}</strong>
                    </div>
                    </div></body></html>`
      const label = window.open("", "Print Order Label", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=yes")
      label.document.body.innerHTML = html;
      label.focus()
      setTimeout(()=>{
        label.print()
        label.close()
      },500)
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
tr {
  border-left: .3rem solid;
  cursor: pointer;
  &.new {
    border-color: $clairy;
    td {
      background-color: rgba($sand, 0.4);
    }
  }
  &.in-progress {
    border-color: $ups;
  }
  &.completed {
    border-color: $green;
  }
  td {
    vertical-align: middle !important;
    border-left: none;
    border-right-color: rgba(0, 0, 0, 0.03);
    &.sap__col {
      text-align: center;
    }
    span {
      display: block;
      white-space: nowrap;
    }
  }
}
.clean input,
input.clean,
.clean textarea {
  width: auto;
  background: transparent;
  border: none;
}
textarea {
  width: 100% !important;
}
.inline {
  //margin: .2rem auto;
  display: inline-flex;
}
.clean input {
  flex: 1 1 auto;
}
.form-control {
  width: auto;
}
input[type="number"] {
  width: 4rem;
  text-align: center;
}
select,
.text-capitalize {
  text-transform: capitalize;
  &.completed {
    background-color: $green;
    color: #fff;
  }
  &.in-progress {
    background-color: $ups;
  }
  &.warning {
    background-color: $red;
    color: #fff;
  }
  &.new {
    background-color: $clairy;
    color: #fff;
  }
}
select {
  border-radius: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0 1rem;
  line-height: 1.5rem;
  outline: none !important;
  border: none;
}
input {
  padding: 0.2rem.5rem;
  border: 1px solid rgba(0, 0, 0, 0.04);
}
</style>
