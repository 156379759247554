<template>
  <tr
    v-on:dblclick="$router.user.roleScore > 1 ? (isDisabled = !isDisabled) : ''"
    :ref="order.id_number"
    :id="order.id_number"
    :class="[
      order.shipping_status,
      {
        'table-info': !isDisabled,
        replacement: order.id_number.includes('_R'),
        scanning: scanning.id_number === order.id_number,
      },
    ]"
  >
    <td>
      <div class="btn-group-vertical actions">
        <button
          v-if="$router.user.roleScore > 1"
          class="btn btn-primary btn-sm"
          @click.prevent="updateOrder(order.id_number)"
          :disabled="isDisabled"
          :class="{ loading: isLoading }"
        >
          <i class="fa fa-save"></i> Update
        </button>
        <template v-if="$router.user.roleScore > 1 && isShippable">
          <button class="btn btn-warning btn-sm"
            v-if="!order.logistic_partner_id"
            @click.prevent="showNewShipping = true">
            <i class="fa fa-truck"></i> 
            Ship 
            <span v-if="order.departure_location === 'HIVE'" class="ml-1">w/Hive</span>
          </button>
          <button v-if="order.logistic_partner_id && order.shipping_status !== 'delivered'" 
            class="btn hive btn-sm" :class="{ loading: isLoading }" 
            @click.prevent="resyncHive(order.id_number)" 
            :disabled="isLoading || order.shipping_status === 'in-transit'">
            <i class="fa fa-hive"></i> Resync Hive
          </button>
        </template>
        <printFiles
          v-if="
            order.shipping_details.labels.length &&
            (order.shipping_status === 'ready-for-shipping' ||
            (!isDisabled && order.status === 'shipping'))
          "
          :s3Keys="order.shipping_details.labels"
          buttonText="Print"
          buttonClass="ups btn-sm"
        />
        <button
          class="btn btn-danger btn-sm"
          v-if="!isDisabled && (order.shipping_details.tracking_numbers.length || order.logistic_partner_id)"
          @click.prevent="cancelShipping(order.id_number)"
          :class="{ loading: isDeleting }"
        >
          <i class="fa fa-trash-alt"></i> Cancel Shipping
        </button>
      </div>
    </td>
    <td>
      <strong>{{ order.id_number }}</strong
      ><br />
      <kbd class="kbd" v-if="order.sap_id">ERP_ID: {{ order.sap_id }}</kbd
      ><br />
      <div v-for="(doc, key) in order.sap_documents" :key="key">
        <kbd v-if="doc.number"><i class="text-uppercase" v-text="key === 'invoice' ? '#' : key"></i> {{ doc.number }}</kbd>
      </div>
      <br />
      <br />
      <kbd class="hive" v-if="order.logistic_partner_id">
        HIVE_ID: 
        <a :href="`https://portal.hive.app/orders/${order.logistic_partner_id}`" target="_blank">{{ order.logistic_partner_id }}</a>
      </kbd>
    </td>
    <td>
      {{ order.date | formatDate }}
      <div class="d-inline-flex text-muted align-items-center nowrap mt-2" title="Goods Readiness Date" v-if="order.goods_readiness !== order.date">{{ order.goods_readiness | formatDate }} <i class="fa fa-hourglass ml-1"></i></div>
    </td>
    <td>
      <label class="w-100" :class="order.status">{{ order.status | status }}</label>
      <label class="w-100" :class="order.shipping_status">{{ order.shipping_status | status }}</label>
    </td>
    <td>
      <div class="nowrap" v-for="(p, index) in order.products" :key="index">{{ p.quantity }} x {{ p.sku }}</div>
    </td>
    <td>
      <div class="mb-2">
        <Shipment v-for="(shipment) in order.shipments" :key="shipment.id" :shipment="shipment" :departure_location="order.departure_location" :isDisabled="isDisabled" />
        <!-- <div v-for="p in order.packages">
          {{ p.serials.join(', ')}}
        </div> -->
      </div>
      <!-- <div v-else
        class="package"
        v-for="(singlePackage, index) in order.packages"
        :key="singlePackage._id + index"
        :id="
          scanning.id_number === order.id_number &&
          scanning.packageIndex === index
            ? 'scanning'
            : ''
        ">
        <a
          :class="[
            'float-right fa ml-2',
            package_details ? 'fa-minus' : 'fa-plus',
          ]"
          @click.prevent="package_details = !package_details"
        ></a>
        <div v-if="package_details">
          <span
            ><strong>{{ singlePackage.sku }}</strong> |
            {{ singlePackage.name }}</span
          >
          <span>Dimensions: {{ singlePackage.dimensions.length }}x{{
              singlePackage.dimensions.width
            }}x{{ singlePackage.dimensions.height }} | Weight:
            {{ singlePackage.dimensions.weight }}kg</span
          >
        </div>
        <span>Products:</span>
        <strong>{{ singlePackage.products.join(", ") }}</strong>
        <hr />
        <span v-if="singlePackage.serials.length > 0 || !isDisabled"
          >Serials:</span
        >
        <template v-if="singlePackage.serials.length > 0">
          <template v-for="(serial, index) in singlePackage.serials">
            <span :key="index">
              <input
                type="text"
                placeholder="NTxxxxxxx"
                v-model="singlePackage.serials[index]"
              />
              <a
                v-if="!isDisabled"
                @click.prevent="
                  singlePackage.serials.splice(index, 1),
                    updateOrder(order.id_number)
                "
                class="fa fa-times remove-button"
              ></a>
            </span>
          </template>
        </template>
        <a
          v-if="!isDisabled"
          @click.prevent="singlePackage.serials.push('')"
          class="fa fa-plus add-button d-block"
        ></a>
        <hr />
        <div class="tracking_number">
          <span class="inline">
            <input
              v-if="singlePackage.tracking_number || !isDisabled"
              type="text"
              v-model="singlePackage.tracking_number"
              placeholder="1Zxxxxxxxxxxxxxxxx"
            />
            <button
              v-if="singlePackage.tracking_number"
              class="btn btn-sm btn-square ups"
              @click="
                trackShipping(
                  courier !== 'dhl'
                    ? singlePackage.tracking_number
                    : 'JD' + order.shipping_details.awb_number
                )
              "
            >
              <i class="fa fa-shipping-fast"></i>
            </button>
            <template v-if="singlePackage.tracking_number">
              <a
                v-if="courier === 'ups'"
                class="btn btn-sm btn-block"
                :href="`https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=${singlePackage.tracking_number}`"
                target="_blank"
                ><i class="fa fa-link"></i
              ></a>
              <a
                v-else-if="courier === 'fedex'"
                class="btn btn-sm btn-block"
                :href="`https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${singlePackage.tracking_number}`"
                target="_blank"
                ><i class="fa fa-link"></i
              ></a>
              <a
                v-else-if="courier === 'dhl'"
                class="btn btn-sm btn-block"
                :href="`https://mydhl.express.dhl/it/it/tracking.html#/results?id=${order.shipping_details.awb_number}`"
                target="_blank"
                ><i class="fa fa-link"></i
              ></a>
            </template>
          </span>
        </div>
      </div> -->
      <div v-if="tracking_response">
        <p>
          <strong>Status: </strong>
          <span class="text-capitalize">{{
            tracking_response.status.status | status
          }}</span>
          {{ tracking_response.status.description }}
        </p>
        <p v-if="tracking_response.location">
          <i class="fa fa-map-marked-alt"></i>
          {{ tracking_response.location.PostalCode }}
          {{ tracking_response.location.City }} -
          {{ tracking_response.location.CountryCode }}
        </p>
        <p v-if="tracking_response.date">
          <i class="fa fa-clock"></i> {{ tracking_response.date }}
        </p>
      </div>
    </td>
    <td>
      <div class="user__address clean">
        <div v-if="order.shipping && !isDisabled">
          <input
            v-model="order.shipping.name"
            :disabled="isDisabled"
            type="text"
            placeholder="Name"
          />
          <input
            v-model="order.shipping.company"
            :disabled="isDisabled"
            type="text"
            placeholder="Company"
          />
          <input
            v-model="order.shipping.vat_number"
            :disabled="isDisabled"
            type="text"
            placeholder="VAT Number"
          />
          <input
            v-model="order.shipping.address_1"
            :disabled="isDisabled"
            type="text"
            placeholder="Address 1"
          />
          <input
            v-model="order.shipping.address_2"
            :disabled="isDisabled"
            type="text"
            placeholder="Address 2"
          />
          <input
            v-model="order.shipping.address_3"
            :disabled="isDisabled"
            type="text"
            placeholder="Address 3"
          />
          <input
            v-model="order.shipping.city"
            :disabled="isDisabled"
            type="text"
            placeholder="City"
          />
          <input
            v-model="order.shipping.state"
            :disabled="isDisabled"
            type="text"
            placeholder="State"
          />
          <input
            v-model="order.shipping.postcode"
            :disabled="isDisabled"
            type="text"
            placeholder="Postcode"
          />
          <input
            v-model="order.shipping.country"
            :disabled="isDisabled"
            type="text"
            placeholder="Country"
          />
          <input
            v-model="order.shipping.email"
            :disabled="isDisabled"
            type="text"
            placeholder="Email"
          />
          <input
            v-model="order.shipping.phone"
            :disabled="isDisabled"
            type="text"
            placeholder="Phone"
          />
        </div>
        <div v-if="order.shipping && isDisabled">
          <strong v-text="order.shipping.name"></strong>
          <span v-text="order.shipping.company"></span>
          <span v-text="order.shipping.vat_number"></span>
          <span v-text="order.shipping.address_1"></span>
          <span v-text="order.shipping.address_2"></span>
          <span v-text="order.shipping.address_3"></span>
          <span v-text="order.shipping.city"></span>
          <span v-text="order.shipping.state"></span>
          <span v-text="order.shipping.postcode"></span>
          <strong v-text="order.shipping.country"></strong>
          <span v-text="order.shipping.email"></span>
          <span v-text="order.shipping.phone"></span>
        </div>
      </div>
    </td>
    <td>
      {{ order.shipping_total || 0 }}
      {{ order.currency === "EUR" ? "€" : "$" }} /
      {{ order.shipping_details.rate || "0" }}€
    </td>
    <td>
      {{ order.shipping_method }}<br />
      <span class="small mt-1 text-center">
        <input
          type="checkbox"
          :id="'express' + order.id_number"
          v-model="order.shipping_details.express"
          :disabled="isDisabled"
        />
        <label
          :for="'express' + order.id_number"
          v-text="order.shipping_details.express ? 'Express' : 'Standard'"
        ></label>
      </span>
    </td>
    <td>{{ order.notes }}</td>
    <td>{{ order.internal_notes }}</td>
    <td>{{ order.channel_group }}</td>
    <td>{{ order.channel }}</td>
    <td>{{ order.typology }}</td>

    <!-- New Shipping Modal -->
    <div v-if="showNewShipping" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              New Shipping for Order #{{ order.id_number }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="showNewShipping = false"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">
            <shipOrder :id_number="order.id_number"></shipOrder>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="showNewShipping = false"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </tr>
</template>
<script>
import OrdersService from "@/services/OrdersService";
import ShippingService from "@/services/ShippingService";
import ShipOrder from "./ShipOrder";
import Shipment from "./Shipment";
import printFiles from "../utils/printFiles";
export default {
  name: "SingleShippingOrder",
  props: ["order", "scanning"],
  data() {
    return {
      isDisabled: true,
      isLoading: false,
      isDeleting: false,
      showNewShipping: false,
      tracking_response: "",
      package_details: false,
    };
  },
  components: {
    ShipOrder,
    printFiles,
    Shipment
  },
  filters: {
    status: function (str) {
      if (str !== "-") {
        str = str.replace(/-/g, " ");
      }
      return str;
    },
  },
  computed: {
    courier() {
      return this.order.shipping_details.courier;
    },
    isShippable(){
      return Object.values(this.order.sap_documents).some(doc => doc.number?.length && doc.date)
    }
  },
  mounted() {},
  methods: {
    async resyncHive(id_number) {
      this.isLoading = true
      await ShippingService.updateShipping(id_number)
      this.isLoading = false
    },
    async updateOrder(id) {
      this.isLoading = !this.isLoading;
      this.isDisabled = true;

      await OrdersService.updateOrder(id, this.order).then(() => {
        this.isLoading = !this.isLoading;
      });
    },
    async cancelShipping(id) {
      if (confirm("Are you sure?")) {
        this.isDeleting = !this.isDeleting;
        await ShippingService.cancelShipping(id).then(() => {
          this.isDeleting = !this.isDeleting;
        });
      } else {
        this.isDisabled = !this.isDisabled;
      }
    },
    async trackShipping(trackShipping) {
      const response = await ShippingService.trackShipping(trackShipping);
      this.tracking_response = response.data;
    },
    printLabels(labels) {
      let printPopup = window.open();
      let htmlPrint = "";
      labels.map((label) => {
        htmlPrint +=
          '<img style="width:100%;height:auto" src=data:image/png;base64,' +
          label +
          " />";
      });
      printPopup.document.write(htmlPrint);
      setTimeout(() => {
        printPopup.focus();
        printPopup.print();
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
tr {
  border-left: 0.4rem solid;
  &.delivered {
    border-color: $green;
  }
  &.in-transit {
    border-color: $ups;
  }
  &.exception {
    border-color: $red;
  }
  &.ready-for-shipping {
    border-color: $clairy;
  }
  &.scanning td {
    background: $clairy !important;
  }
  td {
    vertical-align: middle !important;
    border-left: none;
    border-right-color: rgba(0, 0, 0, 0.03);
    span {
      display: block;
      white-space: nowrap;
    }
  }
}
.ups {
  color: $ups;
  background-color: $ups_bg;
  &:hover {
    color: $ups_bg;
    background-color: $ups;
  }
}
.user__address {
  input {
    min-width: 200px;
  }
}
.inline {
  display: inline-flex;
}
.product {
  margin: 0.4rem auto;
  white-space: nowrap;
  strong {
    margin-right: 1rem;
  }
}
.package {
  border: 1px solid $ups_bg;
  border-bottom: 0.25rem solid $ups_bg;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  &#scanning {
    background-color: $blue;
    color: #fff;
  }
  span {
    white-space: nowrap;
    padding: 0.25rem 0;
  }
}


label,
.text-capitalize {
  text-transform: capitalize;
  white-space: nowrap;
  &.completed,
  &.delivered {
    background-color: $green;
    color: #fff;
  }
  &.shipping,
  &.in-transit {
    background-color: $ups;
    color: #fff;
  }
  &.waiting,
  &.exception {
    background-color: $red;
    color: #fff;
  }
  &.processed,
  &.ready-for-shipping {
    background-color: $clairy;
    color: #fff;
  }
}
label {
  border-radius: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0 1rem;
  line-height: 1.5rem;
  outline: none !important;
  border: none;
}
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
  &-body {
    padding: 0;
  }
  .modal-xl {
    max-width: 98%;
  }
  button.close {
    background-color: #b83339;
    color: #fff;
    font-weight: 400;
    padding: 1.2rem 1.5rem;
    opacity: 1;
  }
}
kbd {
  white-space: nowrap;
  &.kbd {
    background-color: $sap_color;
    display: inline-block;
    margin-top: 0.5rem;
  }
}
input {
  padding: 0.2rem.5rem;
  border: 1px solid rgba(0, 0, 0, 0.04);
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 80%;
  }
}
body.modal-open {
  overflow: hidden;
}
</style>
