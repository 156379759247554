<template>
<div class="border border-dark p-2">
  <div>
    <a class="p-1 mr-1" :class="shipment.shipment_provider && shipment.shipment_provider.length ? shipment.shipment_provider.toLowerCase() : ''"
      :href="shipment.tracking_url" target="_blank">
      <strong>{{ shipment.shipment_provider }}</strong>: {{ shipment.tracking_number }}</a>
  </div>
  <div class="my-2">
    <span>Serials:</span>
    <div v-for="(serial, index) in shipment.serials" :key="serial">
      <div class="d-inline-flex">
        <input type="text" placeholder="02xxxxxxx" :disabled="isDisabled" v-model="shipment.serials[index]"/>
        <button v-if="!isDisabled"
          @click.prevent="shipment.serials.splice(index, 1)"
          class="fa fa-times remove-button">
        </button>
      </div>
    </div>
    <template v-if="!isDisabled">
      <div class="d-inline-flex mt-2">
        <input type="text" placeholder="02xxxxxxx" v-model="newSerial"/>
        <button
          @click.prevent="addSerial"
          class="fa fa-plus add-button d-block"
        ></button>
      </div>
    </template>
  </div>
  <div class="mb-1">
    <small>Status: {{ shipment.status }} - {{ shipment.delivery_status }}</small>
  </div>
  <div class="mb-1">
    <small class="text-nowrap" v-if="shipment.created_at">Created: {{ shipment.created_at | formatDate }}</small><br>
    <small class="text-nowrap" v-if="shipment.shippet_at">Shipped: {{ shipment.shippet_at | formatDate }}</small><br>
    <small class="text-nowrap" v-if="shipment.deliveried_at">Delivered: {{ shipment.deliveried_at | formatDate }}</small>
  </div>
  <kbd v-if="departure_location === 'HIVE'" class="hive">HIVE ID: {{ shipment.id }}</kbd>
  <small class="d-block w-100" v-if="shipment.package">
    <template v-if="togglePackage">
      <span><strong>{{ shipment.package.sku }}</strong> | {{ shipment.package.name }}</span>
      <br>
      <span>L{{ shipment.package.dimensions.length }} x W{{shipment.package.dimensions.width}} x H{{ shipment.package.dimensions.height }} - {{ shipment.package.dimensions.weight }}kg</span>
    </template>
    <span class="underline" v-else @click="togglePackage = true">Show package</span>
  </small>
</div>
</template>
<script>
export default {
  name: "Shipment",
  props: ["shipment","isDisabled", "departure_location"],
  data() {
    return { 
      newSerial: '',
      togglePackage: false
    }
  },
  methods: {
    addSerial(){
      if (this.newSerial.length > 2) {
        const serials = this.newSerial.split(/[^\d]/g)
        this.shipment.serials = [...this.shipment.serials, ...serials]
        this.newSerial = ''
      } else {
        alert('Formato seriale non corretto.')
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
</style>
