<template lang="pug">
tbody
  tr(v-for="(ncp, index) in order.nc_products" :key="`${order.id_number}-ncp-${index}`")
    td 
      router-link(:to="`/production/${order.id_number}#nc-products`")
        strong {{ order.id_number }}
      br
      small {{ ncp.timestamp | formatDate }}
    td 
      strong {{ ncp.sku }}
      br
      kbd.mt-1.d-inline-block.bg-info
        small Product
    td {{ ncp.name }}
    td {{ ncp.quantity }}
    td
      span(v-if="ncp.reason") {{ncp.reason.category}}
    td
      span(v-if="ncp.reason") {{ ncp.reason.value }}
    td {{ ncp.notes }}
  tr(v-for="(ncc, index) in order.nc_components" :key="`${order.id_number}-ncc-${index}`")
    td 
      router-link(:to="`/production/${order.id_number}#nc-components`")
        strong {{ order.id_number }}
      br
      small {{ order.date | formatDate }}
    td 
      strong {{ ncc.sku }}
      br
      kbd.mt-1.d-inline-block.bg-secondary 
        small Component
    td {{ ncc.name }}
    td {{ ncc.quantity }}
    td
      span(v-if="ncc.reason") {{ncc.reason.category}}
    td
      span(v-if="ncc.reason") {{ ncc.reason.value }}
    td {{ ncc.notes }}
</template>
<script>
export default {
  name: "SingleProductionOrderNC",
  props: ["order"],
  data() {
    return {
    };
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
table {
  width: 100%;
}
</style>
