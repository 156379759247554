<template lang="pug">
section.orders
  .table-responsive(v-if="$router.user.roleScore >= 2")
    table.table.table-striped.table-bordered.table-hover
      thead
        tr
          th Order ID
          th SKU
          th Name
          th Quantity
          th Category
          th Reason
          th Notes
      template(v-if="!loadingData && orders_count > 0")
        template(v-for="order in orders")
          SingleProductionOrderNC(v-if="order.nc_products.length || order.nc_components.length" :key="order.id_number" :order="order")
      span(v-else)
        .loading-data(v-if="loadingData")
          span.loading
          h5 Loading orders ...
        .loading-data(v-else)
          h6 No orders found.
        .clearer
  div(v-else) Access denied! You can't access this page.
</template>

<script>
import ProductionService from "@/services/ProductionService";
import SingleProductionOrderNC from "./SingleProductionOrderNC";
export default {
  name: "ProductionOrdersNC",
  data() {
    return {
      page: 1,
      pages: 0,
      orders: "",
      order_list: "",
      orders_count: "",
      loadingData: false,
      filters: {
        nc: true
      }
    };
  },
  components: {
    SingleProductionOrderNC
  },
  mounted() {
    this.page = 1;
    this.getFilteredProductionOrders()
  },
  methods: {
    async getFilteredProductionOrders() {
      this.loadingData = !this.loadingData;
      const response = await ProductionService.getFilteredProductionOrders(this.filters)
      this.order_list = response.data
      this.orders = this.order_list
      this.orders_count = this.order_list.length
      this.pages = 1
      this.loadingData = !this.loadingData
    }
  }
}
</script>