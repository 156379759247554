<template>
  <section class="devices">
    <h2>Devices</h2>
    <div class="row align-bottom">
      <div class="col-12 col-md-8">
        <div class="input-group">
          <input
            type="text"
            v-model="search"
            @keyup="searchDevice"
            placeholder="Search by email..."
            class="form-control my-2"
          />
          <button
            class="btn bg-transparent"
            style="margin-left: -40px; z-index: 100;"
            @click.prevent="resetSearch"
          >
            <i class="fa fa-times"></i>
          </button>
          <button class="btn btn-primary btn-sm ml-4" @click="getDevices()">Next page</button>
        </div>
      </div>
      <div class="col-12 col-md-4 text-right align-self-center">
        <span class="pr-4"
          ><strong>Devices:</strong> {{ devices.length }} |
          <strong>Connected:</strong>
          {{
            devices.filter((d) => {
              return d.connected;
            }).length
          }}</span
        >
        <!-- <button
          class="btn btn-secondary btn-sm"
          @click.prevent="toggleTestDevices"
        >
          <i class="fas fa-terminal"></i> Show/Hide Testing Devices
        </button> -->
        <button
          class="btn btn-secondary btn-sm"
          @click.prevent="devices = unlinkedDevices"
        >
          <i class="fas fa-terminal"></i> Show Unlinked Devices
        </button>
      </div>
    </div>
    <div
      class="accordion"
      id="devicesAccordion"
      v-if="!loadingData && devices.length > 0"
    >
      <template v-for="device in devices">
        <SingleDevice :device="device" :key="device.entityId" />
      </template>
    </div>
    <span v-else>
      <div class="loading-data" v-if="loadingData">
        <span class="loading"></span>
        <h5>Loading devices ...</h5>
      </div>
      <div class="loading-data" v-else><h6>No devices found.</h6></div>
      <div class="clearer"></div>
    </span>
  </section>
</template>
<script>
import SingleDevice from "./SingleDevice";
export default {
  name: "Devices",
  data() {
    return {
      loadingData: false,
      devices: [],
      devices_list: [],
      search: "",
      nextPage: false,
      testDevices: true,
    };
  },
  computed: {
    unlinkedDevices() {
      return this.devices.filter((d) => !d.order || !d.order.id_number);
    },
  },
  components: {
    SingleDevice,
  },
  mounted() {
    this.getDevices();
  },
  methods: {
    async getDevices() {
      this.loadingData = !this.loadingData;
      this.devices = [];
      this.devices_list = [];
      let url = "https://api.clairyhub.com/gateway/devices";
      if (this.nextPage) {
        url = "https://api.clairyhub.com/gateway/devices?page=" + this.nextPage;
      }
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "x-access-token": "4Y.ae7JJNwe2FagT(keiLpnxj3t@Nm+R(Mdj",
        },
      });
      let data = await response.json();
      this.nextPage = data.next_page;
      this.devices = data.devices;
      this.devices_list = data.devices;
      // this.toggleTestDevices();
      this.loadingData = !this.loadingData;
    },
    searchDevice() {
      this.loadingData = !this.loadingData;
      if (this.search.length < 3) {
        this.devices = this.devices_list;
      } else {
        this.devices = this.devices_list.filter((d) => {
          if (d.order) {
            if (d.order.user) {
              let keyword = new RegExp(this.search);
              if (keyword.test(d.order.user.email)) {
                return d;
              }
            }
          }
        });
      }
      this.loadingData = !this.loadingData;
    },
    resetSearch() {
      this.search = "";
      this.devices = this.devices_list;
    },
    // toggleTestDevices() {
    //   this.testDevices = !this.testDevices;
    //   this.testDevices
    //     ? (this.devices = this.devices_list.filter((d) => {
    //         return !d.order;
    //       }))
    //     : (this.devices = this.devices_list.filter((d) => {
    //         return d.order;
    //       }));
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
.loading-data {
  position: absolute;
  padding: 2rem;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  display: block;
  h5 {
    margin-top: 2.5rem;
  }
}
</style>
