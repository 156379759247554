<template lang="pug">
.sidebar.position-fixed.p-4
  .sidebar__content
    .d-inline-flex.w-100.justify-content-between
      h3 Registra consegna # {{deliveryIndex + 1}} ordine {{ orderId }}
      button(type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$parent.toggleDetails()")
        span(aria-hidden="true") &times;
    .row.mt-4
      .col-12.col-md-6
        label.font-weight-bold(for="shipping_date") Data Spedizione
        input.form-control.form-control-sm(v-model="formattedShippingDate" type="date" name="shipping_date" readonly)
      .col-12.col-md-6
        label.font-weight-bold(for="delivery_date") Data ricezione
        input.form-control.form-control-sm(v-model="formattedDeliveryDate" type="date" name="delivery_date")
    .mt-4(v-if="!isOperator")
      .font-weight-bold Note Interne
      textarea.form-control.form-control-sm.mt-2(v-model="delivery.internal_notes" rows="4")
    .row.mt-4.mb-3
      .col-12.col-md-6 
        .font-weight-bold SKU
      .col-12.col-md-6 
        .font-weight-bold Quantità ricevuta
    .row.mb-2(v-for="item in delivery.items")
      .col-12.col-md-6(:key="item.id")
        .font-weight-bold {{ item.sku }}
        small {{ item.name }}
      .col-12.col-md-6 
        .d-inline-flex.align-items-center
          input.form-control.form-control-sm(v-model="item.quantity_received" type="number" name="quantity_received")
          span /{{ item.quantity }}
    table.table.mt-4(style="table-layout: fixed;")
      thead
        tr
          th Controllo
          td.text-center.text-success.font-weight-bold OK
          td.text-center.text-danger.font-weight-bold KO
      tbody
        tr
          td Esteriore merce
          td
            input.form-control(type="radio" v-model="delivery.check.aesthetic" :value="0" name="check_aesthetic")
          td
            input.form-control(type="radio" v-model="delivery.check.aesthetic" :value="1" name="check_aesthetic")
        tr
          td Corrispondenza SKU
          td
            input.form-control(type="radio" v-model="delivery.check.skus" :value="0" name="check_skus")
          td
            input.form-control(type="radio" v-model="delivery.check.skus" :value="1" name="check_skus")
    //- table.table.mt-4(style="table-layout: fixed;")
      thead
        tr
          th Controllo
          td Non sufficiente
          td Buono
          td Molto Buono
          td Eccellente
      tbody
        tr
          td Estetico
          td
            input.form-control(type="radio" v-model="delivery.check.aesthetic" :value="1" name="check_aesthetic")
          td
            input.form-control(type="radio" v-model="delivery.check.aesthetic" :value="2" name="check_aesthetic")
          td
            input.form-control(type="radio" v-model="delivery.check.aesthetic" :value="3" name="check_aesthetic")
          td
            input.form-control(type="radio" v-model="delivery.check.aesthetic" :value="4" name="check_aesthetic")
        tr
          td Dimensionale
          td
            input.form-control(type="radio" v-model="delivery.check.dimensional" :value="1" name="check_dimensional")
          td
            input.form-control(type="radio" v-model="delivery.check.dimensional" :value="2" name="check_dimensional")
          td
            input.form-control(type="radio" v-model="delivery.check.dimensional" :value="3" name="check_dimensional")
          td
            input.form-control(type="radio" v-model="delivery.check.dimensional" :value="4" name="check_dimensional")
        tr
          td Qualitativo
          td
            input.form-control(type="radio" v-model="delivery.check.quality" :value="1" name="check_quality")
          td
            input.form-control(type="radio" v-model="delivery.check.quality" :value="2" name="check_quality")
          td
            input.form-control(type="radio" v-model="delivery.check.quality" :value="3" name="check_quality")
          td
            input.form-control(type="radio" v-model="delivery.check.quality" :value="4" name="check_quality")
        tr
          td Funzionale
          td
            input.form-control(type="radio" v-model="delivery.check.functional" :value="1" name="check_functional")
          td
            input.form-control(type="radio" v-model="delivery.check.functional" :value="2" name="check_functional")
          td
            input.form-control(type="radio" v-model="delivery.check.functional" :value="3" name="check_functional")
          td
            input.form-control(type="radio" v-model="delivery.check.functional" :value="4" name="check_functional")
    .mt-4
      .font-weight-bold Foto e altri documenti
      S3FileUploader(:orderId="orderId" :deliveryIndex="deliveryIndex" @filesUploaded="saveFilesKey" useCase="images" buttonClass="btn btn-sm btn-light")
      .d-inline-flex.flex-wrap.mt-2(v-if="imagesLinks.length")
        .position-relative.shadow.mr-2.p-2(v-for="(image, index) in imagesLinks" :key="image")
          a(:href="image" target="_blank")
            .d-block.text-center(v-if="image.includes('.pdf')")
              i.fa.fa-file-pdf.h2
              br
              span Allegato {{ index }}
            img(v-else :src="image" width="60px")
          .position-absolute.image-delete.circle.bg-gray.text-center.c-pointer(@click="deleteImage(index)") &times;
    .mt-4
      .font-weight-bold Segnalazioni
      textarea.form-control.form-control-sm.mt-2(v-model="delivery.notes" rows="4")
  .py-3
    button.btn.btn-success.btn-block(@click="updateOrder(true)" :disabled="isLoading" :class="{'loading': isLoading}") {{isUpdated ? 'Consegna salvata!' : 'Conferma' }}
</template>

<script>
import S3FileUploader from "../utils/S3FileUploader";
import S3Service from "@/services/S3Service";
export default {
  name: "SingleInboundOrderDetails",
  props: ['orderId','delivery','deliveryIndex'],
  data() {
    return {
      isOperator: this.$router.user.roleScore < 0,
      check: {
        aesthetic: -1,
        skus: -1,
      },
      isLoading: false,
      isUpdated: false,
      imagesLinks: []
    }
  },
  components: { S3FileUploader },
  computed: {
    formattedShippingDate: {
      get() {
        return this.delivery.shipping_date?.substring(0, 10);
      },
      set(value) {
        this.delivery.shipping_date = new Date(value).toISOString();
      }
    },
    formattedDeliveryDate: {
      get() {
        return this.delivery.delivery_date?.substring(0, 10);
      },
      set(value) {
        this.delivery.delivery_date = new Date(value).toISOString();
      }
    },
  },
  async mounted(){
    await this.getImagesLinks()
  },
  methods: {
    async getImagesLinks(){
      this.imagesLinks = []
      if (this.delivery.images?.length) {
        this.imagesLinks = await Promise.all(this.delivery.images.map(async(image) => {
          return await this.getFileUrl(image)
        }))
      }
    },
    saveFilesKey(data){
      this.delivery.images = [...this.delivery.images, ...data.fileKeys]
      this.updateOrder(false)
    },
    deleteImage(index) {
      this.delivery.images.splice(index, 1)
      this.updateOrder(false)
      this.getImagesLinks()
    },
    async getFileUrl(key){
      const { data } = await S3Service.getFileUrl(key)
      return data
    },
    async updateOrder(dismiss) {
      this.isLoading = true
      this.delivery.delivery_date ? this.delivery.status = 'delivered' : ''
      await this.$parent.updateOrder()
      await this.getImagesLinks()
      setTimeout(()=>{ 
        this.isLoading = false 
        this.isUpdated = true
      }, 1000)
      if (dismiss) {
        setTimeout(()=>{ this.$parent.toggleDetails() }, 2500)
      }
      
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
.sidebar {
  top: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 9;
  width: 550px;
  box-shadow: 0 -.2rem .5rem grey;
  height: 100vh;
  &__content {
    height: calc(100vh - 6rem);
    overflow-y: scroll;
  }
}
input[type="number"]{
  width: 4rem;
}
.image-delete {
  z-index: 1;
  top: -.4rem;
  right: -.4rem;
  margin: 0;
  line-height: 1.75;
}
</style>
