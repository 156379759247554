<template>
  <div class="card mt-4">
    <form class="form m-4" @submit.prevent>
      <h5 class="mb-4">Dismiss Product</h5>
      <input
        class="form-control form-control-sm mb-2"
        type="text"
        name="product_serial"
        placeholder="Product Serial"
        v-model="productSerial"
        @blur="getProductSerial(productSerial)"
      />
      <div></div>
      <button class="btn btn-md btn-outline-danger btn-block">Dismiss</button>
      <div class="alert alert-info" v-if="message">{{ message }}</div>
    </form>
  </div>
</template>
<script>
import ProductionService from "@/services/ProductionService";
export default {
  name: "DismissProduct",
  data() {
    return {
      productSerial: '',
      message: false
    }
  },
  methods: {
    async getProductSerial(serial){
      const response = await ProductionService.getProductSerials(serial)
      const data = response.data
      const idNumber = response.data.id_number
      if (idNumber) {
        const order = await this.getProductionOrder(idNumber)
        const productIndex = order.serials.findIndex(s => s.product === serial)
        if (productIndex >= 0) {
          if (confirm(`Are you sure you want to dismiss the following product? \r\n 
            MAC: ${data.serials.mac} \r
            SKU: ${data.product.sku} \r
            Name: ${data.product.name} \r
            Production Date: ${data.serials.timestamp}`)) {
              order.serials[productIndex].active = false
              await this.updateProductionOrder(idNumber, order)
            }
        }
      }
      this.productSerial = ''
    },
    async getProductionOrder(id) {
      const response = await ProductionService.getProductionOrder(id)
      return response.data
    },
    async updateProductionOrder(id, order) {
      const response = await ProductionService.updateProductionOrder(id, order)
      this.message = response.data
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
