<template>
  <section class="orders">
    <div class="table-wrap">
      <h2>Shipping</h2>
      <div
        v-if="$router.user.roleScore >= 2"
        class="row no-gutters mt-2 actions__wrapper"
      >
        <div class="col-12 col-md-2">
          <template>
            <h6 class="actions__title">Create Shipment</h6>
            <button
              @click="shipAll"
              class="btn btn-warning"
              :class="{ loading: isLoading }"
              :disabled="isLoading || loadingData || !orders_count > 0"
            >
              <i class="fa fa-truck"></i> Ship
              <input
                id="to_ship_count"
                type="number"
                style="width: 4rem; text-align: center;"
                @click.prevent
                v-model="toShipCount"
              />
              orders
            </button>
          </template>
        </div>
        <div class="col-12 col-md-4">
          <h6 class="actions__title">Print Labels</h6>
          <printFiles
            :buttonDisabled="!shippingLabels.all.length || !printAllReady"
            :s3Keys="shippingLabels.all"
            buttonText="ALL"
            buttonClass="btn-dark"
          />
          <printFiles
            :buttonDisabled="!shippingLabels.ups.length || !printAllReady"
            :s3Keys="shippingLabels.ups"
            buttonText="UPS"
            buttonClass="ups"
          />
          <printFiles
            :buttonDisabled="!shippingLabels.fedex.length || !printAllReady"
            :s3Keys="shippingLabels.fedex"
            buttonText="FedEx"
            buttonClass="fedex"
          />
          <printFiles
            :buttonDisabled="!shippingLabels.dhl.length || !printAllReady"
            :s3Keys="shippingLabels.dhl"
            buttonText="DHL"
            buttonClass="dhl"
          />
          <button
            class="btn"
            @click="prepareLabels()"
            :disabled="!printAllReady"
          >
            <i class="fas fa-sync"></i>
          </button>
        </div>
        <div class="col-12 col-md-3">
          <h6 class="actions__title">Pickup</h6>

          <button
            @click="createPickup('fedex', 'TGT')"
            :disabled="isLoading"
            :class="{ loading: isLoading }"
            class="btn fedex"
          >
            <i class="fa fa-truck"></i> <b>Fed<span>Ex</span></b> (TGT)
          </button>
          <button
            @click="createPickup('dhl', 'TGT')"
            :disabled="isLoading"
            :class="{ loading: isLoading }"
            class="btn dhl"
          >
            <i class="fa fa-truck"></i> <b>DHL</b> (TGT)
          </button>
          <button
            @click="createPickup('fedex', 'TAGPN')"
            :disabled="isLoading"
            :class="{ loading: isLoading }"
            class="btn fedex mt-2"
          >
            <i class="fa fa-truck"></i> <b>Fed<span>Ex</span></b> (TAGPN)
          </button>
          <button
            @click="createPickup('dhl', 'TAGPN')"
            :disabled="isLoading"
            :class="{ loading: isLoading }"
            class="btn dhl mt-2"
          >
            <i class="fa fa-truck"></i> <b>DHL</b> (TAGPN)
          </button>
        </div>
        <div class="col-12 col-md-3">
          <h6 class="actions__title">Delete Pickup</h6>
          <input
            id="pickup_number"
            type="text"
            class="form-control form-control-sm mb-1"
            style="width: auto;"
            @click.prevent
            v-model="pickupConfirmationNumber"
            placeholder="Enter pickup number"
          />
          <button
            @click="cancelPickup('fedex')"
            class="btn btn-danger"
            :class="{ loading: isLoading }"
            :disabled="isLoading"
          >
            <i class="fa fa-calendar-times"></i> FedEx
          </button>
          <button
            @click="cancelPickup('dhl')"
            class="btn btn-danger"
            :class="{ loading: isLoading }"
            :disabled="isLoading"
          >
            <i class="fa fa-calendar-times"></i> DHL
          </button>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-12 align-self-end filters">
          <div class="row">
            <div class="col-auto">
              <div><label>ID Number</label></div>
              <div class="form-inline">
                <input
                  class="form-control form-control-sm m-0"
                  type="text"
                  v-model="filters.keyword"
                  @keyup.enter="getShippingOrders()"
                />
                <button
                  class="btn btn-primary btn-sm m-0"
                  @click="getShippingOrders()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
              <br />
              <div><label>Tracking Number</label></div>
              <div class="form-inline">
                <input
                  class="form-control form-control-sm m-0"
                  type="text"
                  v-model="filters.tracking_number"
                  @keyup.enter="getShippingOrders()"
                />
                <button
                  class="btn btn-primary btn-sm m-0"
                  @click="getShippingOrders()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="col-12 col-md-auto">
              <label>Dates:</label>
              <input
                type="date"
                v-model="filters.date_start"
                @keyup.enter="getShippingOrders()"
                class="form-control form-control-sm m-0"
                placeholder="From:"
                />
                <input
                type="date"
                v-model="filters.date_end"
                @keyup.enter="getShippingOrders()"
                class="form-control form-control-sm m-0"
                placeholder="To:"
                />
            </div>
            <div class="col-12 col-md-auto">
              <label>Sort by:</label>
              <select
                v-model="filters.sort_by"
                @change="getShippingOrders()"
                class="form-control form-control-sm"
              >
                <option
                  v-for="option in sort_by_options"
                  :key="option.name"
                  :value="option"
                  v-text="option.name"
                >
                </option>
              </select>
            </div>
            <div class="col-auto">
              <label>Product:</label>
              <br />
              <select class="m-0 form-contro form-control-sm" v-model="filters.product_group">
                <option selected="selected" value="">All Products</option>
                <option value="SH">Shelfy</option>
                <option value="ET">Eteria</option>
                <option value="NT">Natede</option>
                <option value="FZ">Febreze</option>
                <option value="IA">IrenAria</option>
                <option value="CY">Clairy</option>
              </select>
              <div class="form-group">
                <input type="text" placeholder="Product SKU 1" v-model="filters.product_skus[0]" class="form-control form-control-sm m-0 mt-1"/>
                <input type="text" placeholder="Product SKU 2" v-model="filters.product_skus[1]" class="form-control form-control-sm m-0 mt-1"/>
                <input type="text" placeholder="Product SKU 3" v-model="filters.product_skus[2]" class="form-control form-control-sm m-0 mt-1"/>
              </div>
            </div>
            <div class="col-auto">
              <label>Shipping Status</label>
              <!-- <div class="dropdown">
                <div class="dropdown-toggle c-pointer btn btn-sm btn-dark" @click="shippingStatusToggle = !shippingStatusToggle">Shipping Status</div>
                <div class="dropdown-menu" :class="{'d-block':shippingStatusToggle}"> -->
                  <div class="form-check" v-for="s in shipping_status" :key="s">
                    <input
                      type="checkbox" class="form-check-input"
                      :id="s" :value="s"
                      v-model="filters.shipping_status"
                    />
                    <label class="form-check-label" :for="s">{{ s }}</label>
                 </div>
                  <!-- <button class="btn btn-block mt-1" @click="shippingStatusToggle = !shippingStatusToggle">Ok</button>
                </div>
              </div> -->
              
              <!-- <label>Hide Statuses:</label>
              <div class="form-check">
                <div class="row no-gutters">
                  <div class="col-6">
                    <div>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="hide_-"
                        value="-"
                        v-model="filters.hide_statuses"
                      />
                      <label class="form-check-label" for="hide_-">-</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="hide_ready-for-shipping"
                        value="ready-for-shipping"
                        v-model="filters.hide_statuses"
                      />
                      <label
                        class="form-check-label"
                        for="hide_ready-for-shipping"
                        >Ready for Shipping</label
                      >
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="hide_in-transit"
                        value="in-transit"
                        v-model="filters.hide_statuses"
                      />
                      <label class="form-check-label" for="hide_in-transit"
                        >In Transit</label
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="hide_exception"
                        value="exception"
                        v-model="filters.hide_statuses"
                      />
                      <label class="form-check-label" for="hide_exception"
                        >Exception</label
                      >
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="hide_returned"
                        value="returned"
                        v-model="filters.hide_statuses"
                      />
                      <label class="form-check-label" for="hide_returned"
                        >Returned</label
                      >
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="hide_hand-delivered"
                        value="hand-delivered"
                        v-model="filters.hide_statuses"
                      />
                      <label class="form-check-label" for="hide_hand-delivered"
                        >Hand Delivered</label
                      >
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="col-auto">
              <div class="btn-group-vertical actions">
                <button
                class="btn btn-primary btn-sm"
                @click="getShippingOrders()"
                >
                <i class="fa fa-filter"></i> Filter
              </button>
              <button
                class="btn btn-sm"
                style="color: #cc392e; border: 1px solid #cc392e;"
                @click="
                  (filters.hide_scanned = !filters.hide_scanned), filterOrders()
                "
              >
                <i
                  class="fa fa-eye"
                  :class="{ 'fa fa-eye-slash': filters.hide_scanned }"
                ></i>
                Scanned
              </button>
                <button class="btn btn-danger btn-sm" @click="resetFilters()">
                  <i class="fa fa-times"></i> Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>Actions ({{ orders.length }})</th>
              <th># Order</th>
              <th>Date</th>
              <th>Status / Shipping Status</th>
              <th>Products</th>
              <th>
                Shipments
                <button
                  class="btn btn-sm btn-square ups"
                  @click="trackShippings()"
                >
                  <i class="fa fa-shipping-fast"></i>
                </button>
              </th>
              <th>Shipping Address</th>
              <th>Shipping Total/Rate</th>
              <th>Shipping Method</th>
              <th>Notes</th>
              <th>Internal Notes</th>
              <th>Channel Group</th>
              <th>Channel</th>
              <th>Typology</th>
            </tr>
          </thead>
          <tbody v-if="!loadingData && orders_count > 0">
            <SingleShippingOrder
              v-for="order in orders"
              :key="order.id"
              :order="order"
              :scanning="selectedPackage"
            >
            </SingleShippingOrder>
          </tbody>
          <span v-else>
            <div class="loading-data" v-if="loadingData">
              <span class="loading"></span>
              <h5>Loading orders ...</h5>
            </div>
            <div class="loading-data" v-else>
              <h6>No orders found.</h6>
            </div>
            <div class="clearer"></div>
          </span>
        </table>
      </div>
    </div>
    <button
      v-if="$router.user.roleScore === -1 || $router.user.roleScore >= 2"
      class="scanner__toggle btn"
      :class="!isScanning ? 'play' : 'stop'"
      @click.prevent="scannerStatus"
    >
      <i :class="!isScanning ? 'fa fa-play' : 'fa fa-stop'"></i>
      {{ !isScanning ? "Start Scanning" : "Stop Scanning" }}
    </button>
    <div v-if="isScanning" class="scanner__wrapper row">
      <div class="col-12 col-md-6">
        <input
          class="scanner__input"
          type="text"
          id="scanner"
          placeholder="Scan something..."
          ref="scannerInput"
          v-model.trim="scannerInput"
          @keydown.tab.prevent="scannerController($event.target)"
          @focus="$event.target.select()"
          autofocus
        />
        <kbd v-if="selectedTrackingNumber.length > 0"
          >Tracking Number: {{ selectedTrackingNumber }} | Order:
          {{ selectedPackage.id_number }}</kbd
        >
      </div>
      <div class="col-12 col-md-6">
        <div
          class="scanner__instructions"
          v-text="scannerInstructions"
          :class="{ error: scannerError }"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import OrdersService from "@/services/OrdersService";
import SingleShippingOrder from "./SingleShippingOrder";
import ShippingService from "@/services/ShippingService";
import ProductionService from "@/services/ProductionService";
import printFiles from "../utils/printFiles";
import { sort_by_options, shipping_status } from "@/assets/data.json";
export default {
  name: "ShippingOrders",
  data() {
    return {
      orders_list: "",
      orders: "",
      orders_count: "",
      sort_by_options: sort_by_options,
      loadingData: false,
      printAllReady: false,
      shippingLabels: { ups: [], fedex: [], dhl: [], all: [] },
      isScanning: false,
      scannerInput: "",
      selectedTrackingNumber: "",
      selectedPackage: "",
      scannerInstructions: "",
      scannerError: false,
      pickupConfirmationNumber: "",
      errorSound: new Audio(
        "https://s3-eu-west-1.amazonaws.com/clairy-gateway/no.mp3"
      ),
      successSound: new Audio(
        "https://s3-eu-west-1.amazonaws.com/clairy-gateway/yes.mp3"
      ),
      trackingSound: new Audio(
        "https://s3-eu-west-1.amazonaws.com/clairy-gateway/tracking.wav"
      ),
      shipping_status: shipping_status,
      filters: {
        shipping_status: ['-','returned'],
        // hide_statuses: ["ready-for-shipping","in-transit", "exception", "hand-delivered"],
        hide_scanned: false,
        keyword: "",
        tracking_number: "",
        product_group: "",
        product_skus: [],
        date_start: null,
        date_end: null,
        sort_by: sort_by_options[1]
      },
      toShipCount: 100,
      isLoading: false,
    };
  },
  components: {
    SingleShippingOrder,
    printFiles,
  },
  mounted() {
    this.getShippingOrders();
    window.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.scannerStatus();
      }
      if (this.isScanning && e.keyCode === 13) {
        this.$nextTick(() => {
          this.$refs.scannerInput.focus();
        });
      }
    });
  },
  sockets: {
    orderUpdated(updatedOrder) {
      console.log(
        "[socket.io] An order has been updated. " + updatedOrder.id_number
      );
      this.orders.filter((order, index) => {
        if (order.id_number === updatedOrder.id_number) {
          this.orders.splice(index, 1, updatedOrder);
        }
      });
    },
  },
  methods: {
    async getShippingOrders() {
      this.loadingData = !this.loadingData;
      const response = await OrdersService.getShippingOrders({
        keyword: this.filters.keyword,
        tracking_number: this.filters.tracking_number,
        shipping_status: this.filters.shipping_status,
        // shipping_statuses: this.filters.hide_statuses,
        product_group: this.filters.product_group,
        product_skus: this.filters.product_skus,
        sort_by: this.filters.sort_by,
        date_start: this.filters.date_start,
        date_end: this.filters.date_end,
      });
      this.orders_list = response.data;
      this.orders = this.orders_list;
      if (this.$router.user.roleScore < 0) {
        this.orders = this.orders.filter((order) => {
          return order.shipping_status === "ready-for-shipping";
        });
      }
      this.orders_count = this.orders.length;
      this.loadingData = !this.loadingData;
      this.prepareLabels();
    },
    sortOrders(){
      const sortBy = this.filters.sort_by.property
      const sortByDirection = this.filters.sort_by.direction
      this.orders = this.orders.sort((a, b) => a[sortBy].toString().localeCompare(b[sortBy].toString()) * sortByDirection)
    },
    async shipAll() {
      this.isLoading = !this.isLoading;
      let shippingErrors = [];
      let shippingOrders = this.orders
        .filter((order) => {
          return (
            (order.sap_documents?.invoice?.number || order.sap_documents?.ddt?.number || order.sap_documents?.return?.number) &&
            order.status === "processed" &&
            order.shipping_status === "-" &&
            !order.shipping_details.tracking_numbers.length
          );
        })
        .splice(0, this.toShipCount);
      this.toShipCount = shippingOrders.length;
      if (
        confirm(
          `Are you sure? You're going to ship ${shippingOrders.length} orders.`
        )
      ) {
        shippingOrders.forEach(async (order) => {
          const response = await ShippingService.newShipping(order.id_number);
          if (response.data.error) {
            let shipError = `Order #${order.id_number} error: ${JSON.stringify(
              response.data.error
            )}`;
            shippingErrors.push(shipError);
          }
        });
      }
      console.log(shippingErrors);
      this.isLoading = !this.isLoading;
    },
    filterOrders() {
      if (this.filters.hide_scanned) {
        this.orders = this.orders_list.filter((order) => {
          return !order.packages[0]?.serials.length > 0;
        });
      } else {
        this.orders = this.orders_list;
      }
    },
    resetFilters() {
      this.filters = {
        // hide_statuses: ["in-transit", "exception"],
        shipping_status: ['-','returned'],
        hide_scanned: false,
        keyword: "",
        tracking_number: "",
        product_group: "",
        product_skus: [],
        date_start: null,
        date_end: null,
        sort_by: sort_by_options[1]
      };
    },
    async trackShippings() {
      await ShippingService.trackShippings();
    },
    async createPickup(courier, id) {
      this.isLoading = true;
      await ShippingService.createPickup({courier: courier, departure_location: id});
      this.isLoading = false;
    },
    async cancelPickup(courier) {
      if (this.pickupConfirmationNumber.length) {
        this.isLoading = true;
        const response = await ShippingService.cancelPickup({
          courier: courier,
          pickupConfirmationNumber: this.pickupConfirmationNumber,
          pickupDate: new Date().toISOString(),
        });
        console.log(response.data);
        this.pickupConfirmationNumber = "";
        this.isLoading = false;
      }
    },
    prepareLabels() {
      this.shippingLabels = { ups: [], fedex: [], dhl: [], all: [] };
      this.printAllReady = false;
      this.orders
        .filter((order) => {
          return (
            order.shipping_status === "ready-for-shipping" &&
            !order.packages[0]?.serials.length > 0
          );
        })
        .map((order) => {
          order.shipping_details.labels.map((label) => {
            if (
              label.indexOf("shipping") > -1 &&
              label.indexOf("labels") > -1
            ) {
              if (order.shipping_details.courier === "ups") {
                this.shippingLabels.ups.push(label);
              }
              if (order.shipping_details.courier === "fedex") {
                this.shippingLabels.fedex.push(label);
              }
              if (order.shipping_details.courier === "dhl") {
                this.shippingLabels.dhl.push(label);
              }
              this.shippingLabels.all.push(label);
            }
          });
        });
      this.printAllReady = true;
    },
    async updateOrder(id, orderData) {
      this.isLoading = !this.isLoading;
      await OrdersService.updateOrder(id, orderData).then(() => {
        this.isLoading = !this.isLoading;
      });
    },
    scannerStatus() {
      this.isScanning = !this.isScanning;
      if (!this.isScanning) {
        this.scannerInput = "";
        this.selectedTrackingNumber = "";
        this.selectedPackage = "";
      } else {
        this.$nextTick(() => {
          this.$refs.scannerInput.focus();
        });
        this.scannerInstructions =
          "Let's start! Scan a shipping label (tracking number).";
        this.scannerError = false;
      }
    },
    sendError(message) {
      this.scannerInstructions = message;
      this.scannerError = true;
      this.errorSound.play();
    },
    async scannerController(target) {
      // If UPS, Fedex or DHL tracking number start format
      if (
        this.scannerInput.includes("1Z") ||
        this.scannerInput.includes("JD") ||
        this.scannerInput.includes("JJD") ||
        (this.scannerInput.substr(0, 1) > "0" &&
          this.scannerInput.substr(0, 1) <= "9")
      ) {
        // DHL barcode fixer
        this.scannerInput.substr(0, 3) === "JJD"
          ? (this.scannerInput = this.scannerInput.slice(1))
          : "";

        // If a tracking number has been scanned
        this.selectedTrackingNumber = "";
        this.selectedPackage = "";
        this.orders.map((order, orderIndex) => {
          order.shipments.map((p, packageIndex) => {
            // Find order's single package
            if (p.tracking_number === this.scannerInput) {
              this.selectedTrackingNumber = this.scannerInput;
              this.selectedPackage = {
                id_number: order.id_number,
                orderIndex: orderIndex,
                packageIndex: packageIndex,
              };
            }
          });
        });
        if (this.selectedTrackingNumber.length > 0) {
          let pos = document.getElementById(this.selectedPackage.id_number)
            .offsetTop;
          //window.scrollTo({ top: pos + 140, behavior: "smooth" });
          window.scrollTo({ top: pos + 140 });
          this.scannerInstructions = "Ok fine! now scan a product's QrCode.";
          this.scannerError = false;
          this.trackingSound.play();
        } else {
          this.sendError("Tracking Number not found!");
        }
      } else {
        // Else a product serial has been scanned
        this.scannerInput = this.scannerInput.substr(0,11)
        if (!this.selectedTrackingNumber.length) {
          this.sendError("No package selected! Scan a shipping label first!");
        } else {
          const response = await ProductionService.getProductSerials(this.scannerInput)
          console.log(response)
          if (response.data.error) {
            this.sendError(response.data.error);
          } else {
            let serialSku = response.data.serials.sku;
            let relativePackage = this.orders[this.selectedPackage.orderIndex]
              .shipments[this.selectedPackage.packageIndex];
            if (relativePackage.serials.includes(this.scannerInput)) {
              this.sendError(
                "Duplicate product! This product has just been added in this package."
              );
            } else {
              if (relativePackage.products.includes(serialSku)) {
                // If scanned serial sku is in this package
                relativePackage.serials.push(this.scannerInput); // Add scanned serial to package serials list
                this.updateOrder(
                  this.orders[this.selectedPackage.orderIndex].id_number,
                  this.orders[this.selectedPackage.orderIndex]
                ); // Save scanned serial in Database
                this.scannerError = false;
                this.successSound.play();
                this.scannerInstructions =
                  "Good job! Now scan another product for this package or another shipping label to change package.";
              } else {
                this.sendError(
                  "Wrong product! This product is not part of this package."
                );
              }
            }
          }
        }
      }
      this.$nextTick(() => {
        this.$refs.scannerInput.focus();
        target.select();
      });
      target.select();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
table {
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
  thead {
    color: #fff;
    tr {
      background-color: $gray02 !important;
      th {
        border: 1px solid !important;
        border-color: $gray03 !important;
        white-space: nowrap;
        font-weight: 300;
        padding: 0.75rem;
        position: relative;
        .btn {
          position: absolute;
          right: 1rem;
          top: 0.4rem;
          background-color: $gray03;
          color: #fff;
        }
      }
    }
  }
  td,
  th {
    vertical-align: middle !important;
    span {
      display: block;
    }
  }
}
tbody {
  tr {
    background-color: #fff;
    &:nth-of-type(odd) {
      background-color: #f9f9f9 !important;
    }
    &:hover {
      background-color: #f4f4f4 !important;
    }
  }
}
.actions {
  &__wrapper {
    padding: 1.5rem;
    background-color: #fff;
    .btn {
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
  &__title {
    text-transform: uppercase;
    font-weight: 600;
  }
}

.loading-data {
  position: absolute;
  padding: 2rem;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  display: block;
  h5 {
    margin-top: 2.5rem;
  }
}
.clearer {
  display: block;
  padding: 4rem;
}

.scanner__toggle {
  position: fixed;
  right: 2rem;
  top: 6.5rem;
  z-index: 999;
  border: 4px solid $red;
  color: $red;
  background-color: #1b1b1b;
  &.stop {
    background-color: #fff;
  }
  &:hover {
    background-color: $red;
    color: #fff;
  }
}
.scanner__wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: $gray04;
  padding: 2rem;
  box-shadow: 0 0 1rem 0 #000;
  kbd {
    display: inline-block;
  }
  .scanner {
    &__input {
      padding: 2rem;
      font-size: 1rem;
      width: 100%;
      border: 4px solid transparent;
      &:focus {
        border-color: $blue;
      }
    }
    &__instructions {
      font-size: 1rem;
      color: #fff;
      border: 4px solid #fff;
      padding: 2rem;
      &.error {
        background-color: $red;
      }
    }
  }
}
.filters {
  background-color: $gray02;
  padding: 1rem;
  label {
    font-weight: 700;
    color: #fff;
  }
}
.form-check {
  padding-left: 0;
  &-input {
    margin: 0 !important;
    margin-top: 0.5rem !important;
  }
  &-label {
    padding: 0.25rem 1.25rem;
    font-weight: 400 !important;
  }
  min-width: 320px;
}
</style>
