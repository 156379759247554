import Vue from "vue";
import App from "./App";
import router from "./router";
import "./utils/filters"

new Vue({
  // eslint-disable-line no-new
  render: (h) => h(App),
  router,
  components: {
    App,
  },
  template: "<App/>",
}).$mount("#app");

export default {
  runtimeCompiler: true,
};
