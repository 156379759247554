<template>
<div class="test-tag" :class="`test-tag--${status}`">{{ status || '-' }}</div>
</template>
<script>
export default {
  name: "TestTag",
  props: ['status']
}
</script>
<style lang="scss" scoped>
.test-tag {
  width: 80px;
  padding: .4rem .8rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  color: white;
  background-color: #F6D22F;
  &--pass {
    background-color: #9AD47D;
  }
  &--fail {
    background-color: #F26E82;
  }
}
</style>
