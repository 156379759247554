import Vue from "vue";
import Router from "vue-router";

import Login from "@/components/Login";
import Dashboard from "@/components/Dashboard";
import Imports from "@/components/orders/Imports";
import Orders from "@/components/orders/Orders";
import SingleOrderDetails from "@/components/orders/SingleOrderDetails";
import NewOrder from "@/components/orders/NewOrder";
import NewLead from "@/components/leads/NewLead";
import Users from "@/components/Users";
import Products from "@/components/products/Products";
import InboundOrders from "@/components/production/InboundOrders";
import ProductionOrders from "@/components/production/ProductionOrders";
import ProductionOrdersNC from "@/components/production/ProductionOrdersNC";
import ProductionOrderDetails from "@/components/production/ProductionOrderDetails";
import ProductionOrderSerialRedirector from "@/components/production/ProductionOrderSerialRedirector";
import ProductionOrderCertificate from "@/components/production/ProductionOrderCertificate";
import NewProductionOrder from "@/components/production/NewProductionOrder";
import ProductionTests from "@/components/production/ProductionTests";
import DeviceTesting from "@/components/production/DeviceTesting";
import SingleProductionTest from "@/components/production/SingleProductionTest";
import ShippingOrders from "@/components/shipping/ShippingOrders";
import Devices from "@/components/devices/Devices";
import AuthService from "@/services/AuthService";
const config = require("../config.json");

import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";

Vue.use(Router);
const router = new Router({
	mode: "history",
	user: false,
	routes: [
		{
			path: "/",
			name: "Dashboard",
			component: Dashboard,
		},
		// Auth
		{
			path: "/login",
			name: "Login",
			component: Login,
		},
		// Imports
		{
			path: "/imports",
			name: "Imports",
			component: Imports,
		},
		// Orders
		{
			path: "/orders",
			name: "Orders",
			component: Orders,
		},
		{
			path: "/orders/:id",
			name: "SingleOrderDetails",
			component: SingleOrderDetails,
		},
		{
			path: "/orders/new",
			name: "NewOrder",
			component: NewOrder,
		},
		// Leads
		{
			path: "/leads/new",
			name: "NewLead",
			component: NewLead,
		},
		// Products
		{
			path: "/products",
			name: "Products",
			component: Products,
		},
		// Users
		{
			path: "/users",
			name: "Users",
			component: Users,
		},
		// Production
		{
			path: "/inbound-orders",
			name: "InboundOrders",
			component: InboundOrders,
		},
		{
			path: "/production",
			name: "Production",
			component: ProductionOrders,
		},
		{
			path: "/production-nc",
			name: "Production NC",
			component: ProductionOrdersNC,
		},
		{
			path: "/production/:id_number",
			name: "ProductionOrderDetails",
			component: ProductionOrderDetails,
		},
		{
			path: "/production/serial/:serial",
			name: "ProductionOrderSerialRedirector",
			component: ProductionOrderSerialRedirector,
		},
		{
			path: "/production/:id_number/certificate",
			name: "ProductionOrderCertificate",
			component: ProductionOrderCertificate,
		},
		{
			path: "/production/new",
			name: "NewProductionOrder",
			component: NewProductionOrder,
		},
		{
			path: "/production-test",
			name: "ProductionTests",
			component: ProductionTests,
		},
		{
			path: "/production-test/:id",
			name: "SingleProductionTest",
			component: SingleProductionTest,
		},
		{
			path: "/production-testing",
			name: "DeviceTesting",
			component: DeviceTesting,
		},
		// Shipping
		{
			path: "/shipping",
			name: "Shipping",
			component: ShippingOrders,
		},
		// Devices
		{
			path: "/devices",
			name: "Devices",
			component: Devices,
		},
		// Redirect
		{
			path: "*",
			redirect: "/",
		},
	],
});

router.beforeEach((to, from, next) => {
	if (to.path !== "/login") {
		if (router.user) {
			next();
		} else {
			AuthService.userInfo()
				.then((response) => {
					router.user = {
						name: response.data.name,
						email: response.data.email,
						role: response.data.role,
						roleScore: response.data.roleScore,
					};
					// resolve(router.user)
					const socket = io(config.server_url, { query: { user: router.user.email } })
					Vue.use(VueSocketIOExt, socket);
					next();
				})
				.catch(() => {
					// reject(error)
					router.user = false;
					next("login");
				});
		}
	} else {
		next();
	}
});

export default router;