import Vue from "vue"
import moment from "moment";

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm");
  }
});

Vue.filter("date", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.filter("formatTimestamp", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
  }
});

Vue.filter("pretty", function (value) {
  if (value) {
    value = JSON.stringify(value);
    return JSON.stringify(JSON.parse(value), null, 2).replace(/[{}",]/g, "");
  }
});

Vue.filter("currency", function (value) {
  let symbol = ''
  if (value) {
    value === 'USD' ? symbol = '$' : ''
    value === 'GBP' ? symbol = '£' : ''
    value === 'EUR' ? symbol = '€' : ''
  }
  return symbol
});

Vue.filter('price', function (value, currencyCode) {
  if (value && currencyCode) {
    return value.toLocaleString('en-US', { style: 'currency', currency: currencyCode })
  } else {
    return ''
  }
})

Vue.filter("label", function (value) {
  if (value) {
    return value.replace('_', ' ')
  }
});

Vue.filter("status", function (value) {
    switch(value) {
      case 'open':
        return 'Aperto'
      case 'closed':
        return 'Chiuso'
      case 'incoming':
        return 'In arrivo'
      case 'delivered':
        return 'Consegnato'
      case 'registered':
        return 'Registrato'
      default:
        return value
    }
});

Vue.filter("qty", function (value) {
  if (value) {
    return parseInt(value)
  }
});

Vue.mixin({
  methods: {
    copyToClipboard(textToCopy) {
        if (navigator.clipboard && window.isSecureContext) {
            return navigator.clipboard.writeText(textToCopy)
        } else {
            let textArea = document.createElement("textarea")
            textArea.value = textToCopy
            textArea.style.position = "fixed"
            textArea.style.left = "-999999px"
            textArea.style.top = "-999999px"
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej()
                textArea.remove()
            });
        }
    }
  },
})