<template>
	<section class="orders">
		<div v-if="$router.user.roleScore >= 0" class="table-wrap">
			<h2>Orders</h2>
			<div class="clearfix"></div>
			<div class="row no-gutters justify-content-between">
				<div class="col-1 align-self-end">
					<!-- <router-link
						v-if="$router.user.roleScore > 1 || $router.user.roleScore === 0"
						v-bind:to="{ name: 'NewLead' }"
						class="btn btn-outline-primary mb-4"
						>+ New Lead</router-link
					> -->
					<router-link
						v-if="$router.user.roleScore > 1 || $router.user.roleScore === 0"
						v-bind:to="{ name: 'NewOrder' }"
						class="btn btn-outline-success mb-4"
						>+ New order</router-link
					>
					<!-- <button
						v-if="this.$router.user.roleScore >= 2"
						class="btn btn-sm btn-square sap"
						:class="{ loading: isLoading }"
						:disabled="isLoading"
						@click.prevent="sapOldImportOrders()"
					>
						<i class="fa fa-cloud-upload-alt"></i>
						Old Importer
					</button> -->
				</div>
				<div class="col-auto row align-items-top filters" v-if="$router.user.roleScore >= 1">
					<div class="col-12 col-md-auto">
						<label>
							Keyword:
							<i class="fa fa-info-circle" title="Search by Order ID, Name or Email, SKU, Tracking Number, Payment Method"></i>
						</label>
						<input
							v-model="filters.keywordsearch"
							@keyup.enter="getFilteredOrders()"
							type="text"
							placeholder="Type keyword"
							class="form-control form-control-sm"
						/>
						<br>
						<label>Product:</label>
						<select
							v-model="filters.product_group"
							@keyup.enter="getFilteredOrders()"
							class="form-control form-control-sm"
						>
							<option selected="selected" value="">All Products</option>
							<option value="SH">Shelfy</option>
							<option value="ET">Eteria</option>
							<option value="NT">Natede</option>
							<option value="FZ">Febreze</option>
							<option value="IA">IrenAria</option>
							<option value="CY">Clairy</option>
						</select>
					</div>
					<div class="col-12 col-md-auto">
						<div class="row">
							<div class="col-6 mr-0">
								<label>Channel Group:</label>
								<select
									v-model="filters.channel_group"
									@keyup.enter="getFilteredOrders()"
									class="form-control form-control-sm"
								>
									<option selected="selected" :value="false">All Channel Groups</option>
									<option
										v-for="channel_group in channel_groups"
										:key="channel_group"
										:value="channel_group"
										v-text="channel_group"
									>
									</option>
								</select>
								<br>
								<label>Channel:</label>
								<select
									v-model="filters.channel"
									@keyup.enter="getFilteredOrders()"
									class="form-control form-control-sm"
								>
									<option selected="selected" :value="false">All Channels</option>
									<option
										v-for="channel in channels"
										:key="channel"
										:value="channel"
										v-text="channel"
									>
									</option>
								</select>
							</div>
							<div class="col-6">
								<label>Typology:</label>
								<select
									v-model="filters.typology"
									@keyup.enter="getFilteredOrders()"
									class="form-control form-control-sm"
								>
									<option selected="selected" :value="false">All Typologies</option>
									<option
										v-for="typology in typologies"
										:key="typology. code"
										:value="typology.code"
										v-text="typology.name"
									>
									</option>
								</select>
								<br>
								<label>Source:</label>
								<select
									v-model="filters.source"
									@keyup.enter="getFilteredOrders()"
									class="form-control form-control-sm"
								>
									<option selected="selected" :value="false">All Sources</option>
									<option
										v-for="source in sources"
										:key="source"
										:value="source"
										v-text="source"
									>
									</option>
								</select>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-auto">
						<label>Dates:</label>
						<input
							type="date"
							v-model="filters.date_start"
							@keyup.enter="getFilteredOrders()"
							class="form-control form-control-sm"
							placeholder="From:"
						/>
						<input
							type="date"
							v-model="filters.date_end"
							@keyup.enter="getFilteredOrders()"
							class="form-control form-control-sm"
							placeholder="To:"
						/>
						<label class="mt-2">Sort by:</label>
						<select
							v-model="filters.sort_by"
							@change="getOrders()"
							class="form-control form-control-sm"
						>
							<option
								v-for="option in sort_by_options"
								:key="option.name"
								:value="option"
								v-text="option.name"
							>
							</option>
						</select>
					</div>
					<div class="col-12 col-md-auto">
						<label>Country:</label>
						<input
							v-model="filters.country"
							@keyup.enter="getFilteredOrders()"
							type="text"
							placeholder="--"
							size="2"
							class="form-control form-control-sm"
						/>
						<label class="mt-3">Currencies:</label>
						<div class="form-check">
							<div v-for="c in currencies" :key="c">
								<input
									type="checkbox"
									class="form-check-input"
									:id="c"
									:value="c"
									v-model="filters.currency"
								/>
								<label class="form-check-label" :for="c">{{ c }}</label>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-auto">
						<label>Statuses:</label>
						<div class="dropdown mb-3">
							<div class="dropdown-toggle c-pointer btn btn-sm btn-dark" @click="statusToggle = !statusToggle">Status</div>
							<div class="dropdown-menu" :class="{'d-block':statusToggle}">
								<!-- <div class="form-check ml-2 pl-4">
									<input type="checkbox" class="form-check-input" id="s_all">
									<label class="form-check-label" for="s_all">Select/Deselect All</label>
								</div> -->
								<div class="form-check ml-2 pl-4" v-for="s in status" :key="s">
									<input
										type="checkbox" class="form-check-input"
										:id="s" :value="s"
										v-model="filters.status"
									/>
									<label class="form-check-label" :for="s">{{ s }}</label>
								</div>
								<button class="btn btn-block mt-1" @click="statusToggle = !statusToggle">Ok</button>
							</div>
						</div>
						<div class="dropdown">
							<div class="dropdown-toggle c-pointer btn btn-sm btn-dark" @click="shippingStatusToggle = !shippingStatusToggle">Shipping Status</div>
							<div class="dropdown-menu" :class="{'d-block':shippingStatusToggle}">
								<div class="form-check ml-2 pl-4" v-for="s in shipping_status" :key="s">
									<input
										type="checkbox" class="form-check-input"
										:id="s" :value="s"
										v-model="filters.shipping_status"
									/>
									<label class="form-check-label" :for="s">{{ s }}</label>
								</div>
								<button class="btn btn-block mt-1" @click="shippingStatusToggle = !shippingStatusToggle">Ok</button>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-auto">
						<label>SAP:</label>
						<div class="form-check">
							<input
							type="checkbox"
							class="form-check-input"
							id="sap_not_imported"
							v-model="filters.sap_not_imported"
							/>
							<label class="form-check-label" for="sap_not_imported">Not Imported Only</label>
						</div>
						<div class="form-check mb-2">
							<input
								type="checkbox"
								class="form-check-input"
								id="sap_not_invoiced"
								v-model="filters.sap_not_invoiced"
							/>
							<label class="form-check-label" for="sap_not_invoiced">Not Invoiced Only</label>
						</div>
						<label>Payment Status:</label>
						<div class="row ml-1">
							<div class="form-check col-12">
								<input
									type="radio"
									class="form-check-input"
									id="payment_paid_paid"
									name="payment_paid"
									:value="true"
									v-model="filters.payment_paid"
								/>
								<label for="payment_paid_paid">Paid</label>
							</div>
							<div class="form-check col-12">
								<input
									type="radio"
									class="form-check-input"
									id="payment_paid_notpaid"
									name="payment_paid"
									:value="false"
									v-model="filters.payment_paid"
								/>
								<label for="payment_paid_notpaid">Not Paid</label>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-auto d-none">
						<input
							type="checkbox"
							class="form-check-input"
							id="sort_replacements"
							@click="sortReplacementOrders()"
							:checked="filters.sortReplacements"
						/>
						<label class="form-check-label" for="sort_replacements"
							>Sort Replacements</label
						>
					</div>
					<div class="col-12 col-md-auto">
						<div class="btn-group-vertical actions">
							<button
								class="btn btn-primary btn-lg btn-block"
								@click="getFilteredOrders()"
								title="Search"
							>
								<i class="fa fa-search"></i>Search
							</button>
							<button
								class="btn btn-danger btn-sm btn-block"
								@click="
									getOrders();
									resetFilters();
								"
								title="Reset"
							>
								<i class="fa fa-times"></i>Reset
							</button>
							<download-excel
								class="btn btn-block btn-info btn-sm"
								:data="excelData"
								name="GW_Orders.xls"
								><i class="fa fa-file-excel"></i>Export</download-excel
							>
							<!-- <button
								v-if="$router.user.roleScore > 2"
								class="btn btn-block btn-light btn-sm"
								@click="exportOrders('A2A', 'A2A')"
							>
								<i class="fa fa-file-excel"></i> Export A2A
							</button>
							<button
								v-if="$router.user.roleScore > 2"
								class="btn btn-block btn-light btn-sm"
								@click="exportOrders('FebrezeFreshAir', 'FebrezeFreshAir')"
							>
								<i class="fa fa-file-excel"></i> Export Febreze
							</button> -->
						</div>
					</div>
				</div>
			</div>
			<div class="table-responsive">
				<table class="table table-striped table-bordered table-hover">
					<thead
						:class="[
							{ crowdfunding: this.filters.typology === 'crowdfunding' },
						]"
					>
						<tr>
							<th>Actions ({{ orders.length }})</th>
							<th>
								# Order
								<button
									v-if="this.$router.user.roleScore >= 2"
									class="btn btn-sm btn-square"
									:class="{ loading: isLoading }"
									:disabled="isLoading"
									@click.prevent="salesforceSyncOrders()"
								>	
									<img src="../../assets/salesforce.svg" width="30px"/>
								</button>
							</th>
							<th>
								Invoice / SAP
								<button
									v-if="this.$router.user.roleScore >= 2"
									class="btn btn-sm btn-square sap"
									:class="{ loading: isLoading }"
									:disabled="isLoading"
									@click.prevent="sapImportOrders()"
								>
									<i class="fa fa-cloud-upload-alt"></i>
								</button>
							</th>
							<th>Date</th>
							<th>Status / Shipping Status</th>
							<th>Products ({{ orders_products_count }})</th>
							<th>Billing</th>
							<th>Shipping</th>
							<th>Total ({{ orders_total }})</th>
							<th>Shipping Info</th>
							<th>Discount</th>
							<!-- <th>Shipping Method</th> -->
							<!-- <th>
								Payment Method
								<button
									class="btn btn-sm btn-square"
									@click="getPaymentsDetails()"
								>
									<i class="fa fa-hand-holding-usd"></i>
								</button>
							</th> -->
							<th>Payment Info</th>
							<th>Segment</th>
							<th>Notes</th>
							<th>Internal Notes</th>
						</tr>
					</thead>
					<tbody v-if="!loadingData && orders_count > 0">
						<SingleOrder
							v-for="order in orders"
							:key="order.id"
							:order="order"
						></SingleOrder>
					</tbody>
					<span v-else>
						<div class="loading-data" v-if="loadingData">
							<span class="loading"></span>
							<h5>Loading orders ...</h5>
						</div>
						<div class="loading-data" v-else>
							<h6>No orders found.</h6>
						</div>
						<div class="clearer"></div>
					</span>
				</table>
			</div>
			<nav aria-label="Orders navigation" v-if="orders_count > 0">
				<ul class="pagination justify-content-center" v-if="pages > 1">
					<li class="page-item" :class="page == 1 ? 'disabled' : ''">
						<a
							@click.prevent="getOrders((page -= 1))"
							class="page-link"
							href="#"
							aria-label="Previous"
							><span aria-hidden="true">&laquo;</span
							><span class="sr-only">Previous</span></a
						>
					</li>
					<li class="page-item" :class="page == 1 ? 'disabled' : ''">
						<a @click.prevent="getOrders(1)" class="page-link" href="#"
							>First</a
						>
					</li>
					<li
						v-for="page_number in pages"
						:key="page_number"
						class="page-item"
						:class="page_number == page ? 'active' : ''"
					>
						<a
							v-if="!(page_number <= page - 10 || page_number >= page + 10)"
							class="page-link"
							href="#"
							@click.prevent="getOrders(page_number)"
						>
							{{ page_number }}</a
						>
					</li>
					<li class="page-item" :class="page == pages ? 'disabled' : ''">
						<a @click.prevent="getOrders(pages)" class="page-link" href="#"
							>Last</a
						>
					</li>
					<li class="page-item" :class="page == pages ? 'disabled' : ''">
						<a
							@click.prevent="getOrders((page += 1))"
							class="page-link"
							href="#"
							aria-label="Next"
							><span aria-hidden="true">&raquo;</span
							><span class="sr-only">Next</span></a
						>
					</li>
				</ul>
			</nav>
		</div>
		<div v-else>Access denied! You can't access this page.</div>
	</section>
</template>

<script>
import OrdersService from "@/services/OrdersService";
import SingleOrder from "./SingleOrder";
import downloadExcel from "../utils/downloadExcel";
import SapService from "@/services/SapService";
import data from "@/assets/data.json";

export default {
  name: "Orders",
  data() {
    return {
      page: 1,
      pages: 0,
      orders: "",
      order_list: "",
      orders_count: "",
      orders_products_count: "",
      orders_total: "",
      imports: "",
      loadingData: false,
      isLoading: false,
	  channel_groups: data.channel_groups,
      channels: data.channels,
      typologies: data.typologies,
      status: data.status,
      shipping_status: data.shipping_status,
      sources: data.sources,
      currencies: data.currencies,
      sort_by_options: data.sort_by_options,
	  statusToggle: false,
	  shippingStatusToggle: false,
      filters: {
        channel_group: false,
        channel: false,
        country: "",
        keywordsearch: "",
        status: data.status,
		shipping_status: data.shipping_status,
        payment_paid: null,
        currency: data.currencies,
        sortReplacements: false,
        date_start: null,
        date_end: null,
        product_group: "",
        typology: false,
        source: false,
        sort_by: data.sort_by_options[0]
      },
      excelData: [],
    };
  },
  components: {
    SingleOrder,
    downloadExcel,
  },
  mounted() {
    this.page = 1;
	if (this.$route.query.channel || this.$route.query.channel_group || this.$route.query.typology || this.$route.query.keywordsearch || this.$route.query.source || this.$route.query.date_start || this.$route.query.date_end || this.$route.query.product_group || this.$route.query.country) {
		this.filters = { ...this.filters, ...this.$route.query }
		this.getFilteredOrders()
	} else {
		this.getOrders(this.page);
	}
  },
  sockets: {
    orderUpdated(updatedOrder) {
      console.log(
        "[socket.io] An order has been updated. " + updatedOrder.id_number
      );
      this.orders.filter((order, index) => {
        if (order.id_number === updatedOrder.id_number) {
          this.orders.splice(index, 1, updatedOrder);
        }
      });
    },
  },
  methods: {
    async getOrders(page) {
      this.loadingData = !this.loadingData;
      page <= 1
        ? (this.page = 1)
        : page > this.pages
        ? (this.page = this.pages)
        : (this.page = page);
      const response = await OrdersService.getOrders(page - 1, this.filters);
      this.order_list = response.data.models;
      this.orders = this.order_list;
	  this.ordersStats()
      this.pages = response.data.pages;
      this.loadingData = !this.loadingData;
      this.setExcelData(this.orders);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getFilteredOrders() {
      this.loadingData = !this.loadingData;

      const response = await OrdersService.getFilteredOrders(this.filters);
      this.order_list = response.data;
      this.orders = this.order_list;
      this.ordersStats()
      this.pages = response.data.pages;
      this.loadingData = !this.loadingData;
      this.setExcelData(this.orders);

	  const queryParamsSet = Object.fromEntries(Object.entries(this.filters).filter(([_, v]) => !!v));
	  this.$router.replace({ name: "Orders", query: queryParamsSet })
    },
	ordersStats(){
		this.orders_count = this.order_list.length;
		this.orders_products_count = 0
		this.orders_total = 0
		this.order_list?.map(o => {
		this.orders_products_count = this.orders_products_count + o.products?.reduce((a,c) => {
          return a+c.quantity
      	},0)
			this.orders_total = this.orders_total + parseFloat(o.total)
		})
	},
    resetFilters() {
      this.filters = {
        channel: false,
        channel_group: false,
        country: "",
        keywordsearch: "",
        status: data.status,
        currency: data.currencies,
        payment_paid: null,
        date_start: null,
        date_end: null,
        product_group: "",
        sortReplacement: false,
        sort_by: data.sort_by_options[0],
		source: false,
		typology: false
      };
	  this.$router.replace({ name: "Orders", query: {} })
    },
    async sortReplacementOrders() {
      this.filters.sortReplacements = !this.filters.sortReplacements;
      this.loadingData = !this.loadingData;
      if (this.filters.sortReplacements) {
        let replacementsIds = [];
        this.order_list
          .filter((o) => {
            return o.id_number.includes("_R");
          })
          .map((o) => {
            replacementsIds.push(o.id_number);
          });
        replacementsIds.forEach((idNumber) => {
          let oldIndex = this.order_list.findIndex(
            (o) => o.id_number === idNumber
          );
          let originalIdNumber = idNumber.slice(0, idNumber.indexOf("_R"));
          let newIndex = this.order_list.findIndex(
            (oo) => oo.id_number === originalIdNumber
          );
          this.orders.splice(newIndex, 0, this.orders.splice(oldIndex, 1)[0]);
        });
      } else {
        this.getOrders();
      }
      this.loadingData = !this.loadingData;
    },
	sortOrders(){
		const sortBy = this.filters.sort_by.property
		const sortByDirection = this.filters.sort_by.direction
		this.orders = this.orders.sort((a, b) => a[sortBy].toString().localeCompare(b[sortBy].toString()) * sortByDirection)
	},
    // async getPaymentsDetails() {
    //   await OrdersService.getPaymentsDetails();
    // },
    setExcelData(orders) {
      this.excelData = [];
      let data = [];
      orders.map((order) => {
        order.products.map((product, index) => {
          let productCat = "";
          product.sku.substring(0, 2) === "ET" ? (productCat = "Eteria") : "";
          product.sku.substring(0, 2) === "NT" ? (productCat = "Natede") : "";
          product.sku.substring(0, 2) === "IA" ? (productCat = "IrenAria") : "";
          product.sku.substring(0, 2) === "CY" ? (productCat = "Clairy") : "";
          product.sku.substring(0, 2) === "SH" ? (productCat = "Shelfy") : "";

          let obj = {
            channel: order.channel,
            channel_group: order.channel_group,
            id_number: order.id_number,
            month: order.date.substring(0, 7) + "/01",
            date: order.date.substring(0, 10),
            created_at: order.createdAt ? order.createdAt.substring(0, 10) : '',
            shipping_date: order.shipping_details.date
              ? order.shipping_details.date.substring(0, 10)
              : "",
            billing:
              index === 0
                ? `${order.billing.name} \n` +
                  `${order.billing.company || ""} \n` +
                  `${order.billing.address_1 || ""} \n` +
                  `${order.billing.address_2 || ""} \n` +
                  `${order.billing.city || ""} \n` +
                  `${order.billing.state || ""} \n` +
                  `${order.billing.postcode || ""} \n` +
                  `${order.billing.country || ""} \n` +
                  `${order.billing.email || ""} \n` +
                  `${order.billing.phone || ""} \n`
                : "",
			status: order.status,
			nots: index === 0 ? order.notes : "",
            shipping_status: order.shipping_status,
            currency: index === 0 ? order.currency : "",
            total: index === 0 ? order.total : "",
            total_tax: index === 0 ? order.total_tax : "",
            shipping_total: index === 0 ? order.shipping_total : "",
            shipping_tax: index === 0 ? order.shipping_tax : "",
            shipping_rate: index === 0 ? order.shipping_details.rate : "",
            discount_total: index === 0 ? order.discount_total : "",
            discount_percentage:
              index === 0 ? order.discount_percentage + "%" : "",
            payment_method: index === 0 ? order.payment?.payment_method : "",
            payment_currency: index === 0 ? order.payment?.details?.currency : "",
            fee: index === 0 ? order.payment?.details.fee : 0,
            receivable_amount:
			index === 0 ? order.payment?.details.receivable_amount : 0,
            exchange_rate:
			index === 0 ? order.payment?.details.exchange_rate : 0,
            payout_date: index === 0 ? order.payment?.details?.payout_date?.substring(0, 10) : 0,
            shipping_courier: order.shipping_details.courier,
            tracking_numbers: order.shipments?.map(s => s.tracking_number).join(','),
            country: index === 0 ? order.shipping.country : "",
            product: product.sku_code,
            plug: product.sku_plug,
            quantity: product.quantity,
            product_total: product.total,
            product_total_tax: product.total_tax,
            product_subtotal: product.subtotal,
            product_subtotal_tax: product.subtotal_tax,
            product_category: productCat,
            iren_ref: index === 0 ? product.ref : "",
            city: order.shipping.city,
			state: order.shipping.state,
            country: order.shipping.country,
            postcode: order.shipping.postcode,
          };
          data.push(obj);
        });
      });
      this.excelData = data;
    },
    async sapImportOrders() {
		this.isLoading = true
		const response = await SapService.sapImportOrders()
		this.isLoading = false
    },
    async salesforceSyncOrders() {
		if (confirm("Confermando carichi i nuovi ordini su Salesforce.\nPrima di procedere assicurati che non ci sia un'altra operazione in corso.\nVuoi procedere?")) {
			this.isLoading = true
			const response = await OrdersService.salesforceSyncOrders()
			this.isLoading = false
		}
    },
    // async sapOldImportOrders() {
	// 	this.isLoading = true
	// 	await SapService.sapOldImportOrders()
	// 	this.isLoading = false
    // },
    async exportOrders(source, template) {
      const csv = await OrdersService.exportOrders({ source, template })
      window.open(
        "data:text/csv;charset=utf-8," + encodeURI(csv.data),
        "orders-export.csv"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
table {
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
  thead {
    color: #fff;
    tr {
      background-color: $gray02 !important;
      //background-color: rgba($gray02, .3)!important;
      th {
        border: 1px solid !important;
        border-color: $gray03 !important;
        white-space: nowrap;
        font-weight: 300;
        padding: 0.75rem;
        position: relative;
        .btn {
          position: absolute;
          right: 1rem;
          top: 0.4rem;
          background-color: $gray03;
          color: #fff;
        }
      }
    }
  }
  td,
  th {
    vertical-align: middle !important;
    span {
      display: block;
    }
  }
}
tbody {
  tr {
    background-color: #fff;
    &:nth-of-type(odd) {
      background-color: #f9f9f9 !important;
    }
    &:hover {
      background-color: #f4f4f4 !important;
    }
  }
}
.filters {
  margin: 0;
  padding: 1rem !important;
  color: #fff;
  background-color: $gray02;
  //background-color: rgba($gray02, .3);
  //border: 1px solid $gray03;
  border-bottom: 0;
  max-width: 100%;
  label {
    font-weight: 700;
  }
  input,
  button,
  select,
  option,
  label {
    font-size: 0.8rem;
    @include mobile {
      margin-top: 0.5rem;
    }
  }
  .form-check {
    max-width: 200px;
    &-label {
      font-weight: 400;
    }
  }
  .col-auto {
    border-right: 1px solid rgba($gray01, 0.4);
    &:last-of-type {
      border-right: none;
    }
  }
}
.loading-data {
  position: absolute;
  padding: 2rem;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  display: block;
  h5 {
    margin-top: 2.5rem;
  }
}
.clearer {
  display: block;
  padding: 4rem;
}
.pagination {
  flex-wrap: wrap;
}
.crowdfunding > tr > th {
  background-color: $kickstarter_color;
  color: #fff;
  font-weight: 700;
  border-color: #28a745 !important;
}
</style>
