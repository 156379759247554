import Api from "@/services/Api";

export default {
  getUsers() {
    return Api().get("/api/users/");
  },
  addUser(params) {
    return Api().post("/api/users/", params);
  },
  getUser(email) {
    return Api().get("/api/users/" + email);
  },
  deleteUser(email) {
    return Api().delete("/api/users/" + email);
  },
  updateUser(email, params) {
    return Api().put("/api/users/" + email, params);
  },
};
