<template>
  <nav class="navbar navbar-expand-xl navbar-dark bg-dark position-sticky">
    <a class="navbar-brand" href="https://gw.vitesy.com/">
      <img
        src="../assets/vitesy_gw_logo.png"
        height="30"
        class="d-inline-block align-top mr-1"
        alt="Vitesy"
      />
      <div class="d-inline-block mt-1"><strong>GATEWAY<sub>4.0</sub></strong></div>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul v-if="this.$router.user" class="navbar-nav mx-auto">
        <router-link
          v-for="route in nav"
          :key="route.id"
          :to="route.page"
          tag="li"
          class="nav-item"
        >
          <a
            v-if="
              $router.user.roleScore >= route.requiredRoleScore &&
              $router.user.roleScore !== route.deniedRoleScore
            "
            class="nav-link"
          >
            <i :class="route.icon"></i> {{ route.text }}</a
          >
        </router-link>
      </ul>
      <ul class="user_info navbar-nav ml-auto">
        <li v-if="user" class="nav-item">
          <a class="nav-link" href="/users"
            ><i class="fa fa-user-cog"></i>
            <!-- {{ user.name + " | " + user.role }} -->
            {{ user.name }}
          </a>
        </li>
        <li class="nav-item" v-if="user">
          <a class="nav-link" href="/login" @click.prevent="userLogout"
            ><strong>Logout</strong><i class="fa fa-sign-out-alt"></i
          ></a>
        </li>
        <li class="nav-item" v-if="!user">
          <a class="nav-link" href="/login">Login</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import AuthService from "@/services/AuthService";
export default {
  name: "Header",
  data() {
    return {
      nav: [
        /* {
          id: 0,
          text: 'Dashboard',
          page: '/',
          icon: 'fa fa-tachometer-alt'
        }, */
        {
          id: 1,
          text: "Orders",
          page: "/orders",
          icon: "fa fa-th-list",
          requiredRoleScore: 0,
        },
        {
          id: 3,
          text: "Shipping",
          page: "/shipping",
          icon: "fa fa-truck",
          requiredRoleScore: 2,
        },
        {
          id: 2,
          text: "Production",
          page: "/production",
          icon: "fa fa-industry",
          requiredRoleScore: -1,
          deniedRoleScore: 0,
        },
        {
          id: 6,
          text: "Inbound Orders",
          page: "/inbound-orders",
          icon: "fa fa-dolly",
          requiredRoleScore: -1,
        },
        {
          id: 5,
          text: "Imports",
          page: "/imports",
          icon: "fa fa-file-import",
          requiredRoleScore: 1,
        },
        // {
        //   id: 6,
        //   text: "Products",
        //   page: "/products",
        //   icon: "fa fa-shopping-cart",
        //   requiredRoleScore: 0,
        // },
        {
          id: 7,
          text: "Lead",
          page: "/leads/new",
          icon: "fa fa-user-plus",
          requiredRoleScore: 0,
        },
        // {
        //   id: 7,
        //   text: "",
        //   page: "/devices",
        //   icon: "fa fa-mobile-alt",
        //   requiredRoleScore: 1,
        // },
        /* {
          id: 7,
          text: 'Users',
          page: '/users',
          icon: 'fa fa-users-cog'
        } */
      ],
      user: false,
    };
  },
  watch: {
    $route() {
      this.user = this.$router.user;
    },
  },
  methods: {
    async userLogout() {
      await AuthService.userLogout();
      this.$router.user = false;
      this.user = false;
      this.$socket.disconnect();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style lang="scss">
@import "../styles/variables.scss";
.navbar-brand {
  position: relative;
  letter-spacing: 0.2rem;
  color: #eee !important;
  // color: $blue!important;
  font-size: 1rem;
  @include tablet {
    letter-spacing: 0.2rem;
    font-size: 1rem;
    img {
      max-width: 120px;
    }
  }
  @include mobile {
    font-size: 1rem;
    line-height: 2rem;
    img {
      max-width: 120px;
    }
  }
}
.navbar {
  padding: 0 2rem;
  top: 0;
  z-index: 3;
  @include tablet {
    padding: 1rem;
  }
}
.navbar-nav {
  .nav-item {
    font-size: 0.8rem;
    border-top: 4px solid $gray04;
    a.nav-link {
      padding: 1rem 1.25rem;
      @include mobile {
        padding: 1rem 0;
        font-size: 1rem;
      }
    }
    &:focus,
    &.router-link-exact-active,
    &.router-link-active {
      border-bottom: 4px solid $vitesy;
      a.nav-link {
        color: $vitesy;
        i {
          opacity: 1;
        }
      }
    }
    a.nav-link {
      color: #eee;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      &:hover {
        color: #fff;
        i {
          opacity: 1;
        }
      }
      i {
        font-size: 1rem;
        opacity: 0.2;
        vertical-align: middle;
        margin: 0 0.6rem;
      }
    }
  }
}

.user_info a.nav-link {
  letter-spacing: 0;
  font-size: 0.8rem;
}
</style>
