<template>
  <div class="auth">
    <form class="form" v-on:submit.prevent="userLogin">
      <h2>Sign In</h2>
      <div>
        <label class="sr-only" for="email">Email</label>
        <input
          id="email"
          class="form-control"
          type="email"
          name="email"
          placeholder="Email"
          required
          autofocus="true"
          v-model="email"
        />
      </div>
      <div>
        <label class="sr-only" for="password">Password</label>
        <input
          id="password"
          class="form-control"
          type="password"
          name="password"
          placeholder="Password"
          required
          v-model="password"
        />
      </div>
      <div>
        <button type="submit" class="btn btn-lg btn-primary btn-block">
          Log in
        </button>
      </div>
      <br />
      <div class="alert alert-success" v-show="alertSuccess" role="alert">
        <strong>Ben fatto!</strong> Hai effettuato l'accesso.
      </div>
      <div class="alert alert-danger" v-show="alertError" role="alert">
        <strong>Ops!</strong> Email e/o password errate.
      </div>
    </form>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      alertSuccess: false,
      alertError: false,
    };
  },
  mounted() {
    if (
      location.protocol !== "https:" &&
      !window.location.href.includes("localhost")
    ) {
      location.href =
        "https:" +
        window.location.href.substring(window.location.protocol.length);
    }
  },
  methods: {
    async userLogin() {
      this.alertSuccess = false;
      this.alertError = false;
      this.isLoading = !this.isLoading;
      await AuthService.userLogin({
        email: this.email,
        password: this.password,
      })
        .then((response) => {
          this.alertSuccess = true;
          this.alertError = false;
          /* this.$router.user = {
            name: response.data.name,
            email: response.data.email,
            role: response.data.role,
            roleScore: response.data.roleScore
          } */
          if (response.data.role === "production") {
            this.$router.push({ name: "Production" });
          } else {
            this.$router.push({ name: "Orders" });
          }
        })
        .catch((err) => {
          console.log(err);
          this.alertError = true;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/variables.scss";
.form {
  background-color: #fff;
  max-width: 600px;
  width: 100%;
  padding: 4rem;
  margin: 0 auto;
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
  @include mobile {
    padding: 2rem;
  }
  h2,
  .checkbox {
    margin-bottom: 1rem;
  }
  .checkbox {
    font-weight: normal;
  }
  .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0.8rem 1rem;
    margin-bottom: 1rem;
    display: block;
    &:focus {
      z-index: 2;
    }
  }
}
</style>
