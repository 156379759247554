import Api from "@/services/Api";

export default {
  userLogin(params) {
    return Api().post("/api/auth/login/", params);
  },
  userLogout() {
    return Api().get("/api/auth/logout/");
  },
  userInfo() {
    return Api().get("/api/auth/me/");
  },
};
