<template>
  <div id="app">
    <Header v-if="!isBlankLayoutRoute"></Header>
    <div class="container-fluid">
      <router-view />
    </div>
    <Footer v-if="!isBlankLayoutRoute"></Footer>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
  name: "App",
  components: {
    Header: Header,
    Footer: Footer,
  },
  data() {
    return {
      blank_layout_routes: ['/leads/new']
    }
  },
  computed: {
    isBlankLayoutRoute(){
      return this.blank_layout_routes.includes(this.$route.path)
    }
  }
};
</script>

<style lang="scss">
@import "styles/variables.scss";
body {
  background-color: #f2f2f2;
}
#app {
  font-family: "Work Sans", sans-serif;
  color: #2a2a2a;
}

.card {
  border-radius: 0;
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2rem;
  @include mobile {
    margin-top: 0.5rem;
  }
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.5rem;
}

.container-fluid {
  padding: 2rem;
  font-size: 0.8rem;
  min-height: calc(100vh - 172px);
  @include tablet {
    padding: 1rem;
  }
}

pre.code {
  margin-top: 0.5rem;
  background-color: #000;
  color: #fff;
  padding: 1rem;
  border-radius: 0.2rem;
}

.loading {
  position: relative;
  color: rgba(0, 0, 0, 0) !important;
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -14px;
    top: 0;
    border: 4px solid $lightblue;
    border-top: 4px solid $clairy;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
