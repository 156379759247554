<template>
  <section class="imports">
    <div class="row" v-if="$router.user.roleScore > 1">
      <h2 class="col-12 mb-4">Imports</h2>
      <div class="col-12 col-md-6">
        <div class="card-deck">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">File import</h3>
              <div class="card-text">
                <form
                  v-on:submit.prevent="fileImport"
                  enctype="multipart/form-data"
                >
                  <label for="import_template">Template</label>
                  <select class="form-control" v-model="import_template" required>
                    <option disabled :value="false" selected>Select import template</option>
                    <option v-for="(t, index) in templates" :key="index" :value="t">{{ t }}</option>
                  </select>
                  <div class="dragndrop">
                    <input
                      type="file"
                      accept=".xls,.xlsx,.csv"
                      id="file"
                      name="file"
                      class="form-control"
                      ref="import_file"
                      @change="handleFileUpload()"
                    />
                    <label for="file" v-html="fileLabel"></label>
                  </div>
                  <button
                    class="btn btn-outline-primary btn-md btn-block"
                    type="submit"
                    :class="{ loading: isLoading }"
                    :disabled="isDisabled"
                  >
                    Upload
                  </button>
                </form>
                <div
                  v-if="fileImportResponse"
                  class="alert alert-info"
                  role="alert"
                >
                  {{ fileImportResponse }}
                </div>
              </div>
            </div>
            <div class="card-footer" v-if="importsCount >= 0">
              <small class="text-muted">Accepted formats: .xls, .xlsx</small>
            </div>
          </div>
        </div>
        <br />
        <div class="col-12 no-padding">
          <h3>Channels</h3>
          <div class="card-deck">
            <!-- <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  <span class="circle circle--iren"></span>Iren Orders
                </h5>
                <div class="card-text">
                  <button
                    class="btn btn-outline-primary btn-sm"
                    @click.prevent="irenImport"
                    :class="{ loading: isLoading }"
                  >
                    <i class="fa fa-cloud-download-alt"></i> Start Import
                  </button>
                  <div
                    v-if="irenImportResponse"
                    class="alert alert-info"
                    role="alert"
                  >
                    {{ irenImportResponse }}
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <small class="text-muted"
                  >Last import on
                  {{
                    imports.find((i) => {
                      return i.channel == "Iren";
                    }).date | formatDate
                  }}</small
                >
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 row no-gutters">
        <div class="col-12" v-if="importsCount > 0">
          <h3>Last imports</h3>
          <div class="card">
            <template v-if="imports && imports.length > 0">
              <ul
                v-for="(imp, index) in imports"
                :key="imp.id"
                class="list-group list-group-flush"
              >
                <li v-if="index <= listLimit" class="list-group-item">
                  <b>{{ imp.orders_count }} orders</b> imported on
                  <b>{{ imp.date | formatDate }}</b> from
                  <i>{{ imp.channel_group }}</i>
                  <i>{{ imp.channel }}</i>
                </li>
              </ul>
            </template>
            <button
              class="btn btn-sm btn-primary"
              @click.prevent="listLimit += 10"
            >
              Show more
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>Access denied! You can't access this page.</div>
  </section>
</template>

<script>
import OrdersService from "@/services/OrdersService";
export default {
  name: "Imports",
  data() {
    return {
      imports: "",
      ecommerceImportResponse: "",
      importsCount: 0,
      irenImportResponse: "",
      fileImportResponse: "",
      isLoading: false,
      isDisabled: true,
      templates: ['Default', 'Amazon', 'Indiegogo', 'A2A','Seriali Ordini HIVE'],
      import_template: false,
      import_file: "",
      listLimit: 10,
      fileLabel: "<strong>Choose a file</strong><span> or drag it here</span>.",
    };
  },
  mounted() {
    this.getImports();
  },
  methods: {
    async getImports() {
      const response = await OrdersService.getImports();
      this.imports = response.data;
      this.importsCount = this.imports.length;
    },
    async irenImport() {
      this.isLoading = !this.isLoading;
      const response = await OrdersService.irenImport();
      this.isLoading = !this.isLoading;
      this.irenImportResponse = response.data;
    },
    handleFileUpload() {
      this.import_file = this.$refs.import_file.files[0];
      this.fileLabel = '<i>"' + this.import_file.name + '"</i>';
      if (this.import_file) {
        this.isDisabled = false;
      }
    },
    async fileImport() {
      if (!this.import_template){
        alert('Select an import template!')
      } else {
        this.isLoading = !this.isLoading;
        this.isDisabled = !this.isDisabled;
        const formData = new FormData();
        formData.append("template", this.import_template);
        formData.append("file", this.import_file);
        const response = await OrdersService.fileImport(formData);
        this.fileImportResponse = response.data;
        this.isLoading = !this.isLoading;
        this.isDisabled = !this.isDisabled;
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/variables.scss";
.alert {
  margin-top: 1rem;
}
.dragndrop {
  position: relative;
  background-color: #e0e8f2;
  background-image: url(../../assets/x-file-wa.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px;
  width: 100%;
  height: 300px;
  border-radius: 0;
  border: 2px dashed #1b2d66;
  padding: 2rem;
  margin: 2rem auto;
  &:hover {
    background-color: #86bef1;
  }
  #file {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }
  label {
    text-align: center;
    color: #1b2d66;
    font-size: 1.4rem;
    line-height: 2rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    height: 2rem;
    margin: auto;
  }
}
.circle {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  background: $gray01;
  margin: 0 0.5rem -0.25rem 0;
  &--ecommerce {
    background-color: $ecommerce_color;
  }
  &--iren {
    background-color: $iren_color;
  }
  &--salesforce {
    background-color: $salesforce_color;
  }
}
</style>
