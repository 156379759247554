<template>
  <div class="card">
    <div class="card-header" :id="`heading_${deviceId}`">
      <div
        class="collapsed device"
        data-toggle="collapse"
        :data-target="`#${deviceId}`"
        aria-expanded="false"
        :aria-controls="`collapse_${deviceId}`"
      >
        <div class="inline">
          <div class="status" :class="{ true: device.connected }"></div>
          <div v-if="deviceUser">
            <code>{{ deviceUser }}</code>
          </div>
          <div v-if="device.order">
            <template v-if="device.order.user"
              >{{ device.order.user.name }} |
              <strong>{{ device.order.user.email }}</strong></template
            >
          </div>
        </div>
        <div>
          <strong>Device Name</strong>: {{ device.deviceName }} |
          <strong>MAC</strong>: {{ deviceId }}
        </div>
      </div>
    </div>
    <div
      :id="`${deviceId}`"
      class="collapse"
      :aria-labelledby="`heading_${deviceId}`"
      data-parent="#devicesAccordion"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-3">
            <p><strong>DEVICE</strong></p>
            <p>ID: {{ device.entityId }}</p>
            <p>Firmware: {{ device.fwVersion }}</p>
            <p>
              Name: <i>{{ device.deviceName }}</i>
            </p>
            <p>Wi-Fi: {{ device.ssid }}</p>
            <p>Goal: {{ device.goalId }}</p>
            <p>Place: {{ device.placeId }}</p>
            <p>Room: {{ device.roomId }}</p>
            <p>
              Location:<br />
              <a v-if="device.location"
                target="_blank"
                :href="
                  'https://www.google.it/maps/place/' +
                  device.location.formattedAddress
                "
                >{{ device.location.formattedAddress }}</a
              >
            </p>
            <hr />
            <p>
              Connected:
              <span class="bool" :class="{ true: device.connected }">{{
                device.connected
              }}</span>
            </p>
            <p>
              Enroll Succeeded:
              <span class="bool" :class="{ true: device.isEnrollSucceeded }">{{
                device.isEnrollSucceeded
              }}</span>
            </p>
            <p>
              Enroll Completed:
              <span class="bool" :class="{ true: device.isEnrollCompleted }">{{
                device.isEnrollCompleted
              }}</span>
            </p>
            <hr />
            <p>Created at: {{ device.createdAt | formatDate }}</p>
            <p>Updated at: {{ device.updatedAt | formatDate }}</p>
          </div>
          <div class="col-12 col-md-3">
            <p><strong>MEASUREMENTS</strong></p>
            <p v-for="(m, key) in deviceMeasurements" :key="m">
              <template v-if="key !== 'deviceId'">
                <span class="text-capitalize">{{ key }}</span
                >: {{ m }}
              </template>
            </p>
            <hr />
            <p><strong>ACCOUNT</strong></p>
            <p>First Name: {{ device.firstName }}</p>
            <p>Last Name: {{ device.lastName }}</p>
            <p>Email: {{ device.email }}</p>
            <p>Language: {{ device.language }}</p>
            <p>Policies: {{ device.policies }}</p>
            <hr />
            <p>Created at: {{ device.createdAt | formatDate }}</p>
            <p>Updated at: {{ device.updatedAt | formatDate }}</p>
          </div>
          <template v-if="device.order">
            <div class="col-12 col-md-3">
              <p><strong>PRODUCT</strong></p>
              <template v-if="device.order.product">
                <p>SKU: {{ device.order.product.sku }}</p>
                <p>Product Serial: {{ device.order.product.product }}</p>
                <p>TechUnit Serial: {{ device.order.product.techunit }}</p>
                <p>MAC Address: {{ device.order.product.mac }}</p>
              </template>
              <hr />
              <p><strong>PRODUCTION</strong></p>
              <p>
                Order: #<a
                  :href="'/production/' + device.order.production.id_number"
                  >{{ device.order.production.id_number }}</a
                >
              </p>
              <p>Date: {{ device.order.production.date | formatDate }}</p>
              <p>
                Start Production Date:
                {{ device.order.production.start_production_date | formatDate }}
              </p>
              <p>
                End Production Date:
                {{ device.order.production.end_production_date | formatDate }}
              </p>
            </div>
            <div class="col-12 col-md-3">
              <p><strong>ORDER</strong></p>
              <p>Order: #{{ device.order.id_number }}</p>
              <p>Invoice: {{ device.order.invoice_number }}</p>
              <hr />
              <template v-if="device.order.user">
                <p><b>User:</b></p>
                <p>Name: {{ device.order.user.name }}</p>
                <p>
                  Address: {{ device.order.user.address_1 }},
                  {{ device.order.user.postcode }} {{ device.order.user.city }}
                  {{ device.order.user.state }}, {{ device.order.user.country }}
                </p>
                <p>
                  Email:
                  <a
                    :href="`mailto:${device.order.user.email}`"
                    target="_blank"
                    >{{ device.order.user.email }}</a
                  >
                </p>
                <p>Phone: {{ device.order.user.phone }}</p>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SingleDevice",
  props: ["device"],
  data() {
    return {
      deviceMeasurements: [],
      deviceUser: false,
    };
  },
  computed: {
    deviceId: function () {
      return this.device.entityId.replace(/[^\w\s]/gi, "");
    },
  },
  mounted() {
    if (!this.device.order || !this.device.order.id_number) {
      this.getDeviceUser(this.device.deviceId);
    }
  },
  methods: {
    async getDeviceUser() {
      let url =
        "https://api.clairyhub.com/gateway/users?device=" +
        this.device.entityId;
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "x-access-token": "4Y.ae7JJNwe2FagT(keiLpnxj3t@Nm+R(Mdj",
        },
      });
      let data = await response.json();
      this.deviceUser = {
        Name: data[0].firstName + " " + data[0].lastName,
        Email: data[0].email,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";

code {
  background-color: $red;
  color: #ffffff;
  padding: 1rem 1.5rem;
}
p {
  margin-bottom: 0.25rem;
}
strong {
  margin-top: 1rem;
}
a {
  color: $blue;
}
.col-12 > p > strong {
  border-bottom: 2px solid $clairy;
}
.status {
  width: 0.75rem;
  height: 0.75rem;
  background-color: $red;
  border-radius: 100%;
  margin-right: 1rem;
  display: inline-block;
  &.true {
    background-color: $green;
  }
}
.bool {
  color: $red;
  &.true {
    color: $green;
  }
}
.card-header {
  padding: 0;
}
.inline div {
  display: inline-block;
}
.device {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  &:hover {
    background-color: $lightblue;
  }
}
</style>
