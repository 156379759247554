<template>
  <div v-if="s3Key.length">
    <img
      v-if="isImage"
      class="img-fluid"
      :src="signedUrl"
      alt="Shipping Label"
    />
    <img
      v-else-if="isZPL"
      class="img-fluid"
      :src="zpl"
      alt="Shipping Label ZPL"
      @click="localPrint(zpl)"
    />
    <iframe
      v-else-if="isPDF"
      width="100%"
      height="100%"
      :src="signedUrl"
      style="min-height: 500px;"
    ></iframe>
    <iframe
      v-else-if="isHTML"
      width="100%"
      height="100%"
      :src="signedUrl"
      style="min-height: 500px;"
    ></iframe>
    <template v-else>{{ s3key + signedUrl }}</template>
  </div>
</template>
<script>
import ShippingService from "@/services/ShippingService";
export default {
  name: "S3File",
  props: ["s3Key"],
  data() {
    return {
      signedUrl: "",
      zpl: "",
    };
  },
  computed: {
    isImage() {
      return (
        this.s3Key.indexOf("png") > -1 ||
        this.s3Key.indexOf("gif") > -1 ||
        this.s3Key.indexOf("jpg") > -1
      );
    },
    isZPL() {
      return this.s3Key.indexOf("zpl") > -1 || this.s3Key.indexOf("epl") > -1;
    },
    isPDF() {
      return this.s3Key.indexOf("pdf") > -1;
    },
    isHTML() {
      return this.s3Key.indexOf("html") > -1;
    },
  },
  mounted() {
    this.getFileUrl();
  },
  methods: {
    async getFileUrl() {
      if (this.isZPL) {
        let pngBase64 = await ShippingService.showZPL(this.s3Key);
        this.zpl = "data:image/png;base64," + pngBase64.data;
      }
      const response = await ShippingService.getFileUrl(this.s3Key);
      this.signedUrl = response.data
    },
    // printZplFile(url) {
    //   var xhr = new XMLHttpRequest();
    //   const _self = this
    //   xhr.open('GET', url, true);
    //   xhr.responseType = 'text';
    //   xhr.onload = function () {
    //     if (xhr.status === 200) {
    //       _self.sendToPrinter(xhr.responseText);
    //     }
    //   };
    //   xhr.send();
    // },
    // sendToPrinter(zplContent) {
    //   var iframe = document.createElement('iframe');
    //   iframe.style.display = 'none';
    //   iframe.srcdoc = zplContent;
    //   document.body.appendChild(iframe);
    //   iframe.contentWindow.print();
    // },
    localPrint(url) {
      var win = window.open('');
      win.document.write('<img width="340px" src="' + url + '" onload="window.print();window.close()" />');
      win.focus();
    }
  },
};
</script>
