var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.new_shipping.error)?_c('div',{staticClass:"bg-danger text-light p-2 d-inline-block mb-4",domProps:{"textContent":_vm._s(_vm.new_shipping.error)}}):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"loading"}):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('table',[_c('tr',[_vm._m(0),_c('td',[_c('a',{attrs:{"href":`https://portal.hive.app/orders/${_vm.new_shipping.logistic_partner_id}`,"target":"_blank"}},[_vm._v(_vm._s(_vm.new_shipping.logistic_partner_id))])])]),_c('tr',[_vm._m(1),_c('td',[_vm._v(_vm._s(_vm.new_shipping.shipping_details.rate)+"€")])]),_c('tr',[_vm._m(2),_c('td',_vm._l((_vm.new_shipping.shipping_details.tracking_numbers),function(tracking_number){return _c('div',{key:tracking_number,domProps:{"textContent":_vm._s(tracking_number)}})}),0)])]),(_vm.new_shipping.shipping_details.labels)?[_c('printFiles',{attrs:{"s3Keys":_vm.new_shipping.shipping_details.labels,"buttonText":"Print Labels","buttonClass":"ups my-4"}}),_c('div',{staticClass:"shipping__labels col-12 row"},_vm._l((_vm.new_shipping.shipping_details.labels),function(label){return _c('div',{key:label,class:[
              {
                'col-12': _vm.new_shipping.shipping_details.courier === 'dhl',
                'shipping__label col-6':
                  _vm.new_shipping.shipping_details.courier !== 'dhl',
              },
            ]},[_c('S3File',{attrs:{"s3Key":label}})],1)}),0)]:_vm._e()],2),(_vm.new_shipping.shipping_details.paperless)?_c('div',{staticClass:"col-12 col-md-6"},[_c('S3File',{attrs:{"s3Key":_vm.new_shipping.shipping_details.paperless}})],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Hive ID:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Rate:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Tracking Numbers:")])])
}]

export { render, staticRenderFns }