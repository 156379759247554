<template>
<div id="printableArea">
  <div class="row">
    <div class="col-12 col-md-6">
      <img src="/vitesy_logo.png" height="50px" width="auto" alt="Vitesy Logo"/>
    </div>
    <div class="col-12 col-md-6 text-right">
      <small>
        <strong>Laboratori Fabrici Srl</strong><br>
        Piazzetta Ado Furlan, 4 - 33170 Pordenone (PN)<br>
        Cap. Soc.€ 4.004,60 - Cod.Fiscale e P.IVA IT01786900934<br>
        Iscr. Reg. Imprese PN 01786900934 - REA PN 104531<br>
        www.vitesy.com - info@labfabrici.com - +39 320 054 2762
      </small>
    </div>
  </div>
  <div class="col-12 row px-0">
    <div class="col-8">
      <h3>Certificate of Conformity #{{ id_number }}</h3>
    </div>
    <div class="col-4">
      <button class="btn ups noPrint ml-4" @click="print('printableArea')"><i class="fa fa-print"></i> Print</button>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 col-lg-5">
      <table class="table table-responsive table-narrow">
        <thead class="thead-light">
          <tr><th colspan="2">Product Information</th></tr>
        </thead>
        <tbody>
          <tr>
            <td><b>Date:</b></td>
            <td>{{ order.date | formatDate }}</td>
          </tr>
          <tr v-for="(value, key, index) in order.product" :key="index" :class="key">
            <td class="text-capitalize text-nowrap"><b>{{ key | label }}</b></td>
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 col-lg-7">
      <table class="table table-responsive table-narrow">
        <thead class="thead-light">
          <tr>
            <th>#</th>
            <th>Seriale</th>
            <th v-if="!isShelfy">Test rigidità elettrica</th>
            <th>Installazione FW</th>
            <th>Collaudo funzionale</th>
            <th>TAMU</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(s, index) in order.serials" :key="index">
            <td>
              {{ index + 1 }}
            </td>
            <td>
              <span>{{ s.product }}</span><br>
              <a v-if="isShelfy" class="noPrint" target="_blank" :href="`/production-test/SLF0-${s.mac}`">{{ `SLF0-${s.mac}` }}</a>
            </td>
            <td v-if="!isShelfy">
              <TestTag status="pass"/>
              <a class="noPrint" target="_blank" :href="`/production-test/${s.power_supply}`">{{ s.power_supply }}</a>
            </td>
            <td>
              <TestTag status="pass"/>
            </td>
            <td>
              <TestTag status="pass"/>
            </td>
            <td>
              <TestTag status="pass"/>
            </td>
            <td>{{ s.timestamp | formatTimestamp}}</td>
          </tr>
          <tr v-for="(s, index) in order.nc_products" :key="index">
            <td>
              {{ order.serials.length + index + 1}}
            </td>
            <td>
              <span>{{ s.product }}</span><br>
              <a v-if="isShelfy" class="noPrint" target="_blank" :href="`/production-test/SLF0-${s.mac}`">{{ `SLF0-${s.mac}` }}</a>
            </td>
            <td v-if="!isShelfy">
              <TestTag :status="s.reason.index === 1 ? 'fail' : 'pass'"/>
            </td>
            <td>
              <TestTag :status="s.reason.index === 2 ? 'fail' : s.reason.index < 2 ? '-' : 'pass'"/>
            </td>
            <td>
              <TestTag :status="s.reason.index === 3 ? 'fail' : s.reason.index < 3 ? '-' : 'pass'"/>
            </td>
            <td>
              <TestTag :status="s.reason.index === 4 ? 'fail' : s.reason.index < 4 ? '-' : 'pass'"/>
            </td>
            <td>{{ s.timestamp | formatTimestamp}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>
<script>
import ProductionService from "@/services/ProductionService";
import TestTag from "./TestTag";
export default {
  name: "ProductionOrderCertificate",
  data() {
    return {
      isLoading: false,
      id_number: this.$route.params.id_number,
      order: false
    };
  },
  components: { TestTag },
  computed: {
    product() {
      return this.order.product.commercial_code
    },
    isShelfy(){
      return this.product === 'SHL0'
    }
  },
  async mounted() {
    await this.getProductionOrder(this.id_number)
  },
  methods: {
    async getProductionOrder(id) {
      await ProductionService.getProductionOrder(id).then((response) => {
        this.order = response.data
      })
    },
    print(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }
  }
};
</script>
<style lang="scss">
@import "../../styles/variables.scss";
@media print {
  body {
    background: white!important;
  }
  .noPrint{
    display: none;
  }
}
table.table-narrow {
  min-width: 100%;
  th, td {
    padding: 0.2rem .5rem!important;
    vertical-align: middle;
  }
  thead th {
    color: #495057!important;
    background-color: #e9ecef!important;
    border-color: #dee2e6!important;
  }
}
</style>
