<template lang="pug">
tbody.mb-4
  tr.order_row
    td.c-pointer(@click="collapsed = !collapsed")
      i.fa.fa-minus(v-if="collapsed")
      i.fa.fa-plus(v-else)
    td.c-pointer(@click="collapsed = !collapsed")
      h6.mb-0.font-weight-bold {{ order.supplier.name }}
    td
      span.text-monospace {{ order.supplier.code }}
    td
      .font-weight-bold {{ order.id_number }}
    td
      select(v-model="order.status" class="mb-2 w-100" :class="order.status" title="Status" :disabled="isOperator" @change="updateOrder")
        option(v-for="status in statuses" :key="status" selected="selected" v-bind:value="status") {{ status | status }}
    td {{ order.deliveries.filter(d => d.status === 'delivered').length }}/{{ order.deliveries.length }}
    td 
    td 
    td 
    td 
  template(v-if="collapsed")
    tr.items_head_row
      td #
      td Prodotti
      td P/N
      td Ubicazione
      td Stato consegna
      td Quantità ricevuta
      td Data consegna
      td DDT
      td(colspan="2") Azioni
    template(v-for="(delivery, index) in order.deliveries")
      tr.items_row.items_row--products(v-for="(item, i) in delivery.items" :key="order.id_number+index+item.id" :class="{ 'items_row--odd' : index % 2 != 0 }")
        td 
          span {{ index+1 + '.' + (i+1) }} 
          template(v-if="!isOperator")
            br
            code D {{ delivery.id }}
            br
            code I {{ item.id }}
        td 
          div
            .font-weight-bold {{ item.sku }}
            small {{ item.name }}
        td
          small.text-monospace {{ item.part_number }}
        td 
          span {{ item.location }} 
          span(v-if="item.warehouse") ({{ item.warehouse }})
        td(v-if="i === 0" :rowspan="delivery.items.length") 
          select(v-model="delivery.status" class="mb-2 w-100" :class="delivery.status" title="Status" :disabled="isOperator || delivery.status === 'registered' || !delivery.packages[0].count" @change="updateOrder")
            option(v-for="status in delivery_statuses" :key="status" selected="selected" v-bind:value="status") {{ status | status }}
          .d-inline-flex
            input(v-model="delivery.packages[0].count" type="number" placeholder="0" :readonly="isOperator" @change="updateOrder") 
            select.border(v-model="delivery.packages[0].type" :readonly="isOperator" @change="updateOrder")
              option(value="pallet") pallet
              option(value="box") box
        td.text-center
          .font-weight-bold {{ item.quantity_received }}/{{ item.quantity }}
          .text-danger.font-weight-bold {{ deliveriesCheck[index].items[i] }}
        td(v-if="i === 0" :rowspan="delivery.items.length")
          .font-weight-bold 
            i.mr-2.fa.fa-hourglass-half.text-muted
            span {{ delivery.shipping_date | date }}
          div
            i.mr-2.fa.fa-calendar-check.text-muted
            span(v-if="delivery.delivery_date") {{ delivery.delivery_date | date }}
            span(v-else) TBD
          .text-danger.font-weight-bold.nowrap(v-if="delivery.days_delay > 0") In ritardo di {{ delivery.days_delay }}gg
          .text-success.font-weight-bold.nowrap(v-if="delivery.delivery_date && delivery.days_delay < 0") In anticipo di {{ delivery.days_delay * -1 }}gg
        td(v-if="i === 0" :rowspan="delivery.items.length")
          .d-inline-flex.align-items-center.w-100(v-for="(file,j) in delivery.files" :key="j")
            .input-group
              input.form-control.form-control-sm(v-model="file.id" type="text" placeholder="N° documento" @change="updateOrder")
              .input-group-append 
                button.btn.btn-sm.btn-info(v-if="file.url" @click="getFileUrl(file.url)") 
                  i.fa.fa-search-plus
            button.btn.btn-sm.btn-outline-danger.ml-1(v-if="file.url" @click="deleteFile(index,j)") 
              i.fa.fa-trash
          S3FileUploader(:orderId="order.id_number" :deliveryIndex="index" @filesUploaded="saveFileKey" buttonClass="btn btn-sm btn-light" useCase="DDT")
        td(v-if="i === 0" :rowspan="delivery.items.length" colspan="2")
          .btn-group-vertical.actions
            button.btn.btn-outline-primary.btn-sm(v-if="delivery.delivery_date" @click="toggleDetails(index)")
              i.fa.fa-info-circle
              span Dettagli
            button.btn.btn-success.btn-sm(v-else @click="toggleDetails(index)")
              i.fa.fa-edit
              span Registra
            .dropdown-info.position-relative.mt-2(v-if="delivery.internal_notes")
              button.btn.btn-sm.dropdown-toggle.w-100
                i.fas.fa-notes-medical.mr-2 
                span Note Interne
              .dropdown-menu.dropdown-menu-right(style="max-width:max-content")
                .px-4 {{ delivery.internal_notes }}
            .dropdown-info.position-relative.mt-2(v-if="delivery.notes")
              button.btn.btn-warning.btn-sm.dropdown-toggle.w-100
                i.fas.fa-sticky-note.mr-2 
                span Segnalazioni
              .dropdown-menu.dropdown-menu-right(style="max-width:max-content")
                .px-4 {{ delivery.notes }}
      transition(name="slidefromright")
        SingleInboundOrderDetails(v-if="detailsToggleIndex === index" :orderId="order.id_number" :delivery="delivery" :deliveryIndex="index")
</template>

<script>
import ProductionService from "@/services/ProductionService";
import SingleInboundOrderDetails from "./SingleInboundOrderDetails";
import S3FileUploader from "../utils/S3FileUploader";
import S3Service from "@/services/S3Service";
export default {
  name: "SingleInboundOrder",
  props: ['order', 'index', 'ordersCount'],
  data() {
    return {
      detailsToggleIndex: -1,
      statuses: ['open','closed'],
      delivery_statuses: ['open','incoming','delivered','registered'],
      isOperator: this.$router.user.roleScore < 0,
      collapsed: this.$router.user.roleScore < 0,
      deliveriesCheck: []
    }
  },
  components: { SingleInboundOrderDetails, S3FileUploader },
  beforeMount() {
    this.checkDeliveries()
  },
  methods: {
    checkDeliveries(){
      this.deliveriesCheck = this.order.deliveries.map(delivery => {
        return {
          items: delivery?.items.map(item => {
            let check = ""
            item.quantity_received && item.quantity_received < item.quantity ? check = `${item.quantity - item.quantity_received } in meno` : ''
            item.quantity_received && item.quantity_received > item.quantity ? check = `${item.quantity_received - item.quantity } in più` : ''
            return check
          })
        }
      })
    },
    toggleDetails(index){
      this.detailsToggleIndex = index
    },
    daysBetweenDates(startDate, endDate) {
      return Math.round((new Date(startDate) - new Date(endDate)) / (1000 * 3600 * 24));
    },
    saveFileKey(data){
      const newFiles = data.fileKeys.map(url => {
        const stringIndex = url.indexOf('inbound-orders/') + 15
        return {
          id: url.substring(stringIndex, stringIndex + 16),
          url: url
        }
      })
      this.order.deliveries[data.deliveryIndex].files = [...this.order.deliveries[data.deliveryIndex].files, ...newFiles]
      this.updateOrder()
    },
    async getFileUrl(key){
      const { data } = await S3Service.getFileUrl(key)
      window.open(data, '_blank').focus()
    },
    async deleteFile(deliveryIndex, fileIndex){
      if (confirm('Confermi di volore eliminare questo file?')) {
        this.order.deliveries[deliveryIndex].files.splice(fileIndex, 1)
        await this.updateOrder()
      }
    },
    async updateOrder() {
      await ProductionService.updateInboundOrder(this.order.id_number, this.order)
      this.checkDeliveries()
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
// table {
//   tr {
//     display: grid;
//     grid-template-columns: 2rem 250px 3fr 3rem 5rem 5rem 5rem 1fr 1fr 1fr;
//   }
// }

.order_row, .items_head_row, .items_row {
  background-color: white;
  &--odd {
    background-color: #f9f9f9;
  }
  td, th {
    padding: .4rem;
  }
  &--products {
    td {
      border-top: 1px solid #f2f2f2!important;
    }
  }
}
.order_row {
  border-bottom: 2px solid black!important;
  td {
    background-color: rgba(skyblue,.7);
  }
}
.items_head_row {
  // color: $gray02;
  background-color: rgba(skyblue,.4);
}

select {
  text-transform: capitalize;
  max-width: 180px;
  display: block;
  &.closed, &.delivered {
    background-color: $green;
    color: #fff;
  }
  &.incoming {
    background-color: $ups;
    color: #fff;
  }
  &.cancelled {
    background-color: $red;
    color: #fff;
  }
  &.open {
    background-color: $clairy;
    color: #fff;
  }
  &.standby {
    background-color: $gray02;
    color: #fff;
  }
  &.registered {
    background-color: $sap_color;
    color: #fff;
    opacity: 1!important;
  }
}
select {
  border-radius: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0 1rem;
  line-height: 1.5rem;
  outline: none !important;
  border: none;
}
input[type="number"]{
  width: 4rem;
}
</style>
