<template>
  <footer>
    <div class="row align-items-center">
      <div class="col-12 col-md-2 text-center text-md-left">
        <img class="footer__logo" src="../assets/gw_logo.png" />
      </div>
      <div class="col-12 col-md-8 text-center">
        Laboratori Fabrici S.r.l. | Piazzetta Ado Furlan, 4 - 33170 - Pordenone
        PN | N. REA PN 104531 | P.IVA: IT01786900934 | Capitale sociale
        €5.454,17
      </div>
      <div class="col-12 col-md-2">
        <button @click.prevent="backToTop" class="btn backtotop">
          <i class="fas fa-angle-up"></i>
        </button>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {
    backToTop() {
      window.scrollTo({ top: 0 });
    },
  },
};
</script>
<style lang="scss">
@import "../styles/variables.scss";
footer {
  background-color: $gray04;
  color: #fff;
  padding: 1.5rem 2rem;
  font-size: 0.8rem;
  box-shadow: inset 0 0 0.4rem #000;
  -webkit-box-shadow: inset 0 0 0.4rem #000;
  -moz-box-shadow: inset 0 0 0.4rem #000;
  -o-box-shadow: inset 0 0 0.4rem #000;
  .footer__logo {
    max-width: 70px;
  }
}
.backtotop {
  opacity: 0.5;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  border-radius: 0;
  background-color: $gray03;
  color: #fff;
  font-size: 2rem;
  height: 3rem;
  text-align: center;
  line-height: 2rem;
  &:hover {
    opacity: 1;
  }
}
</style>
