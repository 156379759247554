<template lang="pug">
section.orders
  div(v-if="$router.user.roleScore <= 1 || $router.user.roleScore >= 2")
    .d-inline-flex.w-100.justify-content-between
      h2 Ordini in Entrata
      button.btn.btn-info.btn-sm(@click="syncOrders()" v-if="!isOperator" :disabled="isLoading" :class="{ 'loading' : isLoading }") 
        i.fa.fa-cloud-download-alt.mr-2
        span Scarica ordini SAP
    form.row.my-4.pb-2(v-show="!isOperator" @submit.prevent="getFilteredOrders")
      .col-6.col-md-4.col-lg-2
        label(for="keyword") ID - SKU - DDT - Fornitore
        .input-group
          input.form-control.form-control-sm#keyword.form-control.form-control-sm.border-right-0.border(type="text" v-model="filters.keyword" placeholder="Cerca...")
          span.input-group-append
            .input-group-text.bg-transparent
              i.fa.fa-search
      .col-6.col-md-4.col-lg-2
        label(for="orderStatus") Stato ordine
        select.form-control.form-control-sm#orderStatus(v-model="filters.orderStatus")
          option(value="") Tutti
          option(value="open") Aperto
          option(value="closed") Chiuso
      .col-6.col-md-4.col-lg-2
        label(for="deliveryStatus") Stato consegna
        select.form-control.form-control-sm#deliveryStatus(multiple v-model="filters.deliveryStatus")
          option(:value="null") Tutti
          option(value="open") Aperto
          option(value="incoming") In arrivo
          option(value="delivered") Consegnato
          option(value="registered") Registrato
        .mt-2.d-inline-flex.align-items-center
          input(type="checkbox" name="isLate" id="isLate" v-model="filters.isLate") 
          label.ml-2.mb-0(for="isLate") In ritardo
        //- .d-inline-flex
          label Quantità
          .d-inline-flex
            input(type="radio" id="delivery_quantity_minus" name="delivery_quantity" :value="-1" v-model="filters.wrongQuantity")
            label(for="delivery_quantity_minus") In meno
          .d-inline-flex
            input(type="radio" id="delivery_quantity_plus" name="delivery_quantity" :value="1" v-model="filters.wrongQuantity") 
            label(for="delivery_quantity_plus") In più
      .col-6.col-md-4.col-lg-2
        label(for="shippingStartDate") Data consegna prevista
        input.form-control.form-control-sm#shippingStartDate(type="date" v-model="filters.shippingStartDate")
        input.form-control.form-control-sm#shippingEndDate(type="date" v-model="filters.shippingEndDate")
      .col-6.col-md-4.col-lg-2
        label(for="deliveryStartDate") Data consegna
        input.form-control.form-control-sm#deliveryStartDate(type="date" v-model="filters.deliveryStartDate")
        input.form-control.form-control-sm#deliveryEndDate(type="date" v-model="filters.deliveryEndDate")
      .col-6.col-md-4.col-lg-2.align-self-end
        .btn-group-vertical.actions
          button.btn.btn-primary(type="submit") 
            i.fa.fa-search.mr-2
            span Filtra
          button.btn.btn-danger(@click="getOrders()")
            i.fa.fa-times.mr-2
            span Reset
    table.w-100.mt-4
      .h6(v-if="!orders || !orders.length") Nessun ordine trovato.
      template(v-else)
        tr.order_head_row.text-uppercase.thead
          th ({{ orders ? orders.length : 0 }})
          th Fornitore
          th Codice Fornitore
          th ID Ordine
          th Stato Ordine
          th Consegne
          th 
          th 
          th 
          th 
        SingleInboundOrder(v-for="(order, index) in orders" :key="order.id_number" :order="order" :index="index" :ordersCount="orders ? orders.length : 0")
  div(v-else) Access denied! You can't access this page.
</template>

<script>
import ProductionService from "@/services/ProductionService";
import SingleInboundOrder from "./SingleInboundOrder";
export default {
  name: "InboundOrders",
  data() {
    return {
      orders: [],
      pages: 1,
      isOperator: this.$router.user.roleScore < 0,
      isLoading: false,
      filters: {
        keyword: '',
        orderStatus: '',
        deliveryStatus: [],
        shippingStartDate: '',
        shippingEndDate: '',
        deliveryStartDate: '',
        deliveryEndDate: '',
        isLate: false,
        wrongQuantity: 0
      }
    }
  },
  components: {
    SingleInboundOrder
  },
  mounted() {
    this.getOrders()
  },
  methods: {
    async getOrders() {
      this.filters = {
        keyword: '',
        orderStatus: 'open',
        deliveryStatus: this.isOperator ? ['incoming','delivered'] : [],
        shippingStartDate: '',
        shippingEndDate: '',
        deliveryStartDate: '',
        deliveryEndDate: '',
        isLate: false,
        wrongQuantity: 0
      }
      await this.getFilteredOrders()
    },
    async getFilteredOrders() {
      this.orders = []
      const { data } = await ProductionService.getFilteredInboundOrders(this.filters)
      this.orders = data
    },
    async syncOrders(){
      this.isLoading = true
      await ProductionService.syncInboundOrders()
      await this.getOrders()
      this.isLoading = false
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
tr.order_head_row {
  position: sticky;
  top: 3.75rem;
  z-index: 3;
  th {
    background: #f2f2f2;
    padding: 1rem 0;
  }
}
</style>
