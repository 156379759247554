import Api from "@/services/Api";

export default {
  // Shipping
  newShipping(id) {
    return Api().post("/api/shipping/" + id);
  },
  updateShipping(id) {
    return Api().patch("/api/shipping/" + id);
  },
  cancelShipping(id) {
    return Api().delete("/api/shipping/" + id);
  },
  trackShipping(trackingNumber) {
    return Api().get("/api/shipping/track/" + trackingNumber);
  },
  trackShippings() {
    return Api().get("/api/shipping/track-update/");
  },
  createPickup(params) {
    return Api().post("/api/shipping/pickup/create/", params);
  },
  cancelPickup(pickupData) {
    return Api().post("/api/shipping/pickup/cancel", pickupData);
  },

  // Labels
  getFileUrl(key) {
    return Api().get("/api/s3/?key=" + key);
  },
  serverPrint(keys) {
    return Api().post("/api/print/", keys);
  },
  showZPL(key) {
    return Api().get("/api/s3/zpl/?key=" + key);
  },
};
