<template>       
<div class="select" v-if="discardReasons.length">
  <select 
    class="select-danger form-control form-control-sm ml-1" tabindex="-1" 
    :disabled="disabled" 
    @change="discard($event.target.value), $event.target.value = false"
  >
    <option disabled selected :value="false">
      Segnala NC
      <slot></slot>
    </option>
    <optgroup v-for="c in discardReasons" :key="c.category" :label="c.category">
      <option v-for="v in c.values" :key="v.name" :value="JSON.stringify({id: v.id, value: v.name, category: c.category, index: c.index})">{{ v.name }}</option>
    </optgroup>
  </select>
</div>
</template>
<script>
import ProductionService from "@/services/ProductionService";
import SapService from "@/services/SapService";
import productionRules from "../utils/productionRules.json";
export default {
  name: "ReportNC",
  props: ['order', 'mac', 'disabled', 'autoReasonIds'],
  data() {
    return {
      discardReasons: [],
      components: []
    }
  },
  async mounted() {
    this.getReasons()
    this.getComponents()
  },
  watch: {
    autoReasonId(){
      this.getReasons()
    }
  },
  methods: {
    getReasons(){
      this.discardReasons = productionRules.discardReasons.reduce((a, c) => {
        const values = c.values.filter((l)=> l.products.includes(this.order.product.commercial_code))
        if (values.length) { a.push({ ...c, values }) }
        return a
      },[])
      if (this.autoReasonIds?.length) {
        console.log(this.autoReasonIds)
        const filtered = this.filterReasonsByIds(this.autoReasonIds)
        filtered.length ? this.discardReasons = filtered : ''
      }
      return this.discardReasons
    },
    filterReasonsByIds(idsToFilter) {
      const filteredOptions = []
      for (const category of this.discardReasons) {
        const filteredValues = category.values.filter((value) =>
          idsToFilter.includes(value.id)
        )

        if (filteredValues.length > 0) {
          const filteredCategory = {
            ...category,
            values: filteredValues,
          };
          filteredOptions.push(filteredCategory)
        }
      }
      return filteredOptions
    },
    async discard(reasonString) {
      if (confirm('Sei sicuro di voler segnalare questa non conformità?')){
      const reason = JSON.parse(reasonString)
      // if (reason.category === 'Installazione FW' || reason.category === 'Collaudo funzionale') {
        await ProductionService.reportNC({ 
          id_number: this.order.id_number, 
          mac: this.mac, 
          reason: reason
        })
        this.$emit('ncReported', reason)
      // } else {
      //   const ncComponent = this.getComponentByDiscardReason(reason.id)
      //   const nc = {
      //     sku: ncComponent.itemCode,
      //     name: ncComponent.itemName,
      //     quantity: 1,
      //     reason: reason,
      //     notes: '',
      //     serial: reason.value === 'Alimentatore - componente non conforme' ? serial.power_supply : '' 
      //   }
      //   // this.order.nc_components.push(nc)
      //   // reason.category !== 'TAMU' ? this.clearRow(serial) : ''
      //   this.$emit('ncComponentReported', nc)
      // }
      }
    },
    getComponentByDiscardReason(id){
      return this.components.find(c => c.discardReasonId === id)
    },
    async getComponents(){
      if (!this.components.length) {
        const bom = productionRules.bom.filter(p => p.skus.includes(this.order.product.sku))[0]?.items ?? []
        if (bom.length) {
          this.components = bom
        } else {
          const response = await SapService.getProducts({itemGroup: 'Materie prime'})
          this.components = response.data.filter(c => c.productFamily === this.order.product.family)
        }
      }
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";


select.select-danger {
  border-color: $red;
  color: $red;
}
</style>
