import Api from "@/services/Api";

export default {
  // Production Orders
  getProductionOrders(page) {
    return Api().get("/api/production/p=" + page)
  },
  getFilteredProductionOrders(params) {
    return Api().post("/api/production/filtered/", params)
  },
  addProductionOrder(params) {
    return Api().post("/api/production/", params)
  },
  startProductionOrder(id, params) {
    return Api().post(`/api/production/${id}/start`, params)
  },
  updateProductionOrder(id, params) {
    return Api().put("/api/production/" + id, params)
  },
  getProductionOrder(id) {
    return Api().get("/api/production/" + id)
  },
  getProductionOrderByMac(mac) {
    return Api().get("/api/production/mac/" + mac)
  },
  deleteProductionOrder(id) {
    return Api().delete("/api/production/" + id)
  },
  // Serials
  getProductSerials(serial) {
    return Api().get("/api/production/serials/" + serial)
  },
  getLatestFirmwareVersion(product) {
    return Api().post("/api/production/firmware/", product)
  },
  activateProductSerialLink(serial, params) {
    return Api().put(`/api/production/serial/${serial}/link`, params)
  },
  deactivateProductSerialLink(serial) {
    return Api().delete(`/api/production/serial/${serial}/link`)
  },
  reportNC(params) {
    return Api().post(`/api/production/report-nc/`, params)
  },
  // Tests
  addProductionTest(params) {
    return Api().post("/api/production-test/", params)
  },
  getProductionTest(id) {
    return Api().get("/api/production-test/" + id)
  },
  // Inbound Orders
  syncInboundOrders() {
    return Api().post("/api/inbound-orders/sync")
  },
  getInboundOrders(page) {
    return Api().get("/api/inbound-orders/p=" + page)
  },
  getFilteredInboundOrders(params) {
    return Api().post("/api/inbound-orders/filtered/", params)
  },
  getInboundOrder(id) {
    return Api().get("/api/inbound-orders/" + id)
  },
  updateInboundOrder(id, params) {
    return Api().put("/api/inbound-orders/" + id, params)
  }
};