<template>
<div>Searching serial...</div>
</template>
<script>
import ProductionService from "@/services/ProductionService";
export default {
  name: "ProductionOrderSerialRedirector",
  data() {
    return {
      serial: this.$route.params.serial,
    };
  },
  async mounted() {
    await this.getProductSerials(this.serial)
  },
  methods: {
    async getProductSerials(serial) {
      await ProductionService.getProductSerials(serial).then((response) => {
        if (response && response.data) {
          const orderId = response.data.id_number
          if (response.data.error) {
            alert(`Serial ${serial} non trovato.`)
          } else {
            window.location.href = `/production/${orderId}?serial=${serial}`
          }
        }
      })
    }
  }
};
</script>