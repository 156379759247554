<template>
  <button
    class="btn mx-auto"
    :class="buttonClass"
    @click="serverPrint()"
    :disabled="buttonDisabled || isLoading"
  >
    <i class="fa fa-print"></i> {{ buttonText }}
  </button>
</template>
<script>
import ShippingService from "@/services/ShippingService";
export default {
  name: "printFiles",
  props: ["s3Keys", "buttonText", "buttonClass", "buttonDisabled"],
  data() {
    return {
      signedUrls: [],
      printData: "",
      isLoading: false,
    };
  },
  methods: {
    async serverPrint() {
      this.isLoading = true;
      if (
        confirm(
          `Are you sure? You're going to print ${this.s3Keys.length} labels.`
        )
      ) {
        this.isLoading = false;
        const response = await ShippingService.serverPrint(this.s3Keys);
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>
