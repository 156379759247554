<template lang="pug">
  .card
    .card-body
      .row(v-if="$router.user.roleScore >= 2")
        .col-md-6
        .col-md-6
          .form-check
            input.mr-2(id="simulate" type="checkbox" v-model="simulateError") 
            label.text-danger Simulate Checkup Error
          .form-check
            input.mr-2(id="downgrade" type="checkbox" v-model="downgradeFirmware") 
            label.text-danger Downgrade Firmware
      .row
        .col-12.col-md-6
          .card.card--device(:style="`border-left: 6px solid ${color};`")
            .card-body
              .d-flex.align-items-center.justify-content-between
                h6.mb-0.card-title Dispositivo
                button.btn.btn-sm.btn-primary(v-if="isSupported" @click="startTestPipeline" :disabled="isTesting") Inizia Collaudo
                .text.text-danger(v-else) Bluetooth is not supported on this device/browser.
              table.table.mt-2(v-if="device || isTesting")
                tr
                  td Nome 
                  td {{ deviceInfo.name }}
                tr
                  td Firmware 
                  td 
                    span {{ deviceInfo.firmware.version }} 
                    span(v-if="deviceInfo.firmware.latest") (Ultima: {{ deviceInfo.firmware.latest }})
                tr
                  td  Check Up
                  td 
                    span.badge.mx-1(
                      v-for="(value, key) in deviceInfo.checkup" 
                      :class="value ? 'badge-success' : 'badge-danger'"
                      :title="legend[key]"
                    ) {{ key }}
                tr
                  td Batteria
                  td 
                    span.badge.mx-1(v-if="deviceInfo.battery.percentage" :class="deviceInfo.battery.percentage < 30 || deviceInfo.battery.percentage > 50  ? 'badge-warning' : 'badge-success'") {{ deviceInfo.battery.percentage }}%
                    span.mx-1 -
                    span(v-if="deviceInfo.battery.voltage") {{ deviceInfo.battery.voltage }} mV
                //- tr
                //-   td Comandi Led
                //-   td 
                //-     span.badge.mx-1(:class="deviceInfo.leds.output === null ? 'badge-secondary' : deviceInfo.leds.output ? 'badge-success' : 'badge-danger'") OUT
                //-     span.badge.mx-1(:class="deviceInfo.leds.internal === null ? 'badge-secondary' : deviceInfo.leds.internal ? 'badge-success' : 'badge-danger'") IN
                //- tr
                //-   td Comando Ventola
                //-   td 
                //-     span.badge.mx-1(:class="deviceInfo.fan === null ? 'badge-secondary' : deviceInfo.fan ? 'badge-success' : 'badge-danger'") {{ deviceInfo.fan === null ? '' : deviceInfo.fan ? 'OK' : 'NOT OK' }}
                tr
                  td Shipping Mode 
                  td  
                    span.badge(:class="deviceInfo.shipping_mode === null ? 'badge-secondary' : deviceInfo.shipping_mode ? 'badge-success' : 'badge-secondary'") {{ deviceInfo.shipping_mode === null ? '' : deviceInfo.shipping_mode ? 'Activated' : 'Not Activated' }}
              .alert.alert-danger(v-if="errorMessage") {{ errorMessage }}
              button.btn.btn-sm.btn-danger(v-if="isTesting" @click="resetTest" :disabled="!isTesting") 
                i.fa.fa-stop-circle.mr-2
                span Arresta Collaudo
        .col-12.col-md-6
          .steps 
            .step(v-for="(step, index) in steps" :class="step.status")
              .d-flex.align-items-center
                .step__status
                  template(v-if="step.status === 'to-do'") {{ index + 1 }}
                  i.fa.fa-spinner.spinner(v-if="step.status === 'in-progress'")
                  i.fa.fa-check(v-if="step.status === 'passed'")
                  i.fa.fa-times(v-if="step.status === 'failed'")
                  i.fa.fa-forward(v-if="step.status === 'skipped'")
                div
                  .step__name {{ step.name }}
                  small.step__description {{ step.description }}
              .step_substeps(v-if="step.substeps")
                .step.substep.d-flex(v-for="(substep, i) in step.substeps" :class="substep.status" v-if="substep.status !== 'to-do'")
                  .step__status
                    template(v-if="substep.status === 'to-do'") {{ index + 1 }}.{{ i + 1 }}
                    i.fa.fa-spinner.spinner(v-if="substep.status === 'in-progress'")
                    i.fa.fa-check(v-if="substep.status === 'passed'")
                    i.fa.fa-times(v-if="substep.status === 'failed'")
                    i.fa.fa-forward(v-if="substep.status === 'skipped'")
                  .step__name {{ substep.name }}
          .mt-1(v-if="isTestCompleted || isTestFailed")
            template(v-if="this.deviceInfo.checkup.result")
              button.btn-block.btn.btn-success.mb-1(:disabled="isSaving" @click="endTest(false)") Tutto ok
              ReportNC(v-if="order" :order="order" 
                :mac="deviceInfo.mac" :disabled="isSaving" 
                @ncReported="endTest"
              ) (Manual)
            ReportNC(v-else="order" :order="order" 
              :mac="deviceInfo.mac" :disabled="isSaving" 
              :autoReasonIds="failedTestReasonId"
              @ncReported="endTest"
            ) (Auto)
  </template>
  <script>
  const CONFIG = {
    serviceUUID: 'c954c6e6-3016-4416-a72f-b184a4f039e0',
    characteristicUUID: '2b3247fd-28db-413c-934f-aa4c271984c9',
    WiFiCredentials: {
      SSID: 'MW40V_DA06',
      Password: '98541014',
      // SSID: 'TAG PN Wifi',
      // Password: 'tagpnrocks15',
      Method: '2',
    }
  }
  
  const COMMANDS = {
    ReadFirmwareVersion: 0x8A, // 138
    ReadBatteryPercent: 0xA0, // 160
    ReadBatteryVoltage: 0xA1, // 161
    CheckUp: 0x89, // 137
    DisableAnimation: 0x7B, // 123
    WriteOutputLeds: 0x12, // 18
    WriteInternalLeds: 0x11, // 17
    WriteFanSpeed: 0x10, // 16
    ShippingMode: 0x7D, // 125
    WriteWiFiSSID: 0x50, // 80
    WriteWiFiPassword: 0x52, // 92
    ReadWiFiConnected: 0xDA, // 218
    ConnectWiFi: 0x5A, // 90
    UpdateFirmware: 0x64 // 100
  }
  
  const COLORS = {
    red: [0xFF, 0x00, 0x00],
    green: [0x00, 0xFF, 0x00],
    blue: [0x00, 0x00, 0xFF],
    purple: [0xFF, 0x00, 0xFF],
    yellow: [0xFF, 0xFF, 0x00],
    orange: [0xFF, 0xA5, 0x00],
    cyan: [0x00, 0xFF, 0xFF]
  }
  
  const STATUS = {
    TO_DO: "to-do",
    IN_PROGRESS: "in-progress",
    PASSED: "passed",
    FAILED: "failed",
    SKIPPED: "skipped",
  }
  
  import ProductionService from "@/services/ProductionService";
  import ReportNC from "./ReportNC";
  
  export default {
    name: 'SingleDeviceTesting',
    props: ['color'],
    data() {
      return {
        order: null,
        simulateError: false, // TEMP
        downgradeFirmware: false, // TEMP
        isSupported: false,
        isConnected: false,
        attempt: 0,
        isTesting: false,
        isTestCompleted: false,
        isTestFailed: false,
        isSaving: false,
        device: null,
        server: null,
        characteristic: null,
        deviceInfo: {},
        failedTestReasonId: null,
        errorMessage: null,
        gattOperationInProgress: false,
        steps: [
          {
            name: 'Connessione al Dispositivo',
            status: STATUS.TO_DO
          },
          // {
          //   name: 'Test Ouput Led',
          //   status: STATUS.TO_DO
          // },
          {
            name: 'Controllo Versione Firmware',
            status: STATUS.TO_DO,
            substeps: [
              {
                name: 'Connessione al Wi-Fi',
                status: STATUS.TO_DO,
              },
              {
                name: 'Aggiornamento Firmware',
                status: STATUS.TO_DO,
              }
            ]
          },
          {
            name: 'Check Up Automatico',
            status: STATUS.TO_DO
          },
          {
            name: 'Controllo Manuale',
            description: 'Percentuale carica batteria, led e ventola accesi, difetti estetici.',
            status: STATUS.TO_DO
          },
          {
            name: 'Impostazione Shipping Mode',
            status: STATUS.TO_DO
          },
          {
            name: 'Disconnessione dal Dispositivo',
            status: STATUS.TO_DO
          },
          {
            name: 'Collaudo Completato',
            status: STATUS.TO_DO
          }
        ],
        legend: {
          CHG: "Battery Charger",
          SHT4x: "Sensore Temperatura/Umidità",
          LIS2DH12: "Accelerometro",
          LPS22HH: "Sensore Pressione/Temperatura",
          BME680: "Sensore Gas"
        }
      };
    },
    // computed: {
    //   id_number() { return this.$route.query?.id_number }
    // },
    components: { ReportNC },
    methods: {
      wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms) )
      },
      readValue(command, data) {
        const error = data.getUint8(3)
        if (error > 0x00) {
          console.error('Command Error:', command, data, error)
          return false
        }
        const length = data.getUint8(1) - 2
  
        const offset = 4
        const buffer = new Uint8Array(data.buffer)
        const payload = Array.from(buffer.slice(offset, offset + length))
        console.info('Command Response:', command, data, payload)
        return payload
      },
      buildTLV(command, data) {
        const dataLength = data ? data.length : 0
        const tlvArray = [command, dataLength, ...(data || [])]
        return new Uint8Array(tlvArray)
      },
      decode(array) {
        return new TextDecoder().decode(array)
      },
      encode(string) {
        return new TextEncoder().encode(string)
      },
      decodePayload(payload) {
        return payload?.[0] || 0
      },
      async sendCommand(command, data, isShippingModeCommand) {
        if (this.gattOperationInProgress) {
          console.log("Waiting for ongoing GATT operation to complete...");
          await this.wait(1000)
          return this.sendCommand(command, data, isShippingModeCommand);
        }
        this.gattOperationInProgress = true
        await this.checkConnectionStatus()
  
        const tlv = this.buildTLV(command, data)
        const payload = Buffer.from(tlv)
        console.log(`Sending command ${command}`, tlv, payload)
  
        return new Promise(async (resolve, reject) => {
          const onCharacteristicValueChanged = (event) => {
            const value = event.target.value
            console.log('characteristicvaluechanged value', value)
            if (value.getUint8(2) === command) {
              const decodedValue = this.readValue(command, value)
              this.characteristic.removeEventListener('characteristicvaluechanged', onCharacteristicValueChanged)
              this.gattOperationInProgress = false
              resolve(decodedValue)
            }
          }
  
          this.characteristic.addEventListener('characteristicvaluechanged', onCharacteristicValueChanged)
  
          try {
            await this.characteristic.writeValueWithResponse(payload)
          } catch (error) {
            this.characteristic.removeEventListener('characteristicvaluechanged', onCharacteristicValueChanged)
  
            if (isShippingModeCommand) {
              this.gattOperationInProgress = false
              resolve(true)
            } else {
              console.error('Error sending command:', error)
              this.gattOperationInProgress = false
              reject(error)
            }
          }
        })
      },
      async resetTest(){
        this.device ? this.device.removeEventListener('gattserverdisconnected', async () => { await this.handleDeviceDisconnection() }) : ''
        if (this.server?.connected) {
          await this.characteristic?.stopNotifications()
          await this.server.disconnect()
        }
  
        this.device = null
        this.server = null
        this.characteristic = null
        this.isTesting = false
        this.isTestCompleted = false
        this.isTestFailed = false
        this.errorMessage = null
  
        this.steps.forEach(step => {
          step.status = "to-do"
          if (step.substeps) {
            step.substeps.forEach(substep => {
              substep.status = "to-do"
            })
          }
        })
  
        this.deviceInfo = {
          name: '',
          mac: '',
          firmware: {
            version: '-',
            latest: null,
            updated: false
          },
          battery: {
            percentage: '',
            voltage: ''
          },
          leds: {
            output: null,
            internal: null
          },
          fan: null,
          checkup: {},
          shipping_mode: null,
          start_timestamp: null
        }
      },
      async connectToDevice(device) {
        try {
          if (!device) {
            this.device = await navigator.bluetooth.requestDevice({
              filters: [{ namePrefix: 'SLF' }],
              optionalServices: [CONFIG.serviceUUID]
            })
          }
          this.steps[0].status = STATUS.IN_PROGRESS
  
          this.deviceInfo.name = this.device.name
          this.deviceInfo.mac = this.device.name.substr(-12)
          this.server = await this.device.gatt.connect()
  
          if (this.server.connected) {
            const service = await this.server.getPrimaryService(CONFIG.serviceUUID)
            this.characteristic = await service.getCharacteristic(CONFIG.characteristicUUID)
            await this.characteristic.startNotifications()
            
            this.device.addEventListener('gattserverdisconnected', async () => { await this.handleDeviceDisconnection() })
  
            this.isConnected = true
            this.steps[0].status = STATUS.PASSED
          }
          
        } catch (error) {
          this.isConnected = false
          this.isTesting = false
          this.steps[0].status = STATUS.FAILED
          // this.sendError('Error connecting to device.') 
        }
      },
      async handleDeviceDisconnection(){
        const maxAttempt = 24
        const delay = 5000
        
        if (!this.isTestCompleted) {
          console.warn('Device disconnected.')
          this.device?.removeEventListener('gattserverdisconnected', async () => { await this.handleDeviceDisconnection() })
          this.isConnected = false
          const interval = await setInterval(async() => {
            try {
              if (this.attempt >= maxAttempt -1) {
                this.sendError('Unable to reconnect to device. Time out.')
                return
              } else {
                this.steps[0].status = STATUS.IN_PROGRESS
                this.attempt++
                console.warn(`Device reconnection attempt ${this.attempt}...`)
                await this.connectToDevice(this.device)
                if (this.server.connected) {
                  this.attempt = 0
                  this.isConnected = true
                  console.warn('Device successfully reconnected!')
                  clearInterval(interval)
                }
              }
            } catch (error) {
              this.isConnected = false
              clearInterval(interval)
              this.steps[0].status = STATUS.FAILED
              this.sendError(error)
            }
          }, delay)
        }
      },
      async checkConnectionStatus(){
        if (!this.isTestCompleted) {
          await this.wait(500)
          if (!this.isConnected) {
            console.warn('Device is disconnected. Waiting for reconnection...')
            await new Promise(resolve => {
              const checkInterval = setInterval(() => {
                if (this.isConnected) {
                  clearInterval(checkInterval)
                  resolve()
                }
              }, 5000)
            })
          }
        }
      },
      async isConnectedToWiFi(){
        let attempt = 0
        return await new Promise(async resolve => {
          const checkInterval = await setInterval(async() => {
            const WiFiConnectionResponse = await this.sendCommand(COMMANDS.ReadWiFiConnected)
            const isConnected = +WiFiConnectionResponse === 1
            if (isConnected || attempt>= 10) {
              clearInterval(checkInterval)
              resolve(isConnected)
            } else {
              attempt++
            }
          }, 3000)
        })
      },
      async writeOutputLeds(color) {
        const outputLedsOutput = await this.sendCommand(COMMANDS.WriteOutputLeds, color)
        this.deviceInfo.leds.output = outputLedsOutput ? true : false
      },
      compareVersions(a, b) {
          const a_ = a.split('.').map(x => +x)
          const b_ = b.split('.').map(x => +x)
  
          a_.push(0, 0, 0)
          b_.push(0, 0, 0)
  
          for (let i = 0; i < 3; i++) {
            if (a_[i] < b_[i]) {
              return 1
            } else if (a_[i] > b_[i]) {
              return -1
            }
          }
          return 0
      },
      async checkFirmwareVersion(){
        this.steps[1].status = STATUS.IN_PROGRESS
        const firmwareOutput = await this.sendCommand(COMMANDS.ReadFirmwareVersion);
        const firmwareVersion = this.decode(new Uint8Array(firmwareOutput)).replaceAll("\0", "")
        this.deviceInfo.firmware.version = firmwareVersion
  
        if (!firmwareVersion) {
          this.steps[1].status = STATUS.FAILED
          this.failedTestReasonId = [2.1]
          this.sendError(`Unable to Read Firmware!`)
        }
  
        const { data } = await ProductionService.getLatestFirmwareVersion({ commercial_code: 'SHL0', critical: true })
        // const latestFirmwareVersion = data
        const latestFirmwareVersion = (this.order.id_number === 'P0000610' || this.order.id_number === 'P0000624') ? '1.0.8' : '1.0.1' // this.downgradeFirmware ? '1.0.1' : data
        this.deviceInfo.firmware.latest = latestFirmwareVersion
        const needsUpdate = this.compareVersions(firmwareVersion, latestFirmwareVersion) > 0
        if (needsUpdate) {
          this.deviceInfo.firmware.updated = true
          this.steps[1].substeps[0].status = STATUS.IN_PROGRESS
          await this.sendCommand(COMMANDS.WriteWiFiSSID, this.encode(CONFIG.WiFiCredentials.SSID))
          await this.sendCommand(COMMANDS.WriteWiFiPassword, this.encode(CONFIG.WiFiCredentials.Password))
          await this.sendCommand(COMMANDS.ConnectWiFi, [0x00, +CONFIG.WiFiCredentials.Method])
          const isConnected = await this.isConnectedToWiFi()
          if (isConnected) {
            this.steps[1].substeps[0].status = STATUS.PASSED
            this.steps[1].substeps[1].status = STATUS.IN_PROGRESS
            await this.sendCommand(COMMANDS.UpdateFirmware,this.encode(latestFirmwareVersion))
            await this.wait(5000)
            await this.checkFirmwareVersion()
          } else {
            this.steps[1].status = STATUS.FAILED
            this.steps[1].substeps[0].status = STATUS.FAILED
            this.steps[1].substeps[1].status = STATUS.FAILED
            this.failedTestReasonId = [2.1]
            this.sendError(`Unable to Connect to Wi-Fi to Update Firmware!`)
          }
        } else {
          this.deviceInfo.updated ? this.steps[1].substeps[1].status = STATUS.PASSED : ''
          this.steps[1].status = firmwareVersion ? STATUS.PASSED : STATUS.FAILED
          this.failedTestReasonId = !firmwareVersion ? [2.1] : null
        }
      },
      async checkup(){
        this.steps[2].status = STATUS.IN_PROGRESS
        const [checkupOutput] = await this.sendCommand(COMMANDS.CheckUp);
  
        const bits = checkupOutput.toString(2)
        this.deviceInfo.checkup.CHG = bits[3] === '0'
        this.deviceInfo.checkup.LPS22HH = bits[6] === '0'
        // this.deviceInfo.checkup.SHT4x = bits[4] === '0'
        // this.deviceInfo.checkup.LIS2DH12 = bits[5] === '0'
        // this.deviceInfo.checkup.BME680 = bits[7] === '0'
  
        let checkupResult = this.deviceInfo.checkup.CHG && this.deviceInfo.checkup.LPS22HH
  
        this.simulateError ? checkupResult = false : '' //TEMP
  
        this.steps[2].status = checkupResult ? STATUS.PASSED : STATUS.FAILED
        this.deviceInfo.checkup.result = checkupResult
  
        if (!checkupResult) {
          this.failedTestReasonId = [4.0]
          this.sendError('Checkup failed!')
        } else {
          this.failedTestReasonId = null
        }
      },
      async checkComponents(){
        this.steps[3].status = STATUS.IN_PROGRESS
        await this.getBatteryInfo()
        await this.writeInternalLeds(true)
        await this.writeFan(true)
        // this.steps[3].status = STATUS.PASSED
      },
      async getBatteryInfo(){
        const batteryPercentageOutput = await this.sendCommand(COMMANDS.ReadBatteryPercent)
        this.deviceInfo.battery.percentage = this.decodePayload(batteryPercentageOutput)
        
        const batteryVoltageOutput = await this.sendCommand(COMMANDS.ReadBatteryVoltage)
        const dv = new DataView(new Uint8Array(batteryVoltageOutput).buffer)
        const uint = dv.getUint16(0, true)
        this.deviceInfo.battery.voltage = uint
      },
      async writeInternalLeds(switchOn){
        const internalLedsOutput = await this.sendCommand(COMMANDS.WriteInternalLeds, switchOn ? [0x5] : [0x00])
        this.deviceInfo.leds.internal = internalLedsOutput ? true : false
      },
      async writeFan(switchOn){
        const fanOutput = await this.sendCommand(COMMANDS.WriteFanSpeed, switchOn ? [0x64] : [0x00])
        this.deviceInfo.fan = fanOutput ? true : false
      },
      async setShippingMode() {
        this.steps[4].status = STATUS.IN_PROGRESS
        const shippingModeOutput = await this.sendCommand(COMMANDS.ShippingMode, undefined, true)
        this.deviceInfo.shipping_mode = shippingModeOutput
        this.steps[4].status = this.deviceInfo.shipping_mode ? STATUS.PASSED : STATUS.FAILED
      },
      sendError(error){
        throw new Error(error)
      },
      async startTestPipeline() {
        await this.resetTest()
        this.isTesting = true
        try {
          await this.connectToDevice()
          
          if (!this.device) {
            this.resetTest()
            return
          }
          
          // this.device.addEventListener('gattserverdisconnected', async () => {
          //   await this.handleDeviceDisconnection()
          // })
  
          const response = await ProductionService.getProductionOrderByMac(this.deviceInfo.mac)
          if (response && response.data) {
            this.order = response.data
          } else {
            alert('Dispositivo non trovato in nessun ordine di produzione.')
            this.order = null
          }
  
          this.deviceInfo.start_timestamp = new Date().getTime()
          await this.writeOutputLeds([...COLORS[this.color],...COLORS[this.color],...COLORS[this.color],...COLORS[this.color]])
          await this.checkFirmwareVersion()
          await this.checkup()
          await this.writeOutputLeds([...COLORS.green,...COLORS.green,...COLORS.green,...COLORS.green])
  
          await this.checkComponents()
          this.isTestCompleted = true
        } catch (error) {
          this.errorMessage = error
          console.error('PIPELINE ERROR:', error)
          await this.writeOutputLeds([...COLORS.red,...COLORS.red,...COLORS.red,...COLORS.red])
          this.isTestFailed = true
          this.isTestCompleted = true
        }
      },
      async endTest(reportedNC) {
  
        if (reportedNC) {
          await this.writeOutputLeds([...COLORS.red,...COLORS.red,...COLORS.red,...COLORS.red])
          if (reportedNC.error) {
          alert(`Errore durante segnalazione della non conformità: ${reportedNC.error}`)
          }
        }
  
        this.isSaving = true
  
        await this.writeInternalLeds(false)
        await this.writeFan(false)
  
        this.steps[3].status = this.isTestFailed ? STATUS.SKIPPED : !reportedNC ? STATUS.PASSED : STATUS.FAILED
  
        await this.setShippingMode()
        await this.wait(1000)
  
        this.steps[5].status = STATUS.IN_PROGRESS
        if (this.server?.connected) {
          await this.characteristic.stopNotifications()
          await this.server.disconnect()
        } else {
          this.device?.removeEventListener('gattserverdisconnected', async () => { await this.handleDeviceDisconnection() })
        }
        this.steps[5].status = this.server.connected ? STATUS.FAILED : STATUS.PASSED
        await this.saveTestResponse(reportedNC)
  
        await this.wait(1000)
        this.isSaving = false
        await this.resetTest()
  
      },
      async saveTestResponse(reportedNC){
        this.steps[6].status = STATUS.IN_PROGRESS
  
        const testLog = {
          id: this.deviceInfo.name,
          type: 'device',
          data: {
            id_number: this.order ? this.order.id_number : null,
            ...this.deviceInfo,
            end_timestamp: new Date().getTime(),
            nc: reportedNC
          }
        }
        const testResponse = await ProductionService.addProductionTest(testLog)
        console.log(testResponse, testLog)
  
        const response = true
        this.steps[6].status = response ? STATUS.PASSED : STATUS.FAILED
      }
  
    },
    async mounted() {
      if ('bluetooth' in navigator) {
        this.isSupported = true
        window.addEventListener("keyup", (e) => {
          e.keyCode === 32 ? !this.isTesting ? this.startTestPipeline() : '' : ''
        })
  
        // const { data } = await ProductionService.getProductionOrder(this.id_number)
        // this.order = data
  
      } else {
        console.error('Bluetooth is not supported on this device/browser.');
        return;
      }
    },
  };
  </script>
  <style lang="scss" scoped>
  @import "../../styles/variables.scss";
  .code {
    background-color: #2c2c2c;
    color: #B4D455;
  }
  
  .steps {
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      position: absolute;
      left: 1.25rem;
      top: 0;
      background-color: $lightblue;
    }
  }
  .step, .substep {
    position: relative;
    background-color: #fff;
    border: 1px solid $lightblue;
    border-radius: 2rem;
    padding: .25rem .25rem;
    margin: .5rem 0;
    align-items: center;
  
    &__description {
      line-height: 0;
    }
  
    &__status {
      background-color: $lightblue;
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      text-align: center;
      font-size: 1rem;
      line-height: 2rem;
      margin-right: .5rem;
      flex: 0 0 auto;
    }
    &.passed {
      .step__status {
        background-color: $green!important;
        animation: none!important;
        color: #fff;
      }
      border-color: $green;
    }
    &.failed {
      .step__status {
        background-color: $red;
        color: #fff;
      }
      border-color: $red;
    }
    &.skipped {
      .step__status {
        background-color: $gray01;
        color: $gray02;
      }
      border-color: $gray02;
    }
    &.to-do {
      opacity: .75;
    }
    &.in-progress {
      .step__status {
        @extend .rotate;
        background-color: $blue;
        color: #fff;
      }
      border-color: $blue;
    }
  }
  </style>