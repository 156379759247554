import Api from "@/services/Api";

export default {
  sapImportOrders() {
    return Api().get("/api/sap/orders/");
  },
  sapOldImportOrders() {
    return Api().get("/api/sap/old/");
  },
  updateSapProduct(params) {
    return Api().put("/api/sap/products/", params);
  },
  getDocs(orderId, docType,email) {
    return Api().get(`/api/sap/docs/${orderId}/?docType=${docType}&email=${email}`);
  },
  getProducts(params) {
    return Api().post(`/api/sap/products/`, params);
  },
  getPriceLists(params) {
    return Api().post(`/api/sap/pricelists/`, params);
  },
  getPriceList(params) {
    return Api().post(`/api/sap/pricelist/`, params);
  },
  getProductPrice(params) {
    return Api().post(`/api/sap/product-price/`, params);
  },
  getBom(sku) {
    return Api().get(`/api/sap/bom/${sku}`);
  },
  getQuery(queryCode) {
    return Api().get(`/api/sap/query/${queryCode}`);
  },
  importOrder(orderId) {
    return Api().get(`/api/sap/import/${orderId}`)
  },
  addProductionOrder(id, product) {
    return Api().post(`/api/sap/production/${id}`, product);
  },
  closeProductionOrder(id, order) {
    return Api().post(`/api/sap/production/${id}/close`, order);
  },
  salesforceSyncSapProducts() {
    return Api().get("/api/crm/products-sync/");
  }
};
