import axios from "axios";
import router from "../router/index.js";
const config = require("../config.json");

export default () => {
  const instance = axios.create({
    withCredentials: true,
    baseURL: config.server_url,
  });

  /*
  instance.interceptors.request.use((config) => {
    return config
  }, (error) => {
    return Promise.reject(error)
  })
  */

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (String(error).includes("403")) {
        router.user = false;
        router.push("login");
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
