<template>
<div v-if="order">
  <div class="d-inline-flex align-items-center px-4">
    <div @click="$router.go(-1)" class="btn btn-outline-secondary btn-md mr-4"><i class="fa fa-arrow-left"></i></div>
    <!-- <router-link :to="{ name: 'Orders' }" class="btn btn-outline-secondary btn-md mr-4"><i class="fa fa-arrow-left"></i></router-link> -->
    <h3 class="mb-0">Order #{{ orderId }}</h3>
  </div>
  <div class="p-4">
    <div class="row">
      <div class="col-12 col-md-6">
        <section>
          <h6><i class="fa fa-th-list"></i> CUSTOMER</h6>
          <div class="row">
            <div class="col-12 col-md-6">
              <strong>Billing Address</strong>
              <div>{{ order.billing.company }}</div>
              <div>{{ order.billing.first_name }} {{ order.billing.last_name }}</div>
              <div>{{ order.billing.address_1 }} {{ order.billing.address_2 }}</div>
              <div>{{ order.billing.postcode }} {{ order.billing.city }} ({{ order.billing.state }})</div>
              <div>{{ order.billing.country }}</div>
              <div>{{ order.billing.email }}</div>
              <div>{{ order.billing.phone }}</div>
              <div>{{ order.billing.vat_number }}</div>
              <div>{{ order.billing.e_invoice }}</div>
            </div>
            <div class="col-12 col-md-6">
              <strong>Shipping Address</strong>
              <div>{{ order.shipping.company }}</div>
              <div>{{ order.shipping.first_name }} {{ order.shipping.last_name }}</div>
              <div>{{ order.shipping.address_1 }} {{ order.shipping.address_2 }}</div>
              <div>{{ order.shipping.postcode }} {{ order.shipping.city }} ({{ order.shipping.state }})</div>
              <div>{{ order.shipping.country }}</div>
              <div>{{ order.shipping.email }}</div>
              <div>{{ order.shipping.phone }}</div>
            </div>
          </div>
        </section>
        <section>
          <h6><i class="fa fa-th-list"></i> ORDER</h6>
          <div class="row">
            <div class="col-12 col-md-6">
              <ul>
                <li><strong>Date:</strong> {{ order.date | formatDate }}</li>
                <li v-if="order.goods_readiness"><strong>Goods Readiness:</strong> {{ order.goods_readiness | formatDate }}</li>
                <li v-if="order.createdBy"><strong>Created by: </strong>{{ order.createdBy }}</li>
                <li>
                  <strong>Created at:</strong>
                  {{ order.createdAt | formatDate }}
                </li>
                <li>
                  <strong>Updated at:</strong>
                  {{ order.updatedAt | formatDate }}
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-6">
              <ul>
                <li><strong>Channel Group:</strong> {{ order.channel_group }}</li>
                <li><strong>Channel:</strong> {{ order.channel }}</li>
                <li><strong>Typology:</strong> {{ order.typology }}</li>
                <li><strong>Source:</strong> {{ order.source }}</li>
              </ul>
            </div>
            <div class="col-12"><hr></div>
            <div class="col-12 col-md-6">
              <ul>
                <li>
                  <strong>Status:</strong>
                  {{ order.status }}
                </li>
                <li>
                  <strong>Shipping Status:</strong>
                  {{ order.shipping_status }}
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-6">
              <ul>
                <li>
                  <strong>Shipping Total:</strong>
                  {{ order.shipping_total }} (tax. {{ order.shipping_tax }})
                </li>
                <li>
                  <strong>Discount:</strong> {{ order.discount_total }} ({{
                    order.discount_percentage
                  }}%)
                </li>
                <li>
                  <strong>Total:</strong> {{ order.total }} (tax.
                  {{ order.total_tax }})
                </li>
                <li>
                  <strong>Paid:</strong> {{ order.payment.paid_percentage }}%
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-12">
              <ul>
                <li v-if="order.notes"><strong>Notes:</strong> {{ order.notes }}</li>
                <li class="mt-2" v-if="order.internal_notes"><strong>Internal Notes:</strong> {{ order.internal_notes }}</li>
              </ul>
            </div>
            <div class="col-12"><hr></div>
            <div class="col-12">
              <strong>Products:</strong>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th>Subtotal</th>
                    <th>Discount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product in order.products" :key="product._id">
                    <td>
                      {{ product.sku }}
                    </td>
                    <td>{{ product.name }}</td>
                    <td>{{ product.quantity }}</td>
                    <td>{{ product.price }} {{ order.currency | currency }}</td>
                    <td>
                      {{ product.total }} (tax. {{ product.total_tax }})
                    </td>
                    <td>
                      {{ product.subtotal }} (tax.
                      {{ product.subtotal_tax }})
                    </td>
                    <td>{{ product.discount_percentage }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <section>
          <div class="row mb-4">
              <h6 class="col-12 col-md-4"><i class="fas fa-file-invoice"></i> SAP Documents</h6>
              <div class="col-12 col-md-8 justify-content-end btn-group">
                <button v-if="order.invoice" class="btn sap mr-1" :class="{ loading: isLoading }" @click="getDocs(order.id_number, 'FTV', order.billing.email)"><i class="fa fa-cloud-download-alt"></i> Scarica Fattura</button>
                <button v-if="!order.invoice" class="btn sap mr-1" :class="{ loading: isLoading }" @click="getDocs(order.id_number, 'DDT', order.billing.email)"><i class="fa fa-cloud-download-alt"></i> Scarica DDT</button>
                <button class="btn sap" :class="{ loading: isLoading }" @click="getDocs(order.id_number, 'ALL', order.billing.email)"><i class="fa fa-cloud-download-alt"></i> Scarica tutto</button>
              </div>
          </div>
          <div v-for="(doc, key) in order.sap_documents" :key="key">
            <h6>{{ key }} - {{ doc.type }}</h6>
            <hr>
            <ul style="columns: 2">
              <li><strong>SAP ID:</strong> {{ doc.sap_id || "-" }}</li>
              <li><strong>Number:</strong> {{ doc.number }}</li>
              <li>
                <strong>Date:</strong>
                {{ doc.date | formatDate }}
              </li>
              <li>
                <strong>Discount Total:</strong>
                {{ doc.discount_total }} {{ order.currency | currency }}
              </li>
              <li>
                <strong>Shipping Total:</strong>
                {{ doc.shipping_total }} {{ order.currency | currency }}
              </li>
            </ul>
            <strong>Products:</strong>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                  <th>Discount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in doc.products" :key="product._id">
                  <td>{{ product.sku }}<br /></td>
                  <td>{{ product.name }}</td>
                  <td>{{ product.quantity }}</td>
                  <td>{{ product.price }} {{ order.currency | currency }}</td>
                  <td>{{ product.total }} {{ order.currency | currency }}</td>
                  <td>{{ product.discount || 0 }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section v-if="(order.subscription && order.channel === 'Subscription')">
          <div>
              <h6><i class="fa fa-ticket-alt"></i> Subscription</h6>
          </div>
          <ul class="mb-2">
            <li>Start Date: {{ order.subscription.start_date | formatDate }}</li>
            <li>End Date: {{ order.subscription.end_date | formatDate }}</li>
            <li>Payment Terms: {{ order.subscription.payment_terms }}</li>
          </ul>
          <strong>Products:</strong>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Serial</th>
                <th>MAC</th>
                <th>
                  <div class="d-flex justify-content-between align-items-center">
                    <span>Activate Links</span>
                    <select v-model="subscription.selectedSector">
                      <option disabled>Select a sector</option>
                      <option v-for="sector in subscription.sectors" :key="sector" :value="sector">{{ sector }}</option>
                    </select>
                    <div class="buttons ml-4">
                      <button class="btn btn-sm btn-success" @click="activateAllSerials()" :disabled="subscription.isLoading"><i class="fa fa-power-off"></i></button>
                      <button class="btn btn-sm ml-1 btn-outline-danger" @click="deactivateAllSerials()" :disabled="subscription.isLoading"><i class="fa fa-power-off"></i></button>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <template v-for="product in production">
              <tbody :key="'sub-s-' + product.serials._id">
                <tr v-if="product.serials">
                  <td>{{ product.serials.sku }}</td>
                  <td>{{ product.serials.product_3 || product.serials.product }}</td>
                  <td>{{ product.serials.mac }}</td>
                  <td>
                    <div class="d-flex justify-content-between align-items-center">
                      <a :href="`https://link.vitesy.com/s/${product.serials.product_3 || product.serials.product}`" target="_blank">{{ `https://link.vitesy.com/s/${product.serials.product_3 || product.serials.product}` }}</a>
                      <div class="buttons ml-4">
                        <button class="btn btn-sm btn-success" @click="activateSerial(product.serials, true)" :disabled="subscription.isLoading"><i class="fa fa-power-off"></i></button>
                        <button class="btn btn-sm ml-1 btn-outline-danger" @click="deactivateSerial(product.serials, true)" :disabled="subscription.isLoading"><i class="fa fa-power-off"></i></button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </section>
      </div>
      <div v-if="order.shipping_details" class="col-12 col-md-6">
        <section>
          <h6><i class="fa fa-truck"></i> Shipping</h6>
          <Shipments :orderId="order.id_number" :shipments="order.shipments"/>
          <ul>
            <li>
              <strong>Departure Location:</strong>
              {{ order.departure_location }}
              <kbd v-if="order.logistic_partner_id" class="hive">
                ID: <a :href="`https://portal.hive.app/orders/${order.logistic_partner_id}`" target="_blank">{{ order.logistic_partner_id }}</a>
              </kbd>
            </li>
            <li>
              <strong>Type: </strong>
              <span
                v-text="
                  order.shipping_details.express ? 'Express' : 'Standard'
                "
              ></span>
            </li>
            <li>
              <strong>Rate:</strong> {{ order.shipping_details.rate }}€
            </li>
            <li>
              <strong>Labels:</strong>
              <div
                v-for="label in order.shipping_details.labels"
                :key="label"
              >
                {{ label }}
              </div>
            </li>
            <li>
              <strong>Shipping Date:</strong>
              {{ order.shipping_details.date | formatDate }}
            </li>
            <li>
              <strong>Delivery Date:</strong>
              {{ order.shipping_details.delivery_date | formatDate }}
            </li>
            <li>
              <strong>Paperless:</strong>
              <a
                v-if="order.shipping_details.paperless"
                href="#"
                @click.prevent="showPaperless = !showPaperless"
                v-text="showPaperless ? 'Hide' : 'Show'"
              ></a
              ><span v-else>-</span>
              <S3File
                v-if="showPaperless"
                :s3Key="order.shipping_details.paperless"
              />
            </li>
            <li v-if="order.shipping_details.signaturetracking">
              <strong>Proof of Delivery:</strong>
              <S3File
                :s3Key="
                  order.shipping_details.signaturetracking.pod_letter
                "
              />
              <S3File
                :s3Key="
                  order.shipping_details.signaturetracking.signature
                "
              />
            </li>
          </ul>
        </section>
        <section v-if="order.packages && order.packages.length">
          <h6><i class="fas fa-box-open"></i> Packages</h6>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Name</th>
                <th>Dimensions</th>
                <th>Products</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in order.packages" :key="product._id">
                <td>
                  {{ product.sku }}
                </td>
                <td>{{ product.name }}</td>
                <td>
                  {{
                    `L${product.dimensions.length}×W${product.dimensions.width}×H${product.dimensions.height}×${product.dimensions.weight}KG`
                  }}
                </td>
                <td>{{ product.products.join(", ") }}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section v-if="production.length">
          <h6><i class="fa fa-industry"></i> Production</h6>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Order</th>
                <th>SKU</th>
                <th>Serial</th>
                <th>MAC</th>
              </tr>
            </thead>
            <template v-for="product in production">
              <tbody :key="product._id">
                <tr v-if="product.serials">
                  <td>
                    {{ product.id_number }}<br />
                    {{ product.date | formatDate }}
                  </td>
                  <td>{{ product.serials.sku }}</td>
                  <td>
                    <router-link :to="`/production/serial/${product.serials.product}`">{{ product.serials.product }}</router-link>
                    <div class="d-block" v-if="product.serials.product_2">
                      ↳ <router-link :to="`/production/serial/${product.serials.product}`">{{ product.serials.product_2 }}</router-link>
                    </div>
                    <div class="d-block" v-if="product.serials.product_3">
                      ↳ <router-link :to="`/production/serial/${product.serials.product_3}`">{{ product.serials.product_3 }}</router-link>
                    </div>
                  </td>
                  <td>
                    <a :href="`https://admin.vitesy.com/devices?search=${product.serials.mac}`" target="_blank">{{ product.serials.mac }}</a>
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </section>
        <section v-if="order.crm">
          <h6><i class="fa fa-cloud"></i> CRM (Salesforce)</h6>
          <div class="row">
            <div class="col-12 col-md-6">
              <ul>
                <li>
                  <strong>Account ID: </strong>
                  <a :href="`https://vitesy.lightning.force.com/lightning/r/Account/${order.crm.accountId}/view`" target="_blank">{{ order.crm.accountId }}</a>
                </li>
                <li>
                  <strong>Contact ID: </strong>
                  <a :href="`https://vitesy.lightning.force.com/lightning/r/Contact/${order.crm.contactId}/view`" target="_blank">{{ order.crm.contactId }}</a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-6">
              <ul>
                <li>
                  <strong>Opportunity ID: </strong>
                  <a :href="`https://vitesy.lightning.force.com/lightning/r/Opportunity/${order.crm.opportunityId}/view`" target="_blank">{{ order.crm.opportunityId }}</a>
                </li>
                <li v-if="order.crm.quoteId">
                  <strong>Quote ID: </strong>
                  <a :href="`https://vitesy.lightning.force.com/lightning/r/Quote/${order.crm.quoteId}/view`" target="_blank">{{ order.crm.quoteId }}</a>
                </li>
                <li>
                  <strong>Order ID: </strong>
                  <a :href="`https://vitesy.lightning.force.com/lightning/r/Order/${order.crm.orderId}/view`" target="_blank">{{ order.crm.orderId }}</a>
                </li>
              </ul>
            </div>
            <div class="col-12" v-if="order.crm.devicesId && order.crm.devicesId.length">
              <ul>
                <li>
                  <strong>Devices IDs: </strong>
                    <a v-for="d in order.crm.devicesId" :key="d"
                      :href="`https://vitesy.lightning.force.com/lightning/r/TM_Device__c/${d}/view`" target="_blank">
                      {{ d }}
                    </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div class="col-12">
        <a
          class="btn btn-sm btn-dark"
          @click="showRAW = !showRAW"
          v-text="showRAW ? 'Hide RAW data' : 'Show RAW data'"
        ></a>
        <pre class="code" v-if="showRAW">{{ order | pretty }}</pre>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import OrdersService from "@/services/OrdersService";
import ProductionService from "@/services/ProductionService";
import SapService from "@/services/SapService";
import S3File from "../utils/S3File";
import Shipments from "./Shipments";
export default {
  name: "SingleOrderDetails",
  data() {
    return {
      order: "",
      production: [],
      isLoading: false,
      showModal: false,
      showDetails: true,
      showRAW: false,
      showPaperless: false,
      subscription: {
        sectors: ['horeca','office'],
        selectedSector: 'horeca',
        isLoading: false
      }
    };
  },
  components: { S3File, Shipments },
  computed: {
    orderId() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.getOrder(this.orderId);
  },
  methods: {
    async getOrder(id) {
      const response = await OrdersService.getOrder(id);
      this.order = response.data;
      if (this.order.shipments?.length) {
        this.order.shipments.map(async (shipment) => {
          if (shipment.serials) {
            shipment.serials.map(async (serial) => {
              this.getProductSerials(serial)
            });
          }
        });
      } else if (this.order.packages) {
        this.order.packages.map(async (singlePackage) => {
          if (singlePackage.serials) {
            singlePackage.serials.map(async (serial) => {
              this.getProductSerials(serial);
            });
          }
        });
      }
    },
    async getProductSerials(serial) {
      const response = await ProductionService.getProductSerials(serial);
      this.production.push(response.data);
    },
    async getDocs(orderId, docType, email) {
      this.isLoading = true
      const response = await SapService.getDocs(orderId, docType, email);
      const documents = response.data
      if (documents && documents.length) {
        documents.forEach((doc) => {
          const linkSource = `data:application/pdf;base64,${doc.file}`;
          const downloadLink = document.createElement("a");
          const fileName = `${doc.numAtCard}_${doc.docEntry}_${doc.objType}.pdf`
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click()
        })
      }
      this.isLoading = false
    },
    // Subscription
    async activateAllSerials() {
      await Promise.all(this.production.map(async product => {
        return await this.activateSerial(product.serials, false)
      }))
      alert('Seriali attivati!')
    },
    async deactivateAllSerials() {
      await Promise.all(this.production.map(async product => {
        return await this.deactivateSerial(product.serials, false)
      }))
      alert('Seriali disattivati!')
    },
    async activateSerial(serial, log) {
      this.isLoading = true
      const serialToActivate = serial.product_3 || serial.product
      const macAddress = serial.mac.match(/.{1,2}/g).join(':')
      const response = await ProductionService.activateProductSerialLink(serialToActivate, {
        serial: serialToActivate,
        macAddress: macAddress,
        company: this.order.billing.company,
        sector: this.subscription.selectedSector
      })
      if (log) {
         alert(`Seriale ${serialToActivate} attivato!`)
      }
      this.isLoading = false
    },
    async deactivateSerial(serial, log) {
      this.isLoading = true
      const serialToDeactivate = serial.product_3 || serial.product
      const response = await ProductionService.deactivateProductSerialLink(serialToDeactivate)
      if (log) {
        alert(`Seriale ${serialToDeactivate} disattivato!`)
      }
      this.isLoading = false
    },

  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
@media (min-width: 992px) {
  .modal-lg {
    max-width: 96%;
  }
}
body.modal-open {
  overflow: hidden;
}
section {
  margin-bottom: 2rem;
  padding: 1rem;
  box-shadow: 1px 1px 0.25rem 0 rgba(0, 0, 0, 0.3);

  h6 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem;
    i {
      margin-right: 0.5rem;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type: none;
      line-height: 2;
    }
  }
}
</style>
