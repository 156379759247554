<template>
  <section class="users">
    <h2>Users</h2>
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Action</th>
                <th>Email</th>
                <th>Name</th>
                <th>Password</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody class="clean">
              <tr v-for="user in users" :key="user">
                <td>
                  <div class="btn-group-vertical">
                    <button
                      class="btn btn-primary btn-sm"
                      @click.prevent="updateUser(user.email, user)"
                    >
                      <i class="fa fa-save"></i> Update
                    </button>
                    <button
                      v-if="$router.user.roleScore > 2"
                      class="btn btn-danger btn-sm"
                      @click.prevent="deleteUser(user)"
                    >
                      <i class="fa fa-trash-alt"></i> Delete
                    </button>
                  </div>
                </td>
                <td>
                  <input
                    type="email"
                    v-model="user.email"
                    :disabled="$router.user.roleScore < 3"
                  />
                </td>
                <td><input type="text" v-model="user.name" /></td>
                <td><input type="password" v-model="user.password" /></td>
                <td>
                  <input
                    type="text"
                    list="roles"
                    v-model="user.role"
                    :disabled="$router.user.roleScore < 3"
                  />
                  <datalist id="roles">
                    <option
                      v-for="role in user_roles"
                      :key="role"
                      v-text="role"
                    ></option>
                  </datalist>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 col-lg-4 card mt-0" v-if="$router.user.roleScore > 2">
        <div class="card-body">
          <h4>New user</h4>
          <form class="form" v-on:submit.prevent="addUser">
            <label for="email">Email</label>
            <input
              class="form-control"
              type="email"
              name="email"
              placeholder="Email"
              v-model="formData.email"
              required
            />
            <label for="name">Name</label>
            <input
              class="form-control"
              type="text"
              name="name"
              placeholder="Name"
              v-model="formData.name"
              required
            />
            <label for="password">Password</label>
            <input
              class="form-control"
              type="password"
              name="password"
              placeholder="Password"
              v-model="formData.password"
              required
            />
            <label for="role">Role</label>
            <input
              class="form-control"
              type="text"
              list="roles"
              v-model="formData.role"
              onmousedown="value = ''"
              required
            />
            <datalist id="roles">
              <option
                v-for="role in user_roles"
                :key="role"
                v-text="role"
              ></option>
            </datalist>
            <button type="submit" class="btn btn-lg btn-primary btn-block">
              Add user
            </button>
          </form>
          <br />
          <div
            class="alert alert-info"
            v-show="formResponse.length > 0"
            role="alert"
            v-text="formResponse"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import UsersService from "@/services/UsersService";
export default {
  name: "Users",
  data() {
    return {
      users: "",
      formData: {},
      user_roles: ["administrator", "editor", "viewer"],
      formResponse: "",
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      const response = await UsersService.getUsers();
      if (this.$router.user.roleScore < 3) {
        this.users = response.data.filter(
          (user) => user.email === this.$router.user.email
        );
      } else {
        this.users = response.data;
      }
    },
    async addUser() {
      const response = await UsersService.addUser(this.formData);
      if (response.data) {
        this.formResponse = response.data;
        this.formData = {};
        this.getUsers();
      }
    },
    async updateUser(email, params) {
      await UsersService.updateUser(email, params);
    },
    async deleteUser(user) {
      await UsersService.deleteUser(user.email);
      this.getUsers();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/variables.scss";
table tr {
  background: #fff;
  th {
    text-transform: uppercase;
    font-weight: 400;
    background-color: $gray02;
    color: #fff;
  }
  td {
    vertical-align: middle;
  }
}
form label,
form button {
  margin-top: 1.5rem;
}
.clean input,
input.clean {
  width: auto;
  background: transparent;
  border: none;
}
</style>
