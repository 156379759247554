<template lang="pug">
Modal(:title="`BOM ${sku}`" extraClass="modal-lg" ref="bomModal" @toggleModal="getBom")
  template(#button)
    .btn.btn-dark.btn-sm Show BOM
  template(#content)
    table.table.table-hover
      thead
        tr
          th Level
          th SKU
          th Quantity
          th Warehouse
          th Lead Time
          th Parent SKU
      tbody
        template(v-for="(item, index) in bom")
          tr(:key="index" :class="[`lvl--${item.lvl}`]")
            td 
              //- button(v-if="bom[index + 1] && bom[index + 1].itemCode === item.childItemCode" @click="toggleLvl(item.childItemCode)") +
              span {{ item.lvl }}
            td {{ item.childItemCode }}
            td {{ item.childQty | qty }}
            td {{ item.childWhsCode }}
            td {{ item.leadTime }}
            td 
              small ({{ item.itemCode }})
</template>
<script>
import Modal from "../utils/Modal";
import SapService from "@/services/SapService";
export default {
  name: "Products",
  props: ['sku'],
  components: { Modal },
  data() {
    return {
      isLoading: false,
      bom: [],
      toggles: {}
    };
  },
  methods: {
    async getBom(toggle) {
      if (toggle) {
        this.isLoading = true
        const { data } = await SapService.getBom(this.sku)
        this.bom = this.listToTree(data.row)
        this.isLoading = false
      }
    },
    toggleLvl(itemCode) {
      this.toggles[itemCode] = !this.toggles[itemCode]
    },
    hasChildren(index, childItemCode){
      return this.bom[index + 1] && this.bom[index + 1].itemCode === childItemCode
    },
    listToTree(list) {
      let tree = { 
        [this.sku]: { itemCode: this.sku, childItemCode: this.sku, childQty: '1.000000', lvl: '-1', childNum: '-1', children: [] } 
      }
      list.forEach(item => { 
        tree[item.childItemCode] = { ...item, children: [] } 
      })
      for (const [key, value] of Object.entries(tree)) {
        if( value.itemCode !== value.childItemCode) {
          tree[value.itemCode].children.push(value)
        }
      }
      const skuTree = tree[this.sku]
      return this.treeToList(skuTree)
    },
    treeToList(tree) {
      let list = []
      let nodes_to_visit = [ ...tree.children ]
      while (nodes_to_visit.length) {
        let current_node = nodes_to_visit.shift()
        list.push({ 
          lvl: current_node.lvl, 
          itemCode: current_node.itemCode,
          childNum: current_node.childNum, 
          childItemCode: current_node.childItemCode, 
          childQty: current_node.childQty, 
          childWhsCode: current_node.childWhsCode, 
          leadTime: current_node.leadTime
        })
        this.toggles[current_node.itemCode] = false
        nodes_to_visit = [...current_node.children, ...nodes_to_visit]
      }
      return list
    }
  }
};
</script>
<style lang="scss" scoped>
  @for $i from 0 through 5 {
  .lvl--#{$i} td {
    background-color: rgba(0, 0, 0, 1 - ($i * 0.2));
    color: #f2f2f2;
  }
}


</style>