<template lang="pug">
    div
      input#fileupload.invisible.position-absolute(type="file" @change="handleFileChange" multiple)
      label.mt-2(for="fileupload" :class="[buttonClass, {'loading': isUploading}]" :disabled="isUploading")
        i.fa.fa-upload.mr-2
        span Carica file
    //-  <button class="link" @click="uploadFiles">Carica file</button>
    //-   <ul v-if="uploadedKeys.length">
    //-     <li v-for="(key, index) in uploadedKeys" :key="index">
    //-       {{ key }}
  </template>
  <script>
  import S3Service from "@/services/S3Service";
  export default {
    props: ['orderId','deliveryIndex', 'useCase', 'buttonClass'],
    data() {
      return {
        selectedFiles: [],
        uploadedKeys: [],
        isUploading: false
      };
    },
    methods: {
      handleFileChange(event) {
        this.selectedFiles = Array.from(event.target.files);
        this.uploadFiles()
      },
      async uploadFiles() {
        if (this.selectedFiles.length === 0) {
            alert("Please select files before uploading.");
            return;
        }
        this.isUploading = true

        let formData = new FormData();
        this.selectedFiles.forEach((file, index) => {
            formData.append('files', file)
            formData.append(`folder`, 'inbound-orders')
            formData.append(`fileName${index}`, `${this.orderId}_${this.deliveryIndex}_${this.useCase}-${index}-${file.name}`)
            formData.append('fileType', file.type.replace(/(.*)\//g, ''))
        })

        try {
            const response = await S3Service.uploadFiles(formData)

        if (response.status === 200) {
            this.uploadedKeys = response.data
            this.$emit('filesUploaded', {
                'orderId': this.orderId,
                'deliveryIndex': this.deliveryIndex,
                'fileKeys': response.data
            })
            console.log("Upload successful:", this.uploadedKeys);
        } else {
            console.error("Upload failed:", response.statusText);
        }
        } catch (error) {
            console.error("Error during file upload:", error);
        }
        this.isUploading = false
      }
    }
  };
  </script>
  