<template>
  <section class="orders">
    <div class="row" v-if="$router.user.roleScore <= 1 || $router.user.roleScore >= 2">
      <div class="col-12 col-md-6">
        <h2>Upload Microline Test</h2>
        <form v-on:submit.prevent="addTests" enctype="multipart/form-data">
          <input
            type="file"
            accept=".json"
            id="file"
            name="file"
            class="form-control"
            ref="files"
            multiple
            @change="readFiles"
          />
          <button
            class="btn btn-outline-primary btn-md btn-block"
            type="submit"
            :class="{ loading: isLoading }"
            :disabled="isLoading"
          >Upload</button>
        </form>
        <div v-if="response">{{ response }}</div>
      </div>
      <div class="col-12 col-md-6">
        <h2>Search by Serial</h2>
        <input type="search" class="form-control" v-model="searchSerial" placeholder="Enter serial" @keyup.enter="getTest(searchSerial)">
        <ul class="py-4">
          <li class="py-1" v-for="t in searchResponse" :key="t.data.SequenceCode"><a :href="`/production-test/${t.data.SequenceCode}`">{{ t.data.SequenceCode }} ({{t.createdAt | formatDate }})</a></li>
        </ul>
      </div>
    </div>
    <div v-else>Access denied! You can't access this page.</div>
  </section>
</template>

<script>
import ProductionService from "@/services/ProductionService";
export default {
  name: "ProductionTests",
  data() {
    return {
      isLoading: false,
      json: [],
      response: false,
      searchResponse: false,
      searchSerial: ''
    };
  },
  components: {
  },
  methods: {
    readFiles(event){
      this.isLoading = true
      const _self = this
      event.target.files.forEach(file => {
        var reader = new FileReader()
        reader.onload = function(event){ 
          const data = JSON.parse(event.target.result)
          const test = {
            id: data.SequenceCode,
            type: 'power-supply',
            data
          }
          _self.json.push(test) 
        }
        reader.readAsText(file)
      })
      this.isLoading = false
    },
    async addTests() {
      this.response = false
      this.isLoading = true
      const response = await ProductionService.addProductionTest(this.json)
      this.response = response.data
      this.json = [] 
      this.isLoading = false
    },
    async getTest(id) {
      this.isLoading = true
      this.searchResponse = false
      const response = await ProductionService.getProductionTest(id)
      this.searchResponse = response.data
      this.isLoading = false
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";

</style>
