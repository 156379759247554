<template>
<table class="table table-bordered mb-2" v-if="shipments && shipments.length">
  <thead>
    <tr>
      <th>Courier</th>
      <th>Tracking</th>
      <th>Status</th>
      <th>Delivery status</th>
      <th>
        Serials
        <button class="btn btn-sm" @click="isEdit = !isEdit"><i class="fa fa-edit"></i></button>
      </th>
      <th>Package</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="shipment in shipments" :key="shipment._id">
      <td>
        {{ shipment.shipment_provider }}
      </td>
      <td><a :href="shipment.tracking_url" target="_blank">{{ shipment.tracking_number }}</a></td>
      <td>
        {{ shipment.status }}<br>
        <small>{{ shipment.shipped_at | formatDate }}</small>
      </td>
      <td>
        {{ shipment.delivery_status }}<br>
        <small>{{ shipment.delivered_at | formatDate }}</small>
      </td>
      <td>
        <div class="d-inline-flex align-items-center mb-1" v-for="(serial, index) in shipment.serials" :key="serial">
          <router-link :to="`/production/serial/${serial}`">
            {{ serial }}
          </router-link>
          <button v-if="$router.user.roleScore >= 2 && isEdit" class="btn btn-sm btn-light rounded-circle btn-delete ml-1" @click="deleteSerial(shipment, index)">&times;</button>
        </div>
        <button v-if="$router.user.roleScore >= 2 && isEdit" class="btn btn-sm btn-block btn-primary mt-2" @click="addSerials(shipment)">+ Add</button>
      </td>
      <td>
        <template v-if="shipment.package && shipment.package.sku">
          {{ shipment.package.sku }}
          <br>
          {{ shipment.package.name }}
          <br>
          {{ `L${shipment.package.dimensions.length}×W${shipment.package.dimensions.width}×H${shipment.package.dimensions.height}×${shipment.package.dimensions.weight}KG` }}
          <br>
          <br>
          <b>{{ shipment.products.join(", ") }}</b>
        </template>
        <template v-else>
          Unknown, managed by Hive
        </template>
      </td>
    </tr>
  </tbody>
</table>
</template>
<script>
import OrdersService from "@/services/OrdersService";
import ProductionService from "@/services/ProductionService";
export default {
  name: "Shipments",
  props: ["orderId", "shipments"],
  data() {
    return {
      isLoading: false,
      isEdit: false
    };
  },
  methods: {
    async getProductSerials(serial) {
      const response = await ProductionService.getProductSerials(serial);
      this.production.push(response.data);
    },
    async addSerials(shipment){
      let serialsInput = prompt('Add serials')
      const serials = serialsInput.split(/[^\d]/g).filter(e => e)
      shipment.serials = [...shipment.serials, ...serials]
      await this.updateShipments()
    },
    async deleteSerial(shipment, index) {
      if (confirm('Are you sure?')) {
        shipment.serials.splice(index,1)
      }
      await this.updateShipments()
    },
    async updateShipments() {
      this.isLoading = true
      await OrdersService.updateOrder(this.orderId, { id_number: this.orderId, shipments: this.shipments })
      this.isLoading = false
    }
    
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
.btn-delete {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($gray01,.5);
  color: rgba(255,255,255,.75);
}
</style>
