<template>
<div @keyup.esc="toggle()" @click.stop :class="extraClass">
  <div class="modal-button" @click="toggle()">
    <slot name="button" />
  </div>
  <div class="modal" :class="[{ 'd-block': show, 'show': show }]" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="font-weight-bold modal-title" v-if="title">{{ title }}</h5> 
          <button v-if="!hideCloseButton" class="close" type="button" data-dismiss="modal" aria-label="Close" @click="toggle()">&times;</button>
        </div>
        <div class="modal-body">
          <slot v-if="show" name="content"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: "Modal",
  props: ['title','extraClass','hideCloseButton'],
  data() {
    return {
      show: false
    }
  },
  methods: {
    async toggle() {
      this.show = !this.show
      this.$emit('toggleModal', this.show)
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
.modal {
  z-index: 9999;
  &-button {
    min-width: 1rem;
    min-height: 100%;
  }
  background-color: rgba(0,0,0, 0.4);
  &-header {
    border: none;

    .modal-title {
      margin: 0;
    }
  }
  &-body {
    max-height: 80vh;
    overflow: auto;

  }
  &-content {
    border-radius: 0;
    border: none;
  }
  .close {
    outline: none;
  }
  &-warning {
    .modal-content { background: $yellow; border: 2px solid $ups;}
  }
  &-success {
    .modal-content { background: #c9ebd7; border: 2px solid $green;}
  }
  ol {
    line-height: 2;
  }
}
</style>
